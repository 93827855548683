import { Assets, Text, Application, Container, Texture, Sprite } from 'pixi.js';
import { Point } from '../models/point';
import { Store } from '@ngrx/store';
import * as GamesActions from '@app/store/features/games/games.actions';

export class Seat {
    container = new Container();

    constructor(
        private readonly _textures: { default: Texture, click: Texture },
        private readonly _data: { tableId: number, seatPosition: number },
        private readonly _position: Point,
        private readonly _store: Store<any>
    ) {
        const seatSprite = new Sprite(_textures.default);
        this.container.position.set(_position.x, _position.y);
        seatSprite.eventMode = 'static'
        seatSprite.cursor = 'pointer';

        seatSprite.on('pointerdown', () => {
            seatSprite.texture = _textures.click;
            this._store.dispatch(GamesActions.takeSeat({ idTable: _data.tableId, seatPosition: _data.seatPosition }))
        });
        seatSprite.on('pointerup', () => {
            seatSprite.texture = _textures.default;

        });

        this.container.addChild(seatSprite);
    }

    hideSeat(): void {
        this.container.visible = false;
    }

    showSeat(): void {
        this.container.visible = true;
    }

    setPosition(position: Point): void {
        this.container.position.set(position.x, position.y);
    }

}