import { Injectable, inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { th } from 'date-fns/locale';
import { filter, map, Observable, pairwise, shareReplay, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  private readonly _router = inject(Router);
  previousUrl$: Observable<string | null>

  page$ = this._router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd), // Type narrowing
    map((event: NavigationEnd) => event.urlAfterRedirects),
    map((url: string) => url.startsWith('/') ? url.slice(1) : url),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  constructor() {
    this.previousUrl$ = this._router.events.pipe(
      filter(event => event instanceof NavigationStart),
      map(event => (event as NavigationStart).url),
      startWith(null), // Ensures first value is null
      pairwise(), // Emits previous and current URL as a tuple
      map(([previousUrl]) => previousUrl), // Extracts only the previous URL
      shareReplay({ bufferSize: 1, refCount: true }) // Caches the last value
    );
    this.previousUrl$.subscribe()

  }

} 
