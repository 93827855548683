<div style="color: #fff; width: 600px; margin: 0 auto; padding: 80px;">

    <div>Button configuration</div>

    <div style="display: flex; margin: 20px;">
        <button appButton
            type="button" class="inactive"
            [ngClass]="{'success': configType===Limit.NL}"
            (click)="changeConfigType()">
            No Limit
        </button>
        <button appButton
            type="button" class="inactive"
            [ngClass]="{'success': configType===Limit.PL}"
            (click)="changeConfigType()">
            Pot Limit
        </button>
    </div>
    <div>
        <form [formGroup]="configType===Limit.NL ? btnConfigNL : btnConfigPL">
            <div formGroupName="preFlop">
                <div
                    style="font-weight: 500; text-align: center; margin-bottom: 10px;border-bottom: 1px #fff solid;">Pre-Flop
                    Configuration</div>
                <table style="width: 100%;">
                    <tr>
                        <td>Bet Button A</td>
                        <td>
                            <select formControlName="btn1">
                                <option
                                    [value]="preFlopBtnOption.value"
                                    *ngFor="let preFlopBtnOption of (configType===Limit.NL ? btn1ConfigNLPreFlopBtnOptions$ : btn1ConfigPLPreFlopBtnOptions$)|async">
                                    {{preFlopBtnOption.label}}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Bet Button B</td>
                        <td>
                            <select formControlName="btn2">
                                <option
                                    [value]="preFlopBtnOption.value"
                                    *ngFor="let preFlopBtnOption of (configType===Limit.NL ? btn2ConfigNLPreFlopBtnOptions$ : btn2ConfigPLPreFlopBtnOptions$)|async">
                                    {{preFlopBtnOption.label}}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Bet Button C</td>
                        <td>
                            <select formControlName="btn3">
                                <option
                                    [value]="preFlopBtnOption.value"
                                    *ngFor="let preFlopBtnOption of (configType===Limit.NL ? btn3ConfigNLPreFlopBtnOptions$ : btn3ConfigPLPreFlopBtnOptions$)|async">
                                    {{preFlopBtnOption.label}}
                                </option>
                            </select>
                        </td>
                    </tr>
                </table>
            </div>

            <div formGroupName="postFlop" style="margin: 50px 0 0 0;">
                <div
                    style="font-weight: 500; text-align: center; margin-bottom: 10px; border-bottom: 1px #fff solid;">Post-Flop
                    Configuration</div>
                <table style="width: 100%;">
                    <tr>
                        <td>Bet Button A</td>
                        <td>
                            <select formControlName="btn1">
                                <option
                                    [value]="postFlopBtnOption.value"
                                    *ngFor="let postFlopBtnOption of (configType===Limit.NL ? btn1ConfigNLPostFlopBtnOptions$ : btn1ConfigPLPostFlopBtnOptions$)|async">
                                    {{postFlopBtnOption.label}}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Bet Button B</td>
                        <td>
                            <select formControlName="btn2">
                                <option
                                    [value]="postFlopBtnOption.value"
                                    *ngFor="let postFlopBtnOption of (configType===Limit.NL ? btn2ConfigNLPostFlopBtnOptions$ : btn2ConfigPLPostFlopBtnOptions$)|async">
                                    {{postFlopBtnOption.label}}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Bet Button C</td>
                        <td>
                            <select formControlName="btn3">
                                <option
                                    [value]="postFlopBtnOption.value"
                                    *ngFor="let postFlopBtnOption of (configType===Limit.NL ? btn3ConfigNLPostFlopBtnOptions$ : btn3ConfigPLPostFlopBtnOptions$)|async">
                                    {{postFlopBtnOption.label}}
                                </option>
                            </select>
                        </td>
                    </tr>
                </table>
            </div>

            <div
                style="display: flex; width: 100%; margin-top: 20px; justify-content: center;">
                <button appButton
                    type="button" class="secondary"
                    (click)="reset()">
                    Reset
                </button>
                <button appButton class="succes"
                    type="button"
                    (click)="save()">
                    Save
                </button>

            </div>
        </form>
    </div>
    <div style="position: absolute; right: 10px; bottom: 10px;" *ngIf="false">
        <div style="font-size: 10px;">
            <h2>btnConfigNL</h2>
            <pre>{{btnConfigNL.value|json}}</pre>
        </div>
        <div style="font-size: 10px;">
            <h2>btnConfigPL</h2>
            <pre>{{btnConfigPL.value|json}}</pre>
        </div>
    </div>
</div>