
import { Injectable } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';


import * as ConfigSelectors from '@app/store/features/config/config.selectors';

import { WsService } from '@app/services/ws.service';
import { TableSummary, TableSummaryData } from '@app/models/table-summary';
import { LiveLobbyRing } from '@app/models/live-lobby-ring';
import * as PlayerBalanceSelectors from '@app/store/features/player-balance/player-balance.selectors';
import { ServerMsgPlayerBalance } from '@app/models/server-msg';
import { ServerMessageType } from '@app/enums/server-message-type';
import * as PlayerBalanceActions from '@app/store/features/player-balance/player-balance.actions';
import { PlayerBalance } from '@app/models/player-balance';
import { AccountType } from '@app/models/account-type';

@Injectable()
export class PlayerBalanceEffects {
    constructor(
        private _ws: WsService,
        private readonly store: Store,
        private readonly lobbyService: LobbyService,
        private actions$: Actions
    ) { }


    playerBalance$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPlayerBalance>(ServerMessageType.PlayerBalance)
            .pipe(
                switchMap(playerBalanceResponse => {
                    const currency = playerBalanceResponse.value2 ?? 0
                    const balance = playerBalanceResponse.value ?? 0;

                    return this.store.pipe(
                        select(PlayerBalanceSelectors.selectEntityById(currency)),
                        take(1),
                        map((currentPlayerBalance) => {
                            /**
                            * @description Update the player balance for the currency
                            * if there is already player balance for the currency it will update specific balance
                            * if there is no player balance for the currency it will add it
                            */

                            const playerBalance: PlayerBalance = {
                                currency,
                                availableMoney: currentPlayerBalance ? currentPlayerBalance.availableMoney : 0,
                                onTable: currentPlayerBalance ? currentPlayerBalance.onTable : 0,
                                cashToken: currentPlayerBalance ? currentPlayerBalance.cashToken : 0,
                                points: currentPlayerBalance ? currentPlayerBalance.points : 0,
                                bonus: currentPlayerBalance ? currentPlayerBalance.bonus : 0,
                            }

                            switch (playerBalanceResponse.values[0]) {
                                case parseInt(AccountType.availableMoney):
                                    playerBalance.availableMoney = balance
                                    break;
                                case parseInt(AccountType.CashOnTable):
                                    playerBalance.onTable = balance
                                    break;
                                case parseInt(AccountType.CashToken):
                                    playerBalance.cashToken = balance
                                    break;
                                case parseInt(AccountType.PointsToUnlock):
                                    playerBalance.points = balance
                                    break;
                            }

                            return PlayerBalanceActions.upsertOne({ playerBalance })

                        })
                    )
                }),
            ));
} 