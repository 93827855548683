import { Assets, Graphics, Text, Application, Container, Texture, Sprite, Loader, TextStyle, ITextStyle, ColorMatrixFilter } from 'pixi.js';
import { Point } from '../models/point';
import { Vector } from '../helpers/vector';
import { DragAndDrop } from '@app/helpers/drag-drop';
import { environment } from '@environments/environment';
import { GameStatus } from '@app/store/features/games/games.reducer';

export class CardReplaceInfo {
    container = new Container();

    private background: Graphics = new Graphics();
    values: number[] = []

    dimensions = {
        background: {
            width: [52, 80, 114, 146],
        },
        text: {
            position: {
                x: [18, 50, 82, 114]
            }
        },
        lineSeparator: {
            position: {
                x: [40, 72, 102]
            }
        }
    }
    constructor() {

    }


    setReplacedCardsNumber(numOfReplacedCards: number) {
        this.values.push(numOfReplacedCards)

        this.updateInfo();
    }

    removeReplacedCardsNumber() {
        this.values = []
        this.background.clear();
        this.container.removeChildren();
    }

    setAllNumbersInactive() {
        this.updateInfo(true)
    }
    
    private updateInfo(allNumbersInactive = false) {
        this.container.removeChildren();

        this.container.addChild(this.background);
        this.background.clear();
        this.background.beginFill(0xFF4248, 1);

        this.background.drawRoundedRect(
            0, // X
            0, // Y
            this.dimensions.background.width[this.values.length - 1], // WIDTH
            36, // HEIGTH
            20 // RADIUS
        );
        this.background.endFill();

        let index = 0;
        for (const value of this.values) {
            // "9" 2 => TripleDrawBettingRound1 2 cards

            const text = new Text(value, new TextStyle({ fontFamily: 'Saira Semi Condensed', fontSize: 28, fill: '#ffffff', fontWeight: '500' }));
            text.position.set(this.dimensions.text.position.x[index], 1);


            if (index + 1 < this.values.length) {
                text.alpha = 0.65;
            }
            if (index > 0) {

                const lineSeparator = new Graphics();
                lineSeparator.beginFill(0xFFFFFF, 0.4);
                lineSeparator.drawRoundedRect(
                    0, // X
                    0, // Y
                    3, // WIDTH
                    16, // HEIGTH
                    0 // RADIUS
                );
                lineSeparator.endFill();
                lineSeparator.position.set(this.dimensions.lineSeparator.position.x[index - 1], 10);
                this.container.addChild(lineSeparator);
            }

            if (allNumbersInactive) {
                text.alpha = 0.65;
            }

            this.container.addChild(text);

            index++;
        }
    }
}

