import { Assets, Graphics, Text, Application, Container, Texture, Sprite, Loader, TextStyle, ITextStyle, ColorMatrixFilter } from 'pixi.js';
import { Point } from "../models/point";
import { CurrencyInfo } from '@app/models/currency-info';
import { GameCurrencyPipe } from '@app/pipes/game-currency.pipe';
import { Vector } from '../helpers/vector';
import { PlayerCallTime } from '@app/models/player-call-time';
import { parseISO, addSeconds, getYear, differenceInMilliseconds } from 'date-fns';
import { tr } from 'date-fns/locale';


export class CallTime {
    container = new Container();


    private info?: PlayerCallTime;


    private textStyle: TextStyle = new TextStyle({
        fontFamily: 'Saira Semi Condensed',
        fontSize: 28,
        fill: '#333333',
        fontWeight: '500',
    });

    private timer: Text;

    private background: Sprite;
    private statusAttention: Sprite;
    private statusCheck: Sprite;

    width: number = 0;
    height: number = 0;

    constructor(
        textures: {
            callTimeAttention: Texture;
            callTimeCheck: Texture;
            callTimeBackground: Texture;
        },
        data: {
            playerCallTime?: PlayerCallTime
        }
    ) {

        this.info = data.playerCallTime;

        this.background = new Sprite(textures.callTimeBackground);
        this.statusAttention = new Sprite(textures.callTimeAttention);
        this.statusCheck = new Sprite(textures.callTimeCheck);

        this.background.scale.set(0.75);
        this.statusAttention.scale.set(0.75);
        this.statusCheck.scale.set(0.75);

        this.width = this.background.width; // Set the width of the container to the width of the background
        this.height = this.background.height; // Set the height of the container to the height of the background

        this.background.visible = false;
        this.statusAttention.visible = false;
        this.statusCheck.visible = false;

        this.timer = new Text('', this.textStyle)
        this.timer.visible = false;

        this.container.addChild(this.background);
        this.container.addChild(this.statusAttention);
        this.container.addChild(this.statusCheck);
        this.container.addChild(this.timer);



    }

    update(dt: number) {
        if (!this.info) {
            this.setIdleState()
            return;
        }

        const activatedAt = parseISO(this.info.activatedAt);
        const expiresAt = addSeconds(activatedAt, this.info.callTimeDuration);
        const duration = differenceInMilliseconds(expiresAt, new Date());

        const timer = this.dhmsCountdownTimer(duration)
        const expiresAtYear = getYear(expiresAt);

        // If player can not leave table and is not activated 
        if (!this.info.canLeaveTable && !this.info.isActivated) {
            // Attention state
            this.background.visible = false; // ⚪
            this.statusAttention.visible = true; // 🟢
            this.statusCheck.visible = false; // ⚪
            this.timer.visible = false; // ⚪
        } else if (!this.info.canLeaveTable && timer > 0) {
            // Active state
            this.timer.text = `${timer}` // Set timer text to the current timer value

            this.background.visible = true; // 🟢
            this.statusAttention.visible = false; // ⚪
            this.statusCheck.visible = false; // ⚪
            this.timer.visible = true; // 🟢

            this.timer.x = this.width / 2 - this.timer.width / 2; // Center the timer text horizontally
            this.timer.y = this.height / 2 - this.timer.height / 2 + 3; // Center the timer text vertically

        } else if (this.info.isActivated && (this.info.callTimeExpired || timer === 0) && expiresAtYear > 1) {
            // Expired state
            this.background.visible = false;  // ⚪
            this.statusAttention.visible = false; // ⚪
            this.statusCheck.visible = true; // 🟢
            this.timer.visible = false; // ⚪
        } else {
            // Idle state
            this.setIdleState()
        }
    }

    setIdleState() {
        this.background.visible = false; // ⚪
        this.statusAttention.visible = false; // ⚪
        this.statusCheck.visible = false; // ⚪
        this.timer.visible = false; // ⚪

        // Test visibility for text and background elements 
        // this.background.visible = true;
        // this.statusCheck.visible = false;
        // this.timer.text = `59` // Set timer text to the current timer value
        // this.timer.visible = true;
        // this.timer.x = this.width / 2 - this.timer.width / 2; // Center the timer text horizontally
        // this.timer.y = this.height / 2 - this.timer.height / 2 + 3; // Center the timer text vertically

    }

    updateDataPlayerCallTime(playerCallTime?: PlayerCallTime) {
        console.log('🟣 #3 Player Call Time :: ', playerCallTime);

        this.info = playerCallTime;
        this.update(0)
    }




    private dhmsCountdownTimer(duration: number): number {

        let t = Math.floor(duration / 1000);

        let days, hours, minutes, seconds;

        days = Math.floor(t / 86400)
        t -= days * 86400;
        hours = (Math.floor(t / 3600) % 24)
        t -= hours * 3600;
        minutes = (Math.floor(t / 60) % 60)
        t -= minutes * 60
        seconds = (t % 60)

        if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {
            return 0
        } else if (days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
            return 0
        }

        // min 2 seconds 30 => 3min
        // min 1 seconds 12 => 2 min
        // min 0 seconds 58 => 58

        if (minutes === 0) {
            return seconds
        }

        return minutes + 1; // +1 min for the current minute to be displayed (1:58 => 2:00)

    }
}   
