import { Injectable, inject } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerMessageType } from '@app/enums/server-message-type';
import { ServerMsg, ServerMsgAccountEmailUniqueResponse, ServerMsgAccountPasswordChangeResponse, ServerMsgAccountUsernameUniqueResponse, ServerMsgConnectResponse, ServerMsgPlayerBalance, ServerMsgRequestSelfExclusionResponse } from '@app/models/server-msg';
import { Store, select } from '@ngrx/store';
import { WsService } from '@app/services/ws.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import * as  WSActions from '@app/store/features/web-socket/web-socket.actions';
import * as ConfigActions from '@app/store/features/config/config.actions';
import { ConnectionStatus } from '@app/enums/connection-status';
import { iif, of, } from 'rxjs';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import { Router } from '@angular/router';
import { GlobalSettings, GlobalSettingsAPIResponse } from '@app/models/global-settings';
import { ServerResponse } from '@app/enums/server-response';
import { MemberPreferences, MemberProfileDTO } from '@app/models/member-profile';
import * as UserActions from '@app/store/features/user/user.actions';
import { UserService } from '@app/features/user/services/user.service';
import { CurrencyInfoDTO } from '@app/models/currency-info';
import { CurrencyService } from '@app/services/currency.service';
import { PlayerBalance } from '@app/models/player-balance';
import { AccountType } from '@app/models/account-type';
import * as UserSelectors from '@app/store/features/user/user.selectors';
import { ToastrService } from 'ngx-toastr';

import * as ConfigSelectors from '@app/store/features/config/config.selectors';
import { environment } from '@environments/environment';
import { ta } from 'date-fns/locale';
import { PlayerLevelStatusDTO } from '@app/models/player-level-status';
import * as CurrenciesSelectors from '@app/store/features/currencies/currencies.selectors';
import { sound } from '@pixi/sound';

@Injectable()
export class UserEffects {

    private readonly _ws = inject(WsService);
    private readonly _actions$ = inject(Actions);
    private readonly _store = inject(Store);
    private readonly _router = inject(Router);
    private readonly _userService = inject(UserService);
    private readonly _currencyService = inject(CurrencyService);
    private readonly _toastr = inject(ToastrService);

    userMemberProfile$ = createEffect(() => this._ws.getDataResponse<MemberProfileDTO>(ServerResponse.MemberProfile)
        .pipe(
            switchMap((memberProfile) => {
                return this._store.pipe(
                    select(ConfigSelectors.getDomainSettings),
                    take(1),
                    map((domainSettings) => this._userService.updateMemberProfile(memberProfile, domainSettings!)))
            }),
            map(memberProfile => {
                return UserActions.updateProfile({ profile: memberProfile })
            })
        ));

    memberPreference$ = createEffect(() => this._ws.getDataResponse<MemberPreferences>(ServerResponse.MemberPreference)
        .pipe(
            map(memberPreferences => {
                return UserActions.updateMemberPreferences({ preferences: memberPreferences })
            })
        ));



    changePassword$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserActions.changePassword),
                tap(({ password }) => this._userService.changePassword(password)),
                switchMap(() => this._ws.getServerMsg<ServerMsgAccountPasswordChangeResponse>(ServerMessageType.AccountPasswordChangeResponse)),
                tap((response) => {
                    if (response.value === 1) {
                        this._toastr.success(`Password Changed Successfully`);
                    } else {
                        this._toastr.error(`Password didn't changed`);
                    }
                })
            ),
        { dispatch: false }
    );


    checkEmailExist$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserActions.checkEmailExist),
                tap(({ email }) => this._userService.checkEmailExist(email)),
                switchMap(() => this._ws.getServerMsg<ServerMsgAccountEmailUniqueResponse>(ServerMessageType.AccountEmailUniqueResponse)),
                map((response) => UserActions.updateEmailExist({ email: response.text, exist: response.value !== 1 }))
            )
    );


    checkUsernameExist$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserActions.checkUsernameExist),
                tap(({ username }) => this._userService.checkUsernameExist(username)),
                switchMap(() => this._ws.getServerMsg<ServerMsgAccountUsernameUniqueResponse>(ServerMessageType.AccountUsernameUniqueResponse)),
                map((response) => UserActions.updateUsernameExist({ username: response.text, exist: response.value !== 1 }))
            )
    );


    updateAccount$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserActions.updateAccount),
                tap(({ memberProfile }) => this._userService.updateAccount(memberProfile)),
                switchMap(() => this._ws.getDataResponse<MemberProfileDTO>(ServerResponse.MemberProfile).pipe(take(1))),
                tap(() => this._toastr.success(`Profile Updated Successfully`, `Edit Profile`))
            ), {
        dispatch: false
    });



    changeProfilePicture$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserActions.changeProfilePicture),
                tap(({ imageBase64 }) => this._userService.uploadAvatar(imageBase64)),
                // switchMap(() => this._ws.getDataResponse<MemberProfileDTO>(ServerResponse.MemberProfile).pipe(take(1))),
                // tap(() => this._toastr.success(`Profile Updated Successfully`, `Edit Profile`))
            ), {
        dispatch: false
    });


    onSelfExclude$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserActions.selfExclude),
                tap(({ period, password }) => this._userService.selfExclude(period, password)),
                switchMap(() => this._ws.getServerMsg<ServerMsgRequestSelfExclusionResponse>(ServerMessageType.RequestSelfExclusionResponse)),
                tap((response) => {
                    if (response.errorCode) {
                        this._toastr.warning(`Password is not correct`, `Self Exclude`)
                    } else {
                        this._toastr.success(`Self Excluded Successfully`, `Self Exclude`)
                    }
                }),
                filter((response) => !response.errorCode),
                map(() => AuthActions.onLogout())
            ));


    onAutoMuck$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserActions.autoMuck),
                tap(({ turnOn }) => this._userService.autoMuck(turnOn)),
            ), { dispatch: false });

  

    onMuteSound$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserActions.muteSound),
                tap(({ turnOff }) => {
                    sound.volumeAll = turnOff ? 0 : 1;
                    localStorage.setItem('soundTurnOff', `${turnOff}`)
                }),
            ), { dispatch: false });

    onJumpToTable$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserActions.jumpToTable),
                tap(({ turnOn }) => this._userService.jumpToTable(turnOn)),
            ), { dispatch: false });




} 