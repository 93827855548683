import { Inject, Injectable, inject } from '@angular/core';
import { ServerRequestMessage } from '@app/enums/server-request';

import { LobbyFilters } from '../../../store/features/lobby/lobby.reducer';
import { LobbyFilterVariant } from '../enums/lobby-filter-variant';
import { LobbyFilterStake } from '../enums/lobby-filter-stake';
import { LobbyFilterLimit } from '../enums/lobby-filter-limit';
import { LobbyFilterSpeed } from '../enums/lobby-filter-speed';
import { LobbyFilterSeat } from '../enums/lobby-filter-seat';
import { TableSummary, TableSummaryColors, TableSummaryDTO, TableSummaryLegend } from '@app/models/table-summary';
import { TournamentSummary, TournamentSummaryResponse } from '@app/models/tournament-summary';
import { SpinNGoSummary, SpinNGoSummaryResponse } from '@app/models/spin-n-go-summary';
import { SitNGoSummary, SitNGoSummaryDTO } from '@app/models/sit-n-go-summary';
import { WsService } from '@app/services/ws.service';
import { VariantType2 } from '@app/enums/variant-type-2';
import { Limit } from '@app/models/limit';
import { Legend } from '@app/features/lobby/consts/legend.constants';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CurrencyInfo } from '@app/models/currency-info';
import { MixTableDetailsTable, MixTableDetailsTableDTO } from '@app/models/mix-table-details';
import { JackPotInfo } from '@app/models/jackpot-info';
import { GameCurrencyPipe } from '@app/pipes/game-currency.pipe';
import { TournamentStatus2 } from '@app/enums/tournament-status-2';
import { addSeconds, format } from 'date-fns';
import { map, of, switchMap, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LobbyService {

  private readonly _sanitizer = inject(DomSanitizer)

  constructor(private readonly ws: WsService) { }

  subscribeLiveLobby() {
    const data = {
      type: ServerRequestMessage.SubscribeLiveLobby,
      Value: 1
    }
    this.ws.sendData(data);
  }

  unsubscribeLiveLobby() {
    const data = {
      type: ServerRequestMessage.SubscribeLiveLobby,
      Value: 0
    }
    this.ws.sendData(data);
  }

  getTableDetails(idTable: number): void {
    const data = {
      type: ServerRequestMessage.GetTableDetails,
      idTable
    }
    this.ws.sendData(data);
  }

  // subscribe to receivel EXTRA real time information from a table such as waiting list and player chips. 
  // IdTable is required, can be 0 to unsubscribe
  subscribeLiveLobbySelected(idTable: number) {
    const data = {
      type: ServerRequestMessage.SubscribeLiveLobbySelected,
      idTable
    }
    this.ws.sendData(data);
  }

  unsubscribeLiveLobbySelected() {
    const data = {
      type: ServerRequestMessage.SubscribeLiveLobbySelected,
      idTable: 0
    }
    this.ws.sendData(data);
  }



  getTournamentSummary(idTournament: number): void {
    const data = {
      type: ServerRequestMessage.GetTournamentSummary,
      idTournament
    }
    this.ws.sendData(data);
  }

  getTournamentInfo(idTournament: number, password?: string): void {
    let data: any = {
      type: ServerRequestMessage.MTTView,
      idTournament,
      Value2: 1 // subscribe to tournament
    }
    if (password) {
      data.Str1 = password
    }
    this.ws.sendData(data);
  }

  getBlindSchedule(idTournament: number): void {
    const data = {
      type: ServerRequestMessage.RequestBlindSchedule,
      idTournament
    }
    this.ws.sendData(data);
  }







  // *** GET

  getTables(lobbyFilters: LobbyFilters, currency: CurrencyInfo): void {

    let filters: any = {
      hideFull: lobbyFilters.hideFull,
      hideEmpty: lobbyFilters.hideEmpty,
      showPrivate: lobbyFilters.showPrivate,
      freerollOnly: lobbyFilters.freerollOnly,
      showOnlyWithTip: lobbyFilters.showOnlyWithTip
    }

    if (lobbyFilters.tableType) {
      filters.tableType = lobbyFilters.tableType
    }

    if (lobbyFilters.variants) {
      switch (lobbyFilters.variants) {

        case LobbyFilterVariant.HoldemGames:
          filters.variants = [
            LobbyFilterVariant.TexasHoldEm,
            LobbyFilterVariant.TexasHoldEmRock,
            LobbyFilterVariant.CapTexasHoldEm
          ]
          break;

        case LobbyFilterVariant.OmahaGames:
          filters.variants = [
            LobbyFilterVariant.Omaha,
            LobbyFilterVariant.OmahaHiLo,
            LobbyFilterVariant.Omaha5,
            LobbyFilterVariant.Omaha5HiLo,
            LobbyFilterVariant.Omaha6,
            LobbyFilterVariant.Omaha7,
            LobbyFilterVariant.CapOmaha,
            LobbyFilterVariant.CapOmaha5,
            LobbyFilterVariant.CapOmaha6,
            LobbyFilterVariant.CapOmaha7,
            LobbyFilterVariant.TriOmaha
          ]
          break;

        case LobbyFilterVariant.StudGames:
          filters.variants = [
            LobbyFilterVariant.SevenStud,
            LobbyFilterVariant.SevenStudHiLo,
            LobbyFilterVariant.Razz
          ]
          break;

        case LobbyFilterVariant.DrawGames:
          filters.variants = [
            LobbyFilterVariant.Badugi,
            LobbyFilterVariant.TripleDraw,
            LobbyFilterVariant.FiveCardDraw,
            LobbyFilterVariant.Badeucey
          ]
          break;

        case LobbyFilterVariant.Any:
          break;

        default:
          filters.variants = [lobbyFilters.variants]
          break;
      }
    }

    if (lobbyFilters.stakes !== LobbyFilterStake.Any) {
      filters.stakes = [lobbyFilters.stakes]
    }

    if (lobbyFilters.limits !== LobbyFilterLimit.Any) {
      filters.limits = [lobbyFilters.limits]
    }

    if (lobbyFilters.speeds !== LobbyFilterSpeed.Any) {
      filters.speeds = [lobbyFilters.limits]
    }

    if (lobbyFilters.nbSeats !== LobbyFilterSeat.Any) {
      filters.nbSeats = [lobbyFilters.nbSeats]
    }

    let data = {
      type: ServerRequestMessage.GetTables,
      currency: currency.id,
      filters
    }
    this.ws.sendData(data);
  }



  getMixTablesDetails(tableId: number) {
    const data = {
      type: ServerRequestMessage.GetMixTablesDetails,
      value: tableId
    }

    this.ws.sendData(data);
  }



  // Reducer helpers functions
  updateTableSummary(tableSummaryDTO: TableSummaryDTO | TableSummary, jackPotsInfo: JackPotInfo[]): TableSummary {
    const { minStake, maxStake } = this.getMinAndMaxStake(tableSummaryDTO.limit, tableSummaryDTO.blind)

    const jackPotInfo = getJackPotInfo()


    const tableSummary: TableSummary = {
      ...tableSummaryDTO,
      playerCount: tableSummaryDTO.playerCount ?? 0,
      averagePot: tableSummaryDTO.averagePot ?? 0,
      waitingList: tableSummaryDTO.waitingList ?? 0,

      game: {
        speed: tableSummaryDTO.isFast ? 'Fast' : 'Normal',
        variant: `${VariantType2[tableSummaryDTO.variant2]} ${tableSummaryDTO.isHiLo ? ' Hi/Lo' : ''}`,
        players: `${tableSummaryDTO.playerCount}/${tableSummaryDTO.maxPlayers}`,
        limit: Limit[tableSummaryDTO.limit],
        isMixTable: tableSummaryDTO.variant2 === VariantType2.MixTable,
        minStake,
        maxStake,
        legend: getLegend(),
        circleStyle: getCircleStyle(this._sanitizer),
        jackPotInfo: jackPotInfo
      },
    }

    return tableSummary


    // closure function for getting JackPotInfo
    function getJackPotInfo(): JackPotInfo | undefined {
      let jackPotInfo: JackPotInfo | undefined = undefined;

      if (tableSummaryDTO.jackPotIds && tableSummaryDTO.jackPotIds[0]) {
        jackPotInfo = jackPotsInfo.find(jackPot => jackPot.id === tableSummaryDTO.jackPotIds![0])
      }

      return jackPotInfo;
    }

    // closure function for getting legend
    function getLegend(): TableSummaryLegend[] {
      const data: TableSummaryLegend[] = []
      if (jackPotInfo) { data.push(Legend.JACKPPOT) }

      if (tableSummaryDTO.isFast) { data.push(Legend.FAST) }
      if (tableSummaryDTO.isStraddle) { data.push(Legend.STRADDLE) }
      if (tableSummaryDTO.hasPassword) { data.push(Legend.PRIVATE) }
      if (tableSummaryDTO.ante > 0) { data.push(Legend.ANTE) }
      if (tableSummaryDTO.isRabbitHunting) { data.push(Legend.ANTE) }
      if (tableSummaryDTO.callTimeConfiguration?.isCallTime) { data.push(Legend.CALL_TIME) }


      return data
    }

    function getCircleStyle(sanitizer: DomSanitizer): SafeStyle[] {
      return new Array(tableSummaryDTO.maxPlayers).fill(null).map((_, index) => {

        const styleRotate = 90 - (360 / tableSummaryDTO.maxPlayers) + (index + 1) * (360 / tableSummaryDTO.maxPlayers)
        const styleSkew = 90 - (360 / tableSummaryDTO.maxPlayers)
        return sanitizer.bypassSecurityTrustStyle(`transform:rotate(${styleRotate}deg) skew(${styleSkew}deg)`)
      })
    }
  }

  // Helper function to calculate min and max stake
  // Used for functions: updateTableSummary, updateMixTableDetails
  private getMinAndMaxStake(limit: Limit, blind: number): { minStake: number, maxStake: number } {
    let minStake: number = 0;
    let maxStake: number = 0;

    if (limit === Limit.FL) {
      minStake = blind * 2;
      maxStake = blind * 4;
    } else {
      minStake = blind;
      maxStake = blind * 2;
    }

    return { minStake, maxStake }
  }

  updateTournamentSummary(tournamentSummaryResponse: TournamentSummaryResponse): TournamentSummary {

    const tournamentSummary: TournamentSummary = {
      ...tournamentSummaryResponse,

      game: {
        isBounty: tournamentSummaryResponse.bounty > 0,
        isReBuyEndIn: tournamentSummaryResponse.reBuyNbLimit > 0,
        //   scheduleDemo: '',
        startingDateTime: format(addSeconds(new Date(), tournamentSummaryResponse.startIn ?? 0), 'MMM dd @ h:mm a'),
        startingDate: format(addSeconds(new Date(), tournamentSummaryResponse.startIn ?? 0), 'MMM dd'),
        lateRegistrationTime: tournamentSummaryResponse.periodDuration * tournamentSummaryResponse.lateRegistrationPeriod,
        // @ To Do
        // Add Translation in Pipe


        startInDate: new Date(tournamentSummaryResponse.dateStarting), // ✅ 
        registrationsStartInDate: addSeconds(new Date(), tournamentSummaryResponse.registrationsStartIn), // ✅ 
        lateRegistrationStartInDate: addSeconds(new Date(), (tournamentSummaryResponse.startIn ?? 0) + (tournamentSummaryResponse.lateRegistrationDuration ?? 0) * 60) // ✅


        // schedule:
        //   of({
        //     startInDate: addSeconds(new Date(), tournamentSummaryResponse.startIn), // ✅ 
        //     registrationsStartInDate: addSeconds(new Date(), tournamentSummaryResponse.registrationsStartIn), // ✅ 
        //     lateRegistrationStartInDate: addSeconds(new Date(), tournamentSummaryResponse.startIn + tournamentSummaryResponse.lateRegistrationDuration * 60) // ✅
        //   }).pipe(
        //     switchMap((data) => {
        //       return timer(0, 1000).pipe(
        //         map((iteration) => {

        //           const { startIn, periodDuration, lateRegistrationPeriod, timeBeforeRegistration, registrationsStartIn } = tournamentSummaryResponse
        //           const countdownToStart = (startIn - iteration) * 1000; // in milliseconds
        //           const lateRegistration = periodDuration * lateRegistrationPeriod; // in minutes
        //           const countdownForLateRegistration = (countdownToStart + tournamentSummaryResponse.lateRegistrationDuration * 60 * 1000); // in milliseconds

        //           const lateRegistrationTimeText = lateRegistration.toString() + ' minutes';
        //           const timeBeforeRegistrationStr = timeBeforeRegistration > 1 ?
        //             timeBeforeRegistration.toString() + ' hours prior' : timeBeforeRegistration.toString() + ' hour prior';

        //           switch (tournamentSummaryResponse.status2) {
        //             case TournamentStatus2.Announced: {
        //               // sum.InformationText = 'Registration begins in:';
        //               // sum.ShowTournamentStartTime = false;

        //               // @new
        //               return this.msToTime(this.getMsDiff(data.registrationsStartInDate))

        //               // @old
        //               // return this._formatPeriod((registrationsStartIn - iteration) * 1000);
        //             }
        //             case TournamentStatus2.RegistrationOpen: {
        //               // sum.ShowTournamentStartTime = true;
        //               if (countdownToStart < 0) {
        //                 // sum.InformationText = 'Tournament starting soon';
        //                 return '';
        //               } else {
        //                 // sum.InformationText = 'Tournament begins in:';


        //                 // @ new: 
        //                 return this.msToTime(this.getMsDiff(data.startInDate))
        //               }
        //             }
        //             case TournamentStatus2.RunningWithLateRegistration: {
        //               if (lateRegistration > 0) {
        //                 // sum.CountdownText = 'Late Registration ' + this.formatPeriod(countdownForLateRegistration);


        //                 // @new]

        //                 return this.msToTime(this.getMsDiff(data.lateRegistrationStartInDate))


        //                 // @old
        //                 // return this._formatPeriod(countdownForLateRegistration);

        //               } else {
        //                 //sum.LateRegistrationTimeText = 'Unavailable';
        //                 return '';
        //               }
        //             }
        //             case TournamentStatus2.RunningRegistrationClosed: {
        //               // sum.InformationText = 'Tournament Status:';
        //               return 'Running';
        //               break;
        //             }
        //             case TournamentStatus2.Completed: {
        //               // sum.InformationText = 'Tournament Status:';
        //               return 'Completed';
        //               break;
        //             }
        //             case TournamentStatus2.Canceled: {
        //               // sum.InformationText = 'Tournament Status:';
        //               return 'Canceled';
        //               break;
        //             }
        //             default:
        //               // sum.InformationText = 'Tournament Status:';
        //               return 'Unknown';
        //           }
        //         })
        //       )
        //     })
        //   )

      }
    }
    return tournamentSummary
  }


  updateSpinNGoSummary(spinNGoSummaryResponse: SpinNGoSummaryResponse): SpinNGoSummary {
    const spinNGoSummary: SpinNGoSummary = {
      ...spinNGoSummaryResponse,
      currencyInfo: {} as any,
    }
    return spinNGoSummary
  }

  updateSitNGoSummary(sitNGoSummaryDTO: SitNGoSummaryDTO): SitNGoSummary {
    const sitNGoSummary: SitNGoSummary = {
      ...sitNGoSummaryDTO,
      game: {
        variant: `${VariantType2[sitNGoSummaryDTO.variant]}`,
        speed: sitNGoSummaryDTO.isFast ? 'Fast' : 'Normal',
        // buyIn: this.getTounamentBuyIn({ entryFee: sitNGoSummaryDTO.entryFee, houseFeePercentage: sitNGoSummaryDTO.houseFeePercentage }, currnecy),
        limit: Limit[sitNGoSummaryDTO.limit],
      }
    }
    return sitNGoSummary
  }

  // private getTournamentPlayers(config: { status: TournamentStatus2, minPlayers: number, maxPlayers: number, nbPlayerRegistered: number, NbPlayersActive: number, nbPlayersActive: number }): string {
  // switch (config.status) {
  //   case TournamentStatus2.Announced:
  //     return `min: ${item.MinPlayers} / max: ${item.MaxPlayers}`;
  //   case TournamentStatus2.RegistrationOpen:
  //     return `${item.NbPlayersActive} / ${item.MaxPlayers}`;
  //   case TournamentStatus2.RunningWithLateRegistration:
  //     return `${item.NbPlayersActive} / ${item.NbPlayerRegistered}`;
  //   case TournamentStatus2.RunningRegistrationClosed:
  //     return `${item.NbPlayersActive} / ${item.NbPlayerRegistered}`;
  //   case TournamentStatus2.Completed:
  //     return `${item.NbPlayerRegistered}`;
  //   case TournamentStatus2.Canceled:
  //     return `${item.NbPlayerRegistered}`;
  //   default:
  //     return `${item.NbPlayersActive} / ${item.NbPlayerRegistered}`;
  // }
  // }

  private getTounamentBuyIn(config: { freeroll?: boolean, initialBuyFree?: boolean, optionOnlyTicket?: boolean, entryFee: number, houseFeePercentage: number }, currency: CurrencyInfo): string {
    if (config.freeroll) {
      return 'Freeroll'
    }

    if (config.initialBuyFree) {
      return '1st Buy In Free';
    }

    if (config.optionOnlyTicket) {
      return 'Ticket';
    }

    const fromPrice = GameCurrencyPipe.prototype.transform(config.entryFee, currency)
    const toPrice = GameCurrencyPipe.prototype.transform(config.entryFee * config.houseFeePercentage, currency)

    return `${fromPrice} + ${toPrice}`
  }

  updateMixTableDetails(mixTablesDetail: MixTableDetailsTableDTO): MixTableDetailsTable {
    const { minStake, maxStake } = this.getMinAndMaxStake(mixTablesDetail.limit, mixTablesDetail.blind)

    return {
      ...mixTablesDetail,
      limit: mixTablesDetail.limit ?? 0,
      blind: mixTablesDetail.blind ?? 0,
      ante: mixTablesDetail.ante ?? 0,
      bringIn: mixTablesDetail.bringIn ?? 0,
      game: {
        variant: `${VariantType2[mixTablesDetail.variant]} ${mixTablesDetail.isHiLo ? ' Hi/Lo' : ''}`,
        limit: Limit[mixTablesDetail.limit],
        minStake,
        maxStake,
      }
    }
  }




  private _formatPeriod(timePeriod: number): string {
    if (timePeriod < 0) { timePeriod = 0; }

    let strToRet = '';
    const secondsTotal = (timePeriod - (timePeriod % 1000)) / 1000;
    const seconds = secondsTotal % 60;
    const minutesTotal = (secondsTotal - (secondsTotal % 60)) / 60;
    const minutes = minutesTotal % 60;
    const hoursTotal = (minutesTotal - minutes) / 60;
    const hours = hoursTotal % 24;
    const days = (hoursTotal - hours) / 24;

    const secondsStr = seconds < 10 ? '0' + seconds.toString() : seconds.toString();
    const minutesStr = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
    const hoursSrt = hours < 10 ? '0' + hours.toString() : hours.toString();
    let daysStr = '';
    if (days > 0) {
      if (days > 1) {
        daysStr = days.toString() + ' days, ';
      } else {
        daysStr = days.toString() + ' day, ';
      }
    }
    strToRet = daysStr + hoursSrt + ':' + minutesStr + ':' + secondsStr;

    return strToRet;
  }



  // @ new way




  // ++++++ TOURNAMENT SERVICE ++++++

  tournamentRegister(idTournament: number, register: boolean): void {
    const data = {
      type: ServerRequestMessage.MTTRegister,
      idTournament,
      value2: register ? 1 : 0
    }

    this.ws.sendData(data);
  }
}
