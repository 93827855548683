import { Injectable, inject } from '@angular/core';
import { ServerRequestMessage } from '@app/enums/server-request';
import { WsService } from '@app/services/ws.service';
import { Transaction, TransactionDTO, TransactionType } from '../models/transaction';
import { Gateway, GatewayDTO } from '../models/gateway';
import { VerificationDocumentType } from '../models/verification-document-type';
import { fi } from 'date-fns/locale';
import { InputType } from "@app/components/input/input.component";

@Injectable({
  providedIn: 'root'
})
export class CashierService {

  private readonly _ws = inject(WsService)


  transferMoneyToPlayer(username: string, amount: number, currency: number, note?: string) {
    this._ws.sendData({
      type: ServerRequestMessage.TransferMoney,
      value: amount,
      value2: currency,
      str1: username,
      str2: note
    });
  }



  getTransactionsHistory(currencyId: number) {
    this._ws.sendData({
      type: ServerRequestMessage.RequestTransactionHistory,
      currency: currencyId
    });
  }

  cancelWithdrawal(currencyId: number) {
    this._ws.sendData({
      type: ServerRequestMessage.WithdrawalCancel,
      currency: currencyId
    });
  }


  requestDeposit(gatewayName: string, amount: number, currencyId: number, promoCode?: string) {
    this._ws.sendData({
      type: ServerRequestMessage.DepositInitiate,
      value2: amount,
      value3: currencyId,
      str1: promoCode,
      str2: gatewayName,
    });
  }

  requestWithdrawal(gatewayId: string, amount: number, currencyId: number, extraInfo: string) {
    this._ws.sendData({
      type: ServerRequestMessage.WithdrawalRequest,
      value2: amount,
      currency: currencyId,
      str1: extraInfo,
      str2: gatewayId
    });
  }


  uploadDocument(documentBase64: string, type: VerificationDocumentType) {
    this._ws.sendData({
      type: ServerRequestMessage.ImageUpload,
      str1: documentBase64,
      value: type
    });
  }

  /**
   * @description
   * Phone verification:
   * requestPhoneVerificationCodeSms()
   * submitPhoneCode()
   */

  requestPhoneVerificationCodeSms() {
    this._ws.sendData({ type: ServerRequestMessage.RequestPhoneCode });
  }

  submitPhoneCode(code: string) {
    this._ws.sendData({
      type: ServerRequestMessage.SubmitPhoneCode,
      str1: code
    });
  }

  /* ======================================= */

  fromTransaction(transaction: TransactionDTO): Transaction {
    let typeFormatted = '';
    switch (transaction.type) {
      case TransactionType.Deposit:
        typeFormatted = 'Deposit';
        break;
      case TransactionType.Withdraw:
        typeFormatted = 'Withdrawal';
        break;
      case TransactionType.Transfer:
        typeFormatted = 'Sent Money';
        break;
      case TransactionType.TransferFrom:
        typeFormatted = 'Received Money';
        break;
      case TransactionType.GiftDepositBonus:
        typeFormatted = 'Gift deposit bonus';
        break;
      case TransactionType.GiftRakeBackBonus:
        typeFormatted = 'RakeBack';
        break;
      case TransactionType.WithdrawCanceled:
        typeFormatted = 'Cancelled of a withdrawal';
        break;
      case TransactionType.IncomePayout:
        typeFormatted = 'Income release';
        break;
      case TransactionType.RabbitHunting:
        typeFormatted = 'Rabbit Hunting';
        break;

    }


    return {
      ...transaction,
      dateFormatted: new Date(transaction.date),
      typeFormatted
    }
  }


  /**
  * @description
  * Formatting gateways because of the language
  */
  fromGateway(gatewayDTO: GatewayDTO): Gateway {
    const gateway = { ...gatewayDTO } as Gateway;

    gateway.currentLang = {
      description: this.getCurrentLanguageDataValue(gateway.description),
      displayName: this.getCurrentLanguageDataValue(gateway.displayName),
    }

    gateway!.fields = gateway!.fields.map(field => {
      field.currentLang = {
        hint: this.getCurrentLanguageDataValue(field.hint),
      }
      field.appInputType = field.inputType === 'number' ? 'number' : 'text';
      return field;
    })

    return gateway;
  }

  getCurrentLanguageDataValue(data: { langId: string; value: string }[], currentLanguage?: string): string {

    if (!data || data.length === 0) {
      return ''
    }

    if (!currentLanguage) {
      currentLanguage = 'en';
    }

    const currentLanguageData = data.find(lang => lang.langId === currentLanguage);

    if (!currentLanguageData) {
      return data[0].value;
    }

    return currentLanguageData.value
  }
}
