import { createReducer, on } from '@ngrx/store';
import * as UserActions from '@app/store/features/user/user.actions';
import { SkinSettings } from '@app/models/skin-settings';
import { GlobalSettings } from '@app/models/global-settings';
import { MemberProfile } from '@app/models/member-profile';
import { CurrencyInfo } from '@app/models/currency-info';
import { PlayerBalance } from '@app/models/player-balance';


// 
export interface UserLocalSettings {
    neverRabbitHunt: boolean;
}


export interface State {
    profile: MemberProfile | null;

    checkEmailExist?: {
        email: string;
        exist: boolean;
    },
    checkUsernameExist?: {
        username: string;
        exist: boolean;
    }
    // Local Storage
    localSettings: UserLocalSettings
}

export const initialState: State = {
    profile: null,
    localSettings: {
        neverRabbitHunt: false
    }
};

export const reducer = createReducer(
    initialState,
    on(UserActions.updateProfile, (state, { profile }) => {
        const localStorageUserSettings = localStorage.getItem(`${'userLocalSettings'}-${profile.id}`) || null;
        const localSettings = localStorageUserSettings ? JSON.parse(localStorageUserSettings) : {
            neverRabbitHunt: false
        };

        return ({ ...state, profile, localSettings })
    }),
    on(UserActions.updateEmailExist, (state, { email, exist }) => ({ ...state, checkEmailExist: { email, exist } })),
    on(UserActions.updateUsernameExist, (state, { username, exist }) => ({ ...state, checkUsernameExist: { username, exist } })),

    on(UserActions.updateMemberPreferences, (state, { preferences }) => {
        return ({ ...state, profile: { ...state.profile!, preferences } })
    }),

    on(UserActions.neverRabbitHunt, (state, { turnOn }) => {
        const localSettings = { ...state.localSettings, neverRabbitHunt: turnOn };
        localStorage.setItem(`${'userLocalSettings'}-${state.profile!.id}`, JSON.stringify(localSettings));

        return ({ ...state, localSettings })
    })
);