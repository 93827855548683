<router-outlet></router-outlet>
<div class="main-background"
    [ngClass]="{'dark': darkMainBackground$ | async}"
    [ngStyle]="{'background-image': 'url(' + (mainBackgroundUrl$ | async) + ')'}"
    ></div>
<ng-template cdkConnectedOverlay
    [cdkConnectedOverlayOpen]="!(getGameInitialized$ | async) || (getDefaultAssetsLoaded$ | async)! < 100"
    [cdkConnectedOverlayOrigin]="{x: 0, y: 0}"
    cdkConnectedOverlayPanelClass="init-loader-panel">

    <div style="display: flex;flex-direction: column; align-items: center;">
        <app-init-loader></app-init-loader>

        <div style="color: #fff; font-size: 24px;">
            {{getDefaultAssetsLoaded$ | async}}%
        </div>

        <!-- <div style="color: #fff;">
            <br><br>
            {{getDefaultAssetsLoaded$ | async }} {{(getDefaultAssetsLoaded$ |
            async)! < 100}} {{!(getGameInitialized$ | async)}}
        </div> -->
    </div>
</ng-template>

<!-- 
<div style="position: fixed; top:0px; left: 0px; width: 100%; height: 100%; background-color: gray;">
    <div>ddddemo</div>
    <div #pixiGameContainer></div>
</div> -->