
import { Injectable, inject } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';

import * as LobbyChatActions from './lobby-chat.actions';
import { WsService } from '@app/services/ws.service';
import { TableSummary, TableSummaryDTO } from '@app/models/table-summary';
import { LiveLobbyRing } from '@app/models/live-lobby-ring';
import { CurrencyService } from '@app/services/currency.service';
import { CurrencyInfoDTO } from '@app/models/currency-info';
import * as LobbyActions from '@app/store/features/lobby/lobby.actions';
import { LobbyChatService } from '@app/features/lobby-chat/services/lobby-chat.service';
import { ServerMsgInfo, ServerMsgLobbyChat } from '@app/models/server-msg';
import { ServerMessageType } from '@app/enums/server-message-type';
import { LobbyChatAdapterService } from '@app/features/lobby-chat/services/lobby-chat-adapter.service';
import * as ConfigSelectors from '@app/store/features/config/config.selectors';

@Injectable()
export class LobbyChatEffects {
    private readonly _ws = inject(WsService);
    private readonly _actions$ = inject(Actions);
    private readonly _store = inject(Store);
    private readonly _lobbyChatService = inject(LobbyChatService);
    private readonly _lobbyChatAdapterService = inject(LobbyChatAdapterService);

    // 
    constructor() {
    }


    onServerMessageTypeChat$ = createEffect(() =>
        this._store.pipe(
            select(ConfigSelectors.getDomainSettings),
            filter(domainSettings => !!domainSettings),
            take(1),
            switchMap(domainSettings => this._ws.getServerMsg<ServerMsgLobbyChat>(ServerMessageType.Chat)
                .pipe(
                    filter(serverMsgLobbyChat => !serverMsgLobbyChat.idTable),
                    map(serverMsgLobbyChat => {

                        const chatMessage = this._lobbyChatAdapterService.from(serverMsgLobbyChat, domainSettings!)
                        if (chatMessage.idReplaced) {
                            return LobbyChatActions.updateOne({ id: chatMessage.idReplaced, message: chatMessage })
                        }

                        return LobbyChatActions.upsertOne({ message: chatMessage })

                    })
                ))));

    getChatHistory$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LobbyChatActions.getChatHistory),
                map(({ numberOfMessages, oldestMessageId }) => this._lobbyChatService.getChatHistory(oldestMessageId, numberOfMessages))
            ), { dispatch: false })


    onSendMessage$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LobbyChatActions.sendMessage),
                map(({ message }) => this._lobbyChatService.sendMessage(message))
            ), { dispatch: false })
} 