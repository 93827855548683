import { NgModule, importProvidersFrom } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './features/auth/guards/auth.guard';


import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '@app/store/features/auth/auth.effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import * as AuthReducer from '@app/store/features/auth/auth.reducer';
import { AuthWrapperComponent } from './features/auth/containers/auth-wrapper/auth-wrapper.component';
import { loginGuard } from './features/auth/guards/login.guard';
import { forgotPasswordGuard } from './features/auth/guards/forgot-password.guard';
import { mainGuard } from './features/main/guards/main.guard';


import * as LobbyReducer from '@app/store/features/lobby/lobby.reducer';
import { LobbyEffects } from '@app/store/features/lobby/lobby.effects';

import * as mainReducers from '@app/store/features/main/main.reducers';
import { TableSummariesEffects } from './store/features/table-summaries/table-summaries.effects';
import { SpinNGoSummariesEffects } from './store/features/spin-n-go-summaries/spin-n-go-summaries.effects';
import { SitNGoSummariesEffects } from './store/features/sit-n-go-summaries/sit-n-go-summaries.effects';
import { TournamentsSummariesEffects } from './store/features/tournament-summaries/tournament-summaries.effects';
import { TablesEffects } from './store/features/tables/tables.effects';
import { TournamentsEffects } from './store/features/tournaments/tournaments.effects';
import { UserEffects } from './store/features/user/user.effects';
import { CurrenciesEffects } from './store/features/currencies/currencies.effects';
import { PlayerBalanceEffects } from './store/features/player-balance/player-balance.effects';
import { LobbyChatEffects } from './store/features/lobby-chat/lobby-chat.effects';
import { TicketsEffects } from './store/features/tickets/tickets.effects';
import { PlayerLevelStatusEffects } from './store/features/player-level-status/player-level-status.effects';
import { GiftsEffects } from './store/features/gifts/gifts.effects';
import { RAFBonusesEffects } from './store/features/raf-bonuses/raf-bonuses.effects';
import { CashierEffects } from './store/features/cashier/cashier.effects';
import { GamesEffects } from './store/features/games/games.effects';


export const routes: Routes = [
  {
    path: 'auth',
    canActivate: [authGuard],
    loadComponent: () => import('@app/features/auth/containers/auth-wrapper/auth-wrapper.component').then(m => m.AuthWrapperComponent),

    //providers: [
    // importProvidersFrom(
    //   StoreModule.forFeature('auth', AuthReducer.reducer),
    //   EffectsModule.forFeature([AuthEffects])
    // )
    //],
    children: [
      {
        path: 'login',
        title: 'Login',
        loadComponent: () => import('@app/features/auth/containers/login/login.component').then(m => m.LoginComponent),
        canActivate: [loginGuard],

      },
      {
        path: 'forgot-password',
        title: 'Forgot Password',
        loadComponent: () => import('@app/features/auth/containers/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent),
        canActivate: [forgotPasswordGuard],

      },
      { path: '**', redirectTo: 'login' },
    ]
  },


  {
    path: '',
    canActivate: [mainGuard],
    loadComponent: () => import('@app/features/main/containers/main-wrapper/main-wrapper.component').then(m => m.MainWrapperComponent),
    providers: [
      importProvidersFrom(
        StoreModule.forFeature('main', mainReducers.reducers),
        EffectsModule.forFeature([
          GamesEffects,
          CurrenciesEffects,
          LobbyEffects,
          TableSummariesEffects,
          SitNGoSummariesEffects,
          SpinNGoSummariesEffects,
          TournamentsSummariesEffects,
          TablesEffects,
          TournamentsEffects,
          UserEffects,
          PlayerBalanceEffects,
          LobbyChatEffects,
          TicketsEffects,
          PlayerLevelStatusEffects,
          GiftsEffects,
          RAFBonusesEffects,
          CashierEffects,

        ])
      )
    ],
    children: [
      {
        path: 'lobby',
        title: 'Game Lobby',
        loadComponent: () => import('@app/features/lobby/containers/lobby/lobby.component').then(m => m.LobbyComponent),
      },
      {
        path: 'tournament/:id',
        title: 'Tournament',
        loadComponent: () => import('@app/features/tournament/containers/tournament/tournament.component').then(m => m.TournamentComponent),
      },
      {
        path: 'user',
        title: 'User',
        loadComponent: () => import('@app/features/user/containers/user-edit-profile/user-edit-profile.component').then(m => m.UserEditProfileComponent),
      },
      {
        path: 'cashier',
        title: 'Cashier',
        loadComponent: () => import('@app/features/cashier/containers/cashier/cashier.component').then(m => m.CashierComponent),
      },
      {
        path: 'theme',
        title: 'Theme Settings',
        loadComponent: () => import('@app/features/theme/containers/theme/theme.component').then(m => m.ThemeComponent),
      },
      {
        path: 'button-configuration',
        title: 'Button Configuration',
        loadComponent: () => import('@app/features/button-configuration/containers/button-configuration/button-configuration.component').then(m => m.ButtonConfigurationComponent),
      },
      {
        path: 'game/:id',
        title: 'Game',
        loadComponent: () => import('@app/features/game/containers/game/game.component').then(m => m.GameComponent),
      },
      {
        path: 'hand-history',
        title: 'Hand History',
        loadComponent: () => import('@app/features/hand-history/containers/hand-history/hand-history.component').then(m => m.HandHistoryComponent),
      },
      {
        path: 'table-sessions',
        title: 'Table Sessions',
        loadComponent: () => import('@app/features/hand-history/containers/table-sessions/table-sessions.component').then(m => m.TableSessionsComponent),
      },
      {
        path: 'hand-sessions/:tableSessionId',
        title: 'Hand Sessions',
        loadComponent: () => import('@app/features/hand-history/containers/hand-sessions/hand-sessions.component').then(m => m.HandSessionsComponent),
      },
      {
        path: 'hand/:handId',
        title: 'Game',
        loadComponent: () => import('@app/features/game/containers/game/game.component').then(m => m.GameComponent),
      },
      { path: '**', redirectTo: 'lobby' },
    ]


  },
  { path: '**', redirectTo: 'auth' },
];

