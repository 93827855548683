import { Injectable, inject } from '@angular/core';
import { ServerRequestMessage } from '@app/enums/server-request';
import { WsService } from '@app/services/ws.service';
import { Player } from '../elements/player';
import { Assets, Container } from 'pixi.js';
import { Point } from '../models/point';
import { Seat } from '../elements/seat';
import { GlobalSettings } from '@app/models/global-settings';
import { DomainSettings } from '@app/models/domain-settings';
import { Game, GameBetControls, GamePlayer, MemberPreferencesRunItTwice } from '@app/store/features/games/games.reducer';
import { SitNGoSummary } from '@app/models/sit-n-go-summary';
import { TournamentSummary } from '@app/models/tournament-summary';
import { TableSummary } from '@app/models/table-summary';
import { PlayerBalance } from '@app/models/player-balance';
import { GameDialogBuyChipsComponent } from '../presenters/game-dialog-buy-chips/game-dialog-buy-chips.component';
import { Dialog } from '@angular/cdk/dialog';
import { take } from 'rxjs';
import { BuyChips } from '../models/buy-chips';
import { PlayerStatus } from '../enums/player-status.enum';
import { Limit } from '@app/models/limit';
import { MemberProfile } from '@app/models/member-profile';
import { GenericDialogComponent } from '@app/components/generic-dialog/generic-dialog.component';
import { GameCurrencyPipe } from '@app/pipes/game-currency.pipe';
import { ToastrService } from 'ngx-toastr';
import { CardData } from '../models/card-data';
import { Store } from '@ngrx/store';
import { NoteColors } from '../enums/note-colors';
import { ConfigTheme } from '@app/store/features/config/config.reducer';
import { WaitingListCommand } from '@app/enums/waiting-list-command';
import { HandType, HandTypesText } from '../enums/hand-type';
import { cardDecoder, cardNameDecoder, cardSuitDecoder } from '../helpers/card-decoder';

@Injectable({
  providedIn: 'root'
})
export class GameService {
  private readonly _store = inject(Store);

  constructor(private readonly ws: WsService, private readonly _dialog: Dialog, private readonly _toastr: ToastrService
  ) { }

  sendMessage(tableId: number, message: string) {
    const data = {
      type: ServerRequestMessage.Chat,
      idTable: tableId,
      str1: message
    }
    this.ws.sendData(data);
  }


  takeSeat(tableId: number, seatPosition: number) {
    this.ws.sendData({
      type: ServerRequestMessage.TakeSeat,
      idTable: tableId,
      value2: seatPosition
    });
  }

  requestLeaveTable(tableId: number) {
    this.ws.sendData({
      type: ServerRequestMessage.LeaveTable,
      idTable: tableId,
    });
  }

  requestLeaveSeat(tableId: number) {
    this.ws.sendData({
      type: ServerRequestMessage.ChangeStatus,
      idTable: tableId,
      value2: PlayerStatus.LeaveSeat
    });
  }

  requestBuyChips(tableId: number, chipsAmount: number) {
    this.ws.sendData({
      type: ServerRequestMessage.BuyChips,
      idTable: tableId,
      value2: chipsAmount
    });
  }

  requestImBack(tableId: number) {
    this.ws.sendData({
      type: ServerRequestMessage.ChangeStatus,
      IdTable: tableId,
      Value2: PlayerStatus.Ready,
    });
  }


  requestFold(tableId: number, handNumber: number) {
    this.ws.sendData({
      type: ServerRequestMessage.Bid,
      idTable: tableId,
      value2: -1,
      handNumber
    });
  }

  requestCheck(tableId: number, checkValue: number, handNumber: number) {
    this.ws.sendData({
      type: ServerRequestMessage.Bid,
      idTable: tableId,
      value2: checkValue,
      handNumber
    });
  }
  requestCall(tableId: number, checkValue: number, handNumber: number) {
    this.ws.sendData({
      type: ServerRequestMessage.Bid,
      idTable: tableId,
      value2: checkValue,
      handNumber
    });
  }
  requestRaise(tableId: number, raiseValue: number, handNumber: number) {
    this.ws.sendData({
      type: ServerRequestMessage.Bid,
      idTable: tableId,
      value2: raiseValue,
      handNumber
    });
  }
  requestAllIn(tableId: number, allInValue: number, handNumber: number) {
    this.ws.sendData({
      type: ServerRequestMessage.Bid,
      idTable: tableId,
      value2: allInValue,
      handNumber
    });
  }

  runItTwiceTable(tableId: number, value: MemberPreferencesRunItTwice) {
    this.ws.sendData({
      type: ServerRequestMessage.SetRunItTwice,
      idTable: tableId,
      value
    });
  }

  runItTwiceGlobal(value: MemberPreferencesRunItTwice) {
    this.ws.sendData({
      type: ServerRequestMessage.SetRunItTwice,
      value
    });
  }

  getBlind(data: { tourSum?: TournamentSummary, sngSum?: SitNGoSummary, tableSum?: TableSummary }): number {
    if (data.tourSum !== undefined) {
      if (data.tourSum.blind === 0) {
        data.tourSum.blind = data.tourSum.blindStart;
      }
      return data.tourSum.blind;
    } else if (data.sngSum !== undefined) {
      return data.sngSum.blind;
    } else if (data.tableSum !== undefined) {
      // TODO: if replay table
      // if (this.id < 0) {
      //   return 0;
      // }
      return data.tableSum!.blind;
    } else {
      return 0;
    }
  }


  getLimit(data: { tourSum?: TournamentSummary, sngSum?: SitNGoSummary, tableSum?: TableSummary }): Limit {
    if (data.tourSum !== undefined) {
      return data.tourSum.limit;
    } else if (data.sngSum !== undefined) {
      return data.sngSum.limit;
    } else if (data.tableSum !== undefined) {
      // TODO: if replay table
      // if (this.id < 0) {
      //return Limit.UNKNOWN;
      // }
      return data.tableSum.limit;
    } else {

      return Limit.UNKNOWN;
    }
  }

  buyChips(data: {
    tableId: number,
    dialogTimeout: number,
    tableSum: TableSummary,
    player: GamePlayer,
    gameData: Game,
    playerBalance: PlayerBalance
  }) {

    const minTableChips = this.getBlind({ tableSum: data.tableSum }) * data.tableSum.takeSeatMin * 2;
    const maxTableChips = this.getBlind({ tableSum: data.tableSum }) * data.tableSum.takeSeatMax * 2;
    const defaultTableChips = this.getBlind({ tableSum: data.tableSum }) * data.tableSum.takeSeatDefault * 2;
    const isTournament = data.gameData.isTournament;

    // data.player.money = data.player.money ?? 0;

    if (data.player.money >= maxTableChips) {
      this._toastr.error('You have maximum number of chips to play on this table', 'Buy Chips');
      return;
    }

    const minBuy = minTableChips > data.player.money ? minTableChips - data.player.money : 0.1 * data.gameData.currency.multiplier;
    const maxBuy = data.playerBalance.availableMoney > maxTableChips ? maxTableChips - data.player.money : data.playerBalance.availableMoney;
    const defaultBuy = Math.round(maxBuy > defaultTableChips ? defaultTableChips : maxBuy);



    if (data.playerBalance.availableMoney >= minBuy) {


      const buyChipsData: BuyChips = {
        available: data.playerBalance.availableMoney,
        defaultBuy,
        min: minBuy,
        max: maxBuy,
        step: this.getBlind({ tableSum: data.tableSum }),
        currency: data.gameData.currency,
        dialogTimeout: data.dialogTimeout,
      }
      this._dialog.open<number>(GameDialogBuyChipsComponent, { width: '460px', data: buyChipsData }).closed.pipe(
        take(1))
        .subscribe((chipsAmount) => {
          if (chipsAmount) {
            this.requestBuyChips(data.tableId, chipsAmount)
            this.requestImBack(data.tableId);
          } else {
            if (data.player.money === 0) {
              this.requestLeaveSeat(data.tableId);
              // ⏺ gameComponent.checkBuyChipsVisibility()
            }
          }
        })
    } else {
      const minAmountToDisplay = isTournament ? `${minTableChips}` : GameCurrencyPipe.prototype.transform(minTableChips, data.gameData.currency);

      const dialog = this._dialog.open(GenericDialogComponent, { width: '300px' })
      dialog.componentInstance!.title = 'Insufficient Funds';
      dialog.componentInstance!.text = `Minimum amount required: ${minAmountToDisplay}`
      dialog.componentInstance!.dissmissBtn = 'Ok'



    }
  }










  updateRaiseButton(data: {
    minimumBet: number,
    isBringIn: number
    betControls: GameBetControls
  }) {

    switch (data.minimumBet) {
      case 0:
        data.betControls.raiseButtonText = 'BUTTON.BET';
        // ⏺ this.addValueToBetButton();
        break;

      case -1:

        data.betControls.raiseButtonText = 'BUTTON.ALLIN';
        // ⏺this.addValueToBetButton();

        break;

      default:

        if (data.betControls.betSlider.min) {

          data.betControls.raiseButtonText = 'BUTTON.RAISE';
          data.betControls.callButtonText = 'BUTTON.CALL';
          data.betControls.checkButtonText = 'BUTTON.CHECK';
          // ⏺  this.bringInButtonText();
          // ⏺ this.addValueToRaiseButton();
        } else {

          // ⏺this.addValueToBetButton();

          data.betControls.raiseButtonText = 'BUTTON.ALLIN';
        }
        break;
    }
  }












  actionFold(data: {
    tableId: number,
    currentHandNumber: number,
    betControls: GameBetControls,
    handType: string,
  }) {

    this.requestFold(data.tableId, data.currentHandNumber);
    data.betControls.showBetButtons = false;
    data.handType = '';

    // ⏺ this.setReplayButtonsVisibility();
  }

  actionCheck(data: {
    tableId: number,
    checkValue: number,
    currentHandNumber: number,
    betControls: GameBetControls,
  }) {
    this.requestCheck(data.tableId, data.checkValue, data.currentHandNumber);
    data.betControls.showBetButtons = false;

    // ⏺ this.setReplayButtonsVisibility();
  }

  actionCall(data: {
    tableId: number,
    callValue: number,
    currentHandNumber: number,
    betControls: GameBetControls,
  }) {
    this.requestCall(data.tableId, data.callValue, data.currentHandNumber);
    data.betControls.showBetButtons = false;

    // ⏺ this.setReplayButtonsVisibility();
  }

  actionRaise(data: {
    tableId: number,
    raiseValue: number,
    currentHandNumber: number,
    betControls: GameBetControls,
  }) {
    this.requestRaise(data.tableId, data.raiseValue, data.currentHandNumber);
    data.betControls.showBetButtons = false;
    // ⏺ this.setReplayButtonsVisibility();
  }



  actionAllIn(data: {
    tableId: number,
    allInValue: number,
    currentHandNumber: number,
    betControls: GameBetControls,
  }) {
    this.requestAllIn(data.tableId, data.allInValue, data.currentHandNumber);
    data.betControls.showBetButtons = false;
    // ⏺this.setReplayButtonsVisibility();
  }


  actionImBack(tableId: number) {
    this.requestImBack(tableId);
  }











  setPlayerNoteText(playerId: number, noteText: string) {
    this.ws.sendData({
      type: ServerRequestMessage.SetPlayerNotes,
      Value: playerId,
      Str1: noteText
    });
  }

  setPlayerNoteColor(playerId: number, colorId: string) {
    this.ws.sendData({
      type: ServerRequestMessage.SetPlayerColor,
      value: playerId,
      str1: colorId
    });
  }




  getHandHistory(fromHandNumber?: number) {
    this.ws.sendData({
      type: ServerRequestMessage.HandHistory,
      value: fromHandNumber ? fromHandNumber : 2696347 // pagination
    });
  }















  // pixi.js

  tableHideSeats(seats: (Seat | Player)[]) {
    for (let i = 0; i < seats.length; i++) {
      if (seats[i] instanceof Seat) {
        (<Seat>seats[i]).hideSeat();
      }
    }
  }

  tableAddPlayer(
    data: {
      seatPosition: number,
      player: GamePlayer,

      container: Container,
      seats: (Seat | Player)[],
      playersPositions: Point[],
      globalSettings: GlobalSettings,
      domainSettings: DomainSettings,
      gameData: Game,
      userProfile: MemberProfile,
      theme: ConfigTheme
    }
  ) {
    const { theme } = data;

    const cards: CardData[] = ((data.player.cards as unknown as CardData[]) ?? []).map(card => {
      return {
        ...card,
        textures: {
          cardTexture: card.isHidden ? Assets.get('game-backCard') : Assets.get(`playerCardAtlas-${theme.card}`).textures[`style_id_${theme.card}_${card.name}.png`],
          rabbitHuntingCardCover: Assets.get('game-rabbitHuntingCardCover'),
        }
      }
    })

    const playerNote: any = {}

    for (const key in Object.keys(NoteColors).slice(Object.keys(NoteColors).length / 2)) {
      playerNote[`${NoteColors[key]}`] = Assets.get(`playerNote-${NoteColors[key]}`);
    }


    data.seats[data.seatPosition] = new Player(
      this._store,
      {
        infoBox: {
          default: Assets.get('game-playerDefault'),
          active: Assets.get('game-playerActive'),
          disconnected: Assets.get('game-playerDisconnected'),
          fold: Assets.get('game-playerFold'),
          sitout: Assets.get('game-playerSitout'),
        },
        avatar: Assets.get('game-emptyAvatar'),
        avatarOverlay: {
          default: Assets.get('game-avatarDefault'),
          active: Assets.get('game-avatarActive'),
          disconnected: Assets.get('game-avatarDisconnected'),
          fold: Assets.get('game-avatarFold'),
          sitout: Assets.get('game-avatarSitout')
        },
        playerStraddle: Assets.get('game-playerStraddle'),
        playerDealer: Assets.get('game-playerDealer'),
        playerProMark: Assets.get('game-playerProMark'),
        playerTimebank: {
          barBackground: Assets.get('game-barBackground'),
          barCap: Assets.get('game-barCap'),
          barMiddle: Assets.get('game-barMiddle'),
          barGlow: Assets.get('game-barGlow'),
        },
        playerNote,
        chatBubble: Assets.get('game-chatBubble'),
        callTime: {
          callTimeAttention: Assets.get('game-callTimeAttention'),
          callTimeCheck: Assets.get('game-callTimeCheck'),
          callTimeBackground: Assets.get('game-callTimeBackground'),
        }
      },
      data.playersPositions[data.seatPosition],
      {
        avatarUrl: data.player.avatar ? `${data.domainSettings.httpUrl}/avatar/${data.player.avatar}` : undefined,
        currency: data.gameData.currency,
        playerId: data.player.id,
        tableId: data.gameData.idTable,
        playerName: data.player.name,
        balance: data.player.money,
        status: data.player.status,
        isProPlayer: data.player.mark === 1,
        timebank: {
          normalTime: (data.gameData.isFast ? data.globalSettings.timeToPlayFast : data.globalSettings.timeToPlayNormal) * 1000
        },
        gamePositions: {
          playerNameMaxLength: 14 // to do 
        },
        isMyPlayer: data.player.id === data.userProfile.id,
        seatNumber: data.seatPosition,
        cards,
        memberPreferences: data.userProfile.preferences,
        playerCallTime: data.player.playerCallTime,
      }
    );

    data.container.addChild(data.seats[data.seatPosition].container);

  }

  joinWaitingList(tableId: number) {
    this.ws.sendData({
      type: ServerRequestMessage.TableSetWaiting,
      idTable: tableId,
      value2: WaitingListCommand.Join,
    });
  }

  leaveWaitingList(tableId: number) {
    this.ws.sendData({
      type: ServerRequestMessage.TableSetWaiting,
      idTable: tableId,
      value2: WaitingListCommand.Leave,
    });
  }

  getHandReplayData(handId: number) {
    this.ws.sendData({
      type: ServerRequestMessage.HandReplay2,
      value: handId
    });
  }

  offerRabbitHunting(idTable: number, handNumber: number) {
    this.ws.sendData({
      type: ServerRequestMessage.AskRabbitHunting,
      idTable,
      handNumber
    });
  }

  startCallTime(idTable: number) {
    this.ws.sendData({
      type: ServerRequestMessage.CallTimeAccepted,
      idTable,
    });
  }

  getCallTimeStatus(idTable: number) {
    this.ws.sendData({
      type: ServerRequestMessage.CallTimeStatus,
      idTable,
    });
  }

  showCards(tableId: number, currentHandNumber: number, showCardsConfirmed: boolean) {
    this.ws.sendData({
      type: ServerRequestMessage.Muck,
      idTable: tableId,
      handNumber: currentHandNumber,
      value2: showCardsConfirmed ? 1 : 0
    });
  }


  requestHandStrength(variant: number, playerCards: string, publicCards: string, hiLo: boolean, nbPlayers: number) {
    this.ws.sendData({
      type: ServerRequestMessage.HandStrength,
      variant,
      playerCards,
      publicCards,
      hiLo,
      nbPlayers
    });
  }

  getHandType(handType: any, cards: CardData[]) {

    console.log('playerHandType', handType, cards)

    // if (myPlayer === null) { return; }
    // if (myPlayer.getPlayerNumbersOfCards() <= 0) { return; }

    let handTypeText = ``
    let numbers: number[] = []
    let cardStrings: { value: number, text: string }[] = []
    let cardString = '';

    if (<HandType>handType === HandType.High) {
      handTypeText = `${cardNameDecoder(cards[0].number)} ${HandTypesText[handType]}`
    } else if (<HandType>handType === HandType.Pair) {
      handTypeText = `${HandTypesText[handType]}, ${cardNameDecoder(cards[0].number, true)}s`
    } else if (<HandType>handType === HandType.TwoPair) {


      cards.forEach(card => {
        if (numbers.indexOf(card.number) === -1) {
          cardString += cardDecoder(null, card.number, true) + 's ';
          numbers.push(card.number)
        }
      });

      handTypeText = `${HandTypesText[handType]}, ${cardString}`
    } else if (<HandType>handType === HandType.Tripple) {
      handTypeText = `${HandTypesText[handType]}, ${cardNameDecoder(cards[0].number)}s`
    } else if (<HandType>handType === HandType.Straight) {
      handTypeText = `${HandTypesText[handType]}, ${cardNameDecoder(cards[0].number)} High`
    } else if (<HandType>handType === HandType.Flush) {
      handTypeText = `${HandTypesText[handType]}, ${cardNameDecoder(cards[0].number)} high, ${cardSuitDecoder(cards[0].suit)}`
    } else if (<HandType>handType === HandType.FullHouse) {


      cards.forEach(card => {
        if (numbers.indexOf(card.number) === -1) {
          cardStrings.push({ value: card.number, text: cardNameDecoder(card.number) })
        }
      });

      cardStrings = cardStrings.sort((a, b) => { return a.value - b.value > 0 ? 1 : -1 })

      const cardsSetOne: number[] = [];
      const cardsSetTwo: number[] = [];

      cardStrings.forEach(card => {
        if (cardsSetOne.length === 0) {
          cardsSetOne.push(card.value)
        } else if (cardsSetOne.indexOf(card.value) !== -1) {
          cardsSetOne.push(card.value)
        } else {
          cardsSetTwo.push(card.value)
        }
      })


      const cardName = ['', 'Ones', 'Twos', 'Threes', 'Fours', 'Fives', 'Sixes', 'Sevens', 'Eights', 'Nines', 'Tens', 'Jacks', 'Queens', 'Kings', 'Aces']
      const firstPair = cardName[cardsSetOne.length > cardsSetTwo.length ? cardsSetOne[0] : cardsSetTwo[0]];
      const lastPair = cardName[cardsSetOne.length > cardsSetTwo.length ? cardsSetTwo[0] : cardsSetOne[0]];



      handTypeText = `${HandTypesText[handType]}, ${firstPair} full of ${lastPair}`
    } else if (<HandType>handType === HandType.FourOfaKind) {
      let cardString = '';
      cards.forEach(card => {
        cardString += cardDecoder(card.suit, card.number, true) + ' ';
      });

      handTypeText = `${HandTypesText[handType]}, ${cardString}`

    } else if (<HandType>handType === HandType.StraightFlush) {
      const royalFlushCheck = [14, 13, 12, 11, 10]
      for (const card of cards) {
        const index = royalFlushCheck.indexOf(card.number)
        if (index > -1) {
          royalFlushCheck.splice(index, 1)
        }
      }

      handTypeText = royalFlushCheck.length === 0 ? 'Royal Flush' : `${HandTypesText[handType]}, ${cardNameDecoder(cards[0].number)} High`



    } else if (<HandType>handType === HandType.Badugi1Card) {
      let cardString = '';
      cards.forEach(card => {
        cardString += cardDecoder(card.suit, card.number, true) + ' ';
      });

      handTypeText = '1-Card' + ', ' + cardString;
    } else if (<HandType>handType === HandType.Badugi2Cards) {
      let cardString = '';
      cards.forEach(card => {
        cardString += cardDecoder(card.suit, card.number, true) + ' ';
      });

      handTypeText = '2-Cards' + ', ' + cardString;
    } else if (<HandType>handType === HandType.Badugi3Cards) {
      let cardString = '';
      cards.forEach(card => {
        cardString += cardDecoder(card.suit, card.number, true) + ' ';
      });

      handTypeText = '3-Cards' + ', ' + cardString;
    } else if (<HandType>handType === HandType.Badugi4Cards) {
      let cardString = '';
      cards.forEach(card => {
        cardString += cardDecoder(card.suit, card.number, true) + ' ';
      });

      handTypeText = 'Badugi' + ', ' + cardString;
    }

    return handTypeText
  }
}
