import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ButtonConfigurationService } from '../../services/button-configuration.service';
import { CommonModule } from '@angular/common';
import { SelectComponent } from '@app/components/select/select.component';
import { SelectOptionComponent } from '@app/components/select-option/select-option.component';
import { RouterModule } from '@angular/router';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Limit } from '@app/models/limit';
import { ButtonComponent } from '@app/components/button/button.component';
import { map, Observable, of, shareReplay, startWith, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import * as GamesActions from '@app/store/features/games/games.actions';

export interface ButtonPreFlopConfiguration {
  btn1: PreFlopOption;
  btn2: PreFlopOption;
  btn3: PreFlopOption;
}
export interface ButtonPostFlopConfiguration {
  btn1: PostFlopOption;
  btn2: PostFlopOption;
  btn3: PostFlopOption;
}
export interface ButtonConfiguration {
  preFlop: ButtonPreFlopConfiguration,
  postFlop: ButtonPostFlopConfiguration
}

export enum ButtonConfigurationLocalStorageKey {
  BTN_CONFIG_POT_LIMIT = 'btnConfigPotLimit',
  BTN_CONFIG_NO_LIMIT = 'btnConfigNoLimit'
}

export enum PreFlopOption {
  NOT_SET = '',
  BB2X = '2BB',
  BB3X = '3BB',
  BB4X = '4BB',
  BB5X = '5BB',
  BB10X = '10BB',
  POT = 'Pot',
  ALL_IN = 'All-In',
}

export enum PostFlopOption {
  NOT_SET = '',
  QUARTER_POT = '1/4 Pot',
  ONE_THIRD_POT = '1/3 Pot',
  HALF_POT = '1/2 Pot',
  TWO_THIRDS_POT = '2/3 Pot',
  THREE_QUARTERS_POT = '3/4 Pot',
  POT = 'Pot',
  ALL_IN = 'All-In'
}

export const DefaultPreFlopConfig = {
  NL: {
    btn1: PreFlopOption.BB2X,
    btn2: PreFlopOption.BB3X,
    btn3: PreFlopOption.BB4X
  },
  PL: {
    btn1: PreFlopOption.BB2X,
    btn2: PreFlopOption.BB3X,
    btn3: PreFlopOption.POT
  }
}

export const DefaultPostFlopConfig = {
  NL: {
    btn1: PostFlopOption.HALF_POT, // 1/2 Pot
    btn2: PostFlopOption.TWO_THIRDS_POT, // 2/3 Pot
    btn3: PostFlopOption.ALL_IN
  },
  PL: {
    btn1: PostFlopOption.HALF_POT, // 1/2 Pot
    btn2: PostFlopOption.TWO_THIRDS_POT, // 2/3 Pot
    btn3: PostFlopOption.ALL_IN
  }
}

@Component({
  selector: 'app-button-configuration',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
  ],
  templateUrl: './button-configuration.component.html',
  styleUrl: './button-configuration.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-button-configuration' }
})
export class ButtonConfigurationComponent implements OnInit {
  private readonly _store = inject(Store);

  private readonly _buttonConfigurationService = inject(ButtonConfigurationService);
  private readonly _fb = inject(FormBuilder);

  Limit = Limit;
  configType: Limit.NL | Limit.PL = Limit.NL

  btnConfigNL = this._fb.nonNullable.group({
    preFlop: this._fb.nonNullable.group({
      btn1: [DefaultPreFlopConfig.NL.btn1],
      btn2: [DefaultPreFlopConfig.NL.btn2],
      btn3: [DefaultPreFlopConfig.NL.btn3],
    }),
    postFlop: this._fb.nonNullable.group({
      btn1: [DefaultPostFlopConfig.NL.btn1],
      btn2: [DefaultPostFlopConfig.NL.btn2],
      btn3: [DefaultPostFlopConfig.NL.btn3],
    })
  })

  btnConfigPL = this._fb.nonNullable.group({
    preFlop: this._fb.nonNullable.group({
      btn1: [DefaultPreFlopConfig.PL.btn1],
      btn2: [DefaultPreFlopConfig.PL.btn2],
      btn3: [DefaultPreFlopConfig.PL.btn3],
    }),
    postFlop: this._fb.nonNullable.group({
      btn1: [DefaultPostFlopConfig.PL.btn1],
      btn2: [DefaultPostFlopConfig.PL.btn2],
      btn3: [DefaultPostFlopConfig.PL.btn3]
    })
  })

  // Pre-Flop
  preFlopBtnOptions = [
    { value: PreFlopOption.NOT_SET, label: '/' },
    { value: PreFlopOption.BB2X, label: '2BB' },
    { value: PreFlopOption.BB3X, label: '3BB' },
    { value: PreFlopOption.BB4X, label: '4BB' },
    { value: PreFlopOption.BB5X, label: '5BB' },
    { value: PreFlopOption.BB10X, label: '10BB' },
    { value: PreFlopOption.POT, label: 'Pot' },
    { value: PreFlopOption.ALL_IN, label: 'All-In' },
  ]
  btn1ConfigNLPreFlopBtnOptions$: Observable<{ value: string, label: string }[]>;
  btn2ConfigNLPreFlopBtnOptions$: Observable<{ value: string, label: string }[]>;
  btn3ConfigNLPreFlopBtnOptions$: Observable<{ value: string, label: string }[]>;

  btn1ConfigPLPreFlopBtnOptions$: Observable<{ value: string, label: string }[]>;
  btn2ConfigPLPreFlopBtnOptions$: Observable<{ value: string, label: string }[]>;
  btn3ConfigPLPreFlopBtnOptions$: Observable<{ value: string, label: string }[]>;

  // Post-Flop
  postFlopBtnOptions = [
    { value: PostFlopOption.NOT_SET, label: '/' },
    { value: PostFlopOption.QUARTER_POT, label: '1/4 Pot' },
    { value: PostFlopOption.ONE_THIRD_POT, label: '1/3 Pot' },
    { value: PostFlopOption.HALF_POT, label: '1/2 Pot' },
    { value: PostFlopOption.TWO_THIRDS_POT, label: '2/3 Pot' },
    { value: PostFlopOption.THREE_QUARTERS_POT, label: '3/4 Pot' },
    { value: PostFlopOption.POT, label: 'Pot' },
    { value: PostFlopOption.ALL_IN, label: 'All-In' },
  ]

  btn1ConfigNLPostFlopBtnOptions$: Observable<{ value: string, label: string }[]>;
  btn2ConfigNLPostFlopBtnOptions$: Observable<{ value: string, label: string }[]>;
  btn3ConfigNLPostFlopBtnOptions$: Observable<{ value: string, label: string }[]>;

  btn1ConfigPLPostFlopBtnOptions$: Observable<{ value: string, label: string }[]>;
  btn2ConfigPLPostFlopBtnOptions$: Observable<{ value: string, label: string }[]>;
  btn3ConfigPLPostFlopBtnOptions$: Observable<{ value: string, label: string }[]>;


  constructor() {
    if (typeof Storage !== "undefined") {
      let config = localStorage.getItem(ButtonConfigurationLocalStorageKey.BTN_CONFIG_POT_LIMIT)
      if (config) {
        this.btnConfigPL.patchValue(JSON.parse(config))
      }
      config = localStorage.getItem(ButtonConfigurationLocalStorageKey.BTN_CONFIG_NO_LIMIT)
      if (config) {
        this.btnConfigNL.patchValue(JSON.parse(config))
      }

    }


    const preFlopNL$ = this.btnConfigNL.get('preFlop')!.valueChanges!.pipe(
      startWith(this.btnConfigNL.get('preFlop')?.value),
      shareReplay({ bufferSize: 1, refCount: true })
    )

    this.btn1ConfigNLPreFlopBtnOptions$ = preFlopNL$.pipe(
      map(value => {
        return [...this.preFlopBtnOptions].filter(option => (option.value !== value?.btn2 && option.value !== value?.btn3) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )
    this.btn2ConfigNLPreFlopBtnOptions$ = preFlopNL$.pipe(
      map(value => {
        return [...this.preFlopBtnOptions].filter(option => (option.value !== value?.btn1 && option.value !== value?.btn3) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )
    this.btn3ConfigNLPreFlopBtnOptions$ = preFlopNL$.pipe(
      map(value => {
        return [...this.preFlopBtnOptions].filter(option => (option.value !== value?.btn1 && option.value !== value?.btn2) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )

    const preFlopPL$ = this.btnConfigPL.get('preFlop')!.valueChanges!.pipe(
      startWith(this.btnConfigPL.get('preFlop')?.value),
      shareReplay({ bufferSize: 1, refCount: true })
    )

    this.btn1ConfigPLPreFlopBtnOptions$ = preFlopPL$.pipe(
      map(value => {
        return [...this.preFlopBtnOptions].filter(option => (option.value !== value?.btn2 && option.value !== value?.btn3) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )
    this.btn2ConfigPLPreFlopBtnOptions$ = preFlopPL$.pipe(
      map(value => {
        return [...this.preFlopBtnOptions].filter(option => (option.value !== value?.btn1 && option.value !== value?.btn3) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )
    this.btn3ConfigPLPreFlopBtnOptions$ = preFlopPL$.pipe(
      map(value => {
        return [...this.preFlopBtnOptions].filter(option => (option.value !== value?.btn1 && option.value !== value?.btn2) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )



    // Post-Flop
    const postFlopNL$ = this.btnConfigNL.get('postFlop')!.valueChanges!.pipe(
      startWith(this.btnConfigNL.get('postFlop')?.value),
      shareReplay({ bufferSize: 1, refCount: true })
    )

    this.btn1ConfigNLPostFlopBtnOptions$ = postFlopNL$.pipe(
      map(value => {
        return [...this.postFlopBtnOptions].filter(option => (option.value !== value?.btn2 && option.value !== value?.btn3) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )
    this.btn2ConfigNLPostFlopBtnOptions$ = postFlopNL$.pipe(
      map(value => {
        return [...this.postFlopBtnOptions].filter(option => (option.value !== value?.btn1 && option.value !== value?.btn3) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )
    this.btn3ConfigNLPostFlopBtnOptions$ = postFlopNL$.pipe(
      map(value => {
        return [...this.postFlopBtnOptions].filter(option => (option.value !== value?.btn1 && option.value !== value?.btn2) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )

    const postFlopPL$ = this.btnConfigPL.get('postFlop')!.valueChanges!.pipe(
      startWith(this.btnConfigPL.get('postFlop')?.value),
      shareReplay({ bufferSize: 1, refCount: true })
    )

    this.btn1ConfigPLPostFlopBtnOptions$ = postFlopPL$.pipe(
      map(value => {
        return [...this.postFlopBtnOptions].filter(option => (option.value !== value?.btn2 && option.value !== value?.btn3) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )
    this.btn2ConfigPLPostFlopBtnOptions$ = postFlopPL$.pipe(
      map(value => {
        return [...this.postFlopBtnOptions].filter(option => (option.value !== value?.btn1 && option.value !== value?.btn3) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )
    this.btn3ConfigPLPostFlopBtnOptions$ = postFlopPL$.pipe(
      map(value => {
        return [...this.postFlopBtnOptions].filter(option => (option.value !== value?.btn1 && option.value !== value?.btn2) || option.value === '')
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )


  }

  changeConfigType() {
    this.configType = this.configType === Limit.NL ? Limit.PL : Limit.NL;
  }

  save() {
    this._store.dispatch(GamesActions.setButtonConfiguration({ configType: this.configType, value: (this.configType === Limit.NL ? this.btnConfigNL : this.btnConfigPL).value as ButtonConfiguration }))
  }

  reset() {
    if (this.configType === Limit.NL) {
      this.btnConfigNL.reset()
    } else if (this.configType === Limit.PL) {
      this.btnConfigPL.reset()
    }
  }

  ngOnInit(): void {


  }
}
