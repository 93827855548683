
import { Injectable } from '@angular/core';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';

import * as LobbyActions from '../lobby/lobby.actions';
import * as TournamentSummariesActions from '../tournament-summaries/tournament-summaries.actions';
import { ServerMessageType } from '@app/enums/server-message-type';

import { Store, select } from '@ngrx/store';

import * as GamesActions from './games.actions';
import * as GamesSelectors from './games.selector';
import * as UserActions from '@app/store/features/user/user.actions';

import * as TableSummariesSelectors from '@app/store/features/table-summaries/table-summaries.selectors';
import * as TournamentSummariesSelectors from '@app/store/features/tournament-summaries/tournament-summaries.selector';
import * as SitAndGoSummarySelectors from '@app/store/features/sit-n-go-summaries/sit-n-go-summaries.selector';
import * as SpinAndGoSummarySelectors from '@app/store/features/spin-n-go-summaries/spin-n-go-summaries.selector';
import * as TournamentsSelectors from '@app/store/features/tournaments/tournaments.selector';


import { VariantType2 } from '@app/enums/variant-type-2';
import { WsService } from '@app/services/ws.service';
import { ServerMsgBidAllIn, ServerMsgBidAnte, ServerMsgBidBet, ServerMsgBidBigBlind, ServerMsgBidCall, ServerMsgBidCheck, ServerMsgBidRaise, ServerMsgBidSmallBlind, ServerMsgBidStraddle, ServerMsgChargedRabbitHunting, ServerMsgCommunityCards, ServerMsgDealer, ServerMsgEndOfHand, ServerMsgFold, ServerMsgFreeRabbitHunting, ServerMsgGameChat, ServerMsgGameStatusChanged, ServerMsgHandStart, ServerMsgHideRabbitHuntingButton, ServerMsgInfo, ServerMsgMoveToPot, ServerMsgOfferRabbitHunting, ServerMsgPlayerBuyChips, ServerMsgPlayerLeave, ServerMsgPlayerPlayStatistic, ServerMsgPlayerSetRunItTwice, ServerMsgPlayerStatus, ServerMsgPlayerTakeSeat, ServerMsgPlayerTurnCardsReturnChange, ServerMsgPlayerTurnChange, ServerMsgPotResult, ServerMsgPotSplitted, ServerMsgRabbitHuntingPrice, ServerMsgReturnBackMoney, ServerMsgSendCardHidden, ServerMsgSkipNextHand, ServerMsgStartedRabbitHunting, ServerMsgTimeBankStatus, ServerMsgTimeBankUsing, ServerMsgWinnerByFold, ServerMsgWinnerByStrongestHand, ServerMsgWinnerSplit } from '@app/models/server-msg';
import { LiveLobbyRing } from '@app/models/live-lobby-ring';
import { TableSummary } from '@app/models/table-summary';
import { LiveLobbyRingSelected } from '@app/models/live-lobby-ring-selected';
import { TableInfo } from '@app/models/table-info';
import { MixTableDetails, MixTableDetailsDTO } from '@app/models/mix-table-details';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest, from, of } from 'rxjs';
import { Assets, Application, Container, Sprite, DisplayObject } from 'pixi.js';
import * as GameHorizontal from '@app/features/game/consts/game-horizontal.const';
import { TournamentSummary } from '@app/models/tournament-summary';
import { SitNGoSummary } from '@app/models/sit-n-go-summary';
import { BetSlider, ChargedRabbitHunting, Game, GameActionControls, GameBetControls, GameChat, GameEvent, GameHistoryEvent, GamePlayer, GamePreBetControls, GameStatus, MaxPlayers, MemberPreferencesRunItTwice, PreBetType, RabbitHuntingPrice, ReplayAction } from './games.reducer';
import { seats } from '@app/features/lobby/consts/lobby-filters.const';
import * as CurrenciesSelectors from '@app/store/features/currencies/currencies.selectors';

import * as ConfigSelectors from '@app/store/features/config/config.selectors';

export enum CardSuit { Hearts, Diamods, Clubs, Spades }
export enum CardNumber { T = 10, Jack, Queen, King, Ace }
export const CardName: string[] = [
    '', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'Jack', 'Queen', 'King', 'Ace'
];
import * as UserSelectors from '@app/store/features/user/user.selectors';
import { el, id } from 'date-fns/locale';
import { PlayerStatus } from '@app/features/game/enums/player-status.enum';
import { cloneDeep, groupBy } from 'lodash';
import { GameCurrencyPipe } from '@app/pipes/game-currency.pipe';
import { Player } from '@app/features/game/elements/player';
import { CardData } from '@app/features/game/models/card-data';
import { Dialog } from '@angular/cdk/dialog';
import { LobbyDialogLegendsComponent } from '@app/features/lobby/presenters/lobby-dialog-legends/lobby-dialog-legends.component';
import { GameDialogBuyChipsComponent } from '@app/features/game/presenters/game-dialog-buy-chips/game-dialog-buy-chips.component';
import { GameService } from '@app/features/game/services/game.service';

import * as PlayerBalanceSelectors from '@app/store/features/player-balance/player-balance.selectors';
import { Limit } from '@app/models/limit';
import { GenericDialogComponent } from '@app/components/generic-dialog/generic-dialog.component';
import { environment } from '@environments/environment';
import { GameDialogPlayerInfoComponent } from '@app/features/game/presenters/game-dialog-player-info/game-dialog-player-info.component';
import { HandReplayData } from '@app/models/hand-replay';
import { Hand, HandHistory, HandHistoryData } from '@app/models/hand-history';
import { ErrorCode } from '@app/enums/errors';
import { PlayerCallTimeData } from '@app/models/player-call-time';
import * as TablesSelectors from '../../../store/features/tables/tables.selector';
import { GameCallTimeDialogComponent } from '@app/features/game/presenters/game-call-time-dialog/game-call-time-dialog.component';


@Injectable()
export class GamesEffects {

    constructor(
        private _ws: WsService,
        private readonly _store: Store,
        private readonly _gameService: GameService,
        private _actions$: Actions,
        private _router: Router,
        private _dialog: Dialog,

    ) { }


    /**
    * NOT IMPLEMENTED:
    * GameStart
    * GameEnd
    * HandStartWithPlayers
    * HandStartPlayerBalances
    */

    handReplayDataError$ = createEffect(() => this._ws.getDataResponse<HandReplayData>(ServerResponse.HandReplayData)
        .pipe(
            filter(handReplayData => !!handReplayData.error),
            tap(() => this._router.navigate(['lobby'])),

            tap(handReplayData => {

                let text = '';

                switch (handReplayData.error) {
                    case ErrorCode.HandNumberAccessDenied:
                        text = 'Access denied to hand replay';
                        break;

                    case ErrorCode.HandNumberNotEnded:
                        text = 'Hand not ended yet';
                        break;

                    case ErrorCode.HandNumberNotDefined:
                        text = 'Hand number not defined';
                        break;
                    case ErrorCode.HandNumberNotFound:
                        text = 'Hand number not found';
                        break;

                    default:
                        text = 'Hand replay error';
                        break;
                }
                const dialog = this._dialog.open(GenericDialogComponent, { width: '300px' })
                dialog.componentInstance!.title = 'Hand Replay';
                dialog.componentInstance!.text = text;
                dialog.componentInstance!.dissmissBtn = 'Ok'
            })
        ), { dispatch: false }
    )

    handReplayData$ = createEffect(() => this._ws.getDataResponse<HandReplayData>(ServerResponse.HandReplayData)
        .pipe(
            filter(handReplayData => !handReplayData.error),
            map(handReplayData => {

                const handStart = handReplayData.events.find(data => data.msgTypeLog === 'HandStart');
                const bidSmallBlind = handReplayData.events.find(data => data.msgTypeLog === 'BidSmallBlind');
                const tourInfo = handReplayData.events.find(data => data.msgTypeLog === 'InfoTournament');
                const infoCurrency = handReplayData.events.find(data => data.msgTypeLog === 'InfoCurrency');
                const infoTable = handReplayData.events.find(data => data.msgTypeLog === 'InfoTable');
                const handNumber = handReplayData.handNumber;

                let currencyId = infoCurrency?.value;
                let maxNumOfPlayers: MaxPlayers = 10;
                let tableName = `Replay hand ${handReplayData.handNumber}`;
                let tableId = -1 * handReplayData.handNumber;
                let tableVariant;
                let variant;
                let isReplay = true;
                if (handStart?.values) {
                    maxNumOfPlayers = handStart?.values[1] as MaxPlayers;
                }

                // check if replay is from tournament table
                // and if so set currenty to tournament
                let fakeTournamentId;
                if (tourInfo) {
                    fakeTournamentId = -1 * handReplayData.handNumber
                    tableName = tourInfo.text!
                    tableVariant = VariantType2[tourInfo.value3!];
                    variant = tourInfo.value3
                } else {
                    tableVariant = VariantType2[infoTable!.value3!];
                    variant = infoTable!.value3
                }

                /**
                 * 
                 * Messages to ignore
                   SendCardHidden: if the IdPlayer is the Id of the player currently logged. 
                   Another message with your card visible will also be sent after.
                   PlayerBuyChips
                   AskPlayerRebuy
                   PlayerTakeSeat we don't care about non-playing in a replay
                   PlayerStatus
                   📚 Any messages that have an IdTable different that the one set in HandStart.
                   This can occur in Tournament when players move to another table.
                 */

                const replayEvents = handReplayData.events
                const gameReplayEvents = replayEvents
                    .slice(replayEvents.findIndex(event => event.msgTypeLog === 'Dealer'))
                    .filter(event => event.idTable === handStart?.idTable)
                    //.filter(event => event.msgType !== ServerMessageType.SendCardHidden)
                    .filter(event => event.msgType !== ServerMessageType.PlayerBuyChips)
                    .filter(event => event.msgType !== ServerMessageType.AskPlayerRebuy)
                    .filter(event => event.msgType !== ServerMessageType.PlayerTakeSeat)
                    .filter(event => event.msgType !== ServerMessageType.PlayerStatus)


                    .map(event => {
                        event.idTable = handNumber * -1;
                        return {
                            response: "ServerMsg",
                            serverMsg: [event]
                        }
                    })


                const infoPlayers = replayEvents
                    .filter(event => event.msgTypeLog === 'InfoPlayer')
                    .reduce((acc, infoPlayer) => {
                        const playerData = { ...infoPlayer.playerData, cards: [] } as unknown as GamePlayer;
                        if (!acc.some(player => player.id === playerData.id)) {
                            acc.push(playerData);
                        }
                        return acc;
                    }, [] as GamePlayer[]);


                return {
                    tableId,
                    tableName,
                    currencyId,
                    replayEvents,
                    gameReplayEvents,
                    handNumber,
                    maxNumOfPlayers,
                    variant,
                    infoPlayers
                }

            }),
            switchMap(handReplayData => {
                return combineLatest([
                    of(handReplayData),
                    this._store.pipe(
                        select(CurrenciesSelectors.selectEntityById(handReplayData.currencyId!)),
                        filter(currency => !!currency),
                        map((currency) => currency!),
                        take(1))
                ])
            }),
            map(([handReplayData, currency]) => {
                const seats: (GamePlayer | null)[] = []
                for (let i = 0; i < handReplayData.maxNumOfPlayers; i++) {
                    const player = handReplayData.infoPlayers[i];
                    seats[i] = player ?? null
                }
                const game: Game = {
                    idTable: handReplayData.tableId,
                    tableName: handReplayData.tableName,
                    currency,
                    currencyId: handReplayData.currencyId!,
                    isFast: false,
                    handNumber: handReplayData.handNumber,
                    currentHandNumber: handReplayData.handNumber,
                    maxNumOfPlayers: handReplayData.maxNumOfPlayers,
                    seats,
                    variant: handReplayData.variant!,
                    gameHistoryEvents: [],
                    gameActionControls: {
                        checkSeatOutNextHand: false,
                        showImBackButton: false,
                        showBuyRebuyChipsButton: false,
                        showTipButton: false,
                        showLeaveTableButton: false,
                        showReplayButtons: true,
                        checkRunItTwice: MemberPreferencesRunItTwice.OFF,
                        isStraddle: false,
                        disableChat: false
                    },
                    gamePreBetControls: {
                        show: false,
                        selected: undefined,
                        values: {
                            [PreBetType.call]: {
                                type: PreBetType.call,
                                text: 'Call',
                                visible: false,
                                checked: false,
                                cssClass: 'call'
                            },
                            [PreBetType.check]: {
                                type: PreBetType.check,
                                text: 'Check',
                                visible: false,
                                checked: false,
                                cssClass: 'check'
                            },
                            [PreBetType.checkFold]: {
                                type: PreBetType.checkFold,
                                text: 'Check/Fold',
                                visible: false,
                                checked: false,
                                cssClass: 'check-fold'
                            },
                            [PreBetType.callAny]: {
                                type: PreBetType.callAny,
                                text: 'Call Any',
                                visible: false,
                                checked: false,
                                cssClass: 'call-any'
                            },
                        }
                    },
                    gameBetControls: {
                        showBetButtons: false,

                        showOfferRabbitHunting: false,

                        showReplaceCardsButtons: false,

                        showPostBigBlind: false,
                        showFoldButton: false,
                        showCallButton: false,
                        showCheckButton: false,
                        showRaiseButton: false,
                        justForShowCallValue: 0,
                        justForShowCallValueBB: '0 BB',

                        raiseButtonText: 'Raise',
                        raiseButtonValue: '',
                        raiseButtonValueBigBlind: '',

                        betSlider: { min: 0, max: 10, step: 1, value: 0 } as BetSlider,
                        showSlider: false,

                        showBB2Button: false,
                        showBB3Button: false,
                        showBB4Button: false,
                        showPotButton: false,
                        showHalfPotButton: false,
                        showQuarterPotButton: false,
                        showThreeQuartersPot: false,
                        showMaxButton: true,
                        callValue: 0,
                        potValue: 0,
                        minimumBet: 0,
                        isBetPreload: false,
                        callButtonText: 'Call',
                        isBringIn: 0,
                        checkButtonText: 'Check',
                        straddle: false,
                        amount7StudBet: 0,

                    },
                    gamePrePlayHandInfo: {
                        previousHighestBet: 0,
                    },
                    limit: Limit.UNKNOWN,
                    handType: '',
                    tableCards: [],

                    potTotalValue: 0,
                    mainPotValue: 0,
                    mainPot2Value: 0,

                    hasSexyDealer: false,
                    isReplay: true,
                    isTournament: false,
                    pauseOverlay: {
                        show: false,
                        timer: 0
                    },
                    playerTurnId: -1,

                    cardsInHand: [],
                    onMove: false,
                    folded: false,

                    chat: [],
                    playersStatistics: {},
                    gameReplayAction: ReplayAction.Play,
                    replayEvents: handReplayData.replayEvents,
                    gameReplayEvents: handReplayData.gameReplayEvents,
                    gameReplayEventsIndex: 0,
                    chargedRabbitHunting: []
                }

                return GamesActions.upsertOne({ game })
            })

        ));

    updateTableInfo$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgInfo>(ServerMessageType.Info)
            .pipe(
                switchMap(serverMsgInfo => combineLatest([
                    of(serverMsgInfo),
                    this.getTableSummary(serverMsgInfo.idTable),
                    this._store.pipe(
                        select(TableSummariesSelectors.selectEntityById(serverMsgInfo.idTable)),
                        filter(tableSum => !!tableSum),
                        map((tableSum) => tableSum!.currency),
                        switchMap((currency) => this._store.pipe(select(CurrenciesSelectors.selectEntityById(currency)))),
                        filter(currency => !!currency),
                        map((currency) => currency!),
                        take(1)
                    ),
                    this.getUserProfile(),

                ])),
                map(([serverMsgInfo, tableSum, currency, userProfile]) => {

                    const { players, publicCards, handNumber, currentPlayerTurn, pots, idTournament } = serverMsgInfo.info;
                    const tableName = tableSum.name;
                    // ## addSeatsAndPlayers
                    const currentHandNumber = handNumber ?? 0;
                    const maxNumOfPlayers = this.getMaxPlayers({ tableSum });
                    const seats: (GamePlayer | null)[] = []
                    const variant = this.getVariant({ tableSum });
                    const isFast = this.getIsFast({ tableSum });
                    const limit = this._gameService.getLimit({ tableSum });

                    const isReplay = false; // ⏺ 
                    const isTournament = false; // ⏺ 




                    const gameBetControls = {
                        showBetButtons: environment.gameDevMode ?? false,

                        showOfferRabbitHunting: false,

                        showReplaceCardsButtons: false,

                        showPostBigBlind: environment.gameDevMode ?? false,
                        showFoldButton: false,
                        showCallButton: false,
                        showCheckButton: false,
                        showRaiseButton: false,
                        justForShowCallValue: 0,
                        justForShowCallValueBB: '0 BB',

                        raiseButtonText: 'Raise',
                        raiseButtonValue: '',
                        raiseButtonValueBigBlind: '',

                        betSlider: { min: 0, max: 10, step: 1, value: 0 } as BetSlider,
                        showSlider: environment.gameDevMode ?? false,

                        showBB2Button: environment.gameDevMode ?? false,
                        showBB3Button: environment.gameDevMode ?? false,
                        showBB4Button: environment.gameDevMode ?? false,
                        showPotButton: false,
                        showHalfPotButton: false,
                        showQuarterPotButton: false,
                        showThreeQuartersPot: false,
                        showMaxButton: true,
                        callValue: 0,
                        potValue: 0,
                        minimumBet: 0,
                        isBetPreload: false,
                        callButtonText: 'Call',
                        isBringIn: 0,
                        checkButtonText: 'Check',
                        straddle: false,
                        amount7StudBet: 0,

                        rabbitHuntingPrice: undefined
                    }


                    const gamePreBetControls: GamePreBetControls = {
                        show: false,
                        selected: undefined,
                        values: {
                            [PreBetType.call]: {
                                type: PreBetType.call,
                                text: 'Call',
                                visible: false,
                                checked: false,
                                cssClass: 'call'
                            },
                            [PreBetType.check]: {
                                type: PreBetType.check,
                                text: 'Check',
                                visible: false,
                                checked: false,
                                cssClass: 'check'
                            },
                            [PreBetType.checkFold]: {
                                type: PreBetType.checkFold,
                                text: 'Check/Fold',
                                visible: false,
                                checked: false,
                                cssClass: 'check-fold'
                            },
                            [PreBetType.callAny]: {
                                type: PreBetType.callAny,
                                text: 'Call Any',
                                visible: false,
                                checked: false,
                                cssClass: 'call-any'
                            },
                        }
                    }


                    const gameActionControls = {
                        checkSeatOutNextHand: false,
                        showImBackButton: false,
                        showBuyRebuyChipsButton: false,
                        showTipButton: false,
                        showLeaveTableButton: false,
                        showReplayButtons: false,
                        checkRunItTwice: userProfile.preferences.runItTwice,
                        isStraddle: false,
                        disableChat: false
                    }

                    for (let i = 0; i < maxNumOfPlayers; i++) {
                        const player = players[i];

                        if (player) {
                            seats[i] = player as GamePlayer;
                            seats[i]!.money = player.money ?? 0;
                            if (player.id === userProfile.id) {
                                const tournamentSummary = undefined;// ⏺
                                const sitNGoSummary = undefined;// ⏺
                                this.checkBuyChipsVisibility(gameActionControls, isReplay, isTournament, tableSum, tournamentSummary, sitNGoSummary, seats[i]);

                                // ⏺ Check the code below is not part of the original code addSeatsAndPlayers()

                                gameBetControls.showReplaceCardsButtons = false

                                if (player.status === PlayerStatus.Sitout) {
                                    gameActionControls.showImBackButton = true;
                                    gameActionControls.checkSeatOutNextHand = true;
                                    gamePreBetControls.show = false;
                                    // disable all bitting buttons
                                    gameBetControls.showBetButtons = false;
                                } else if (player.status === PlayerStatus.SitoutNextHand) {
                                    gameActionControls.showImBackButton = false;
                                    gameActionControls.checkSeatOutNextHand = true;
                                    gamePreBetControls.show = false;
                                } else if (player.status === PlayerStatus.Ready) { // Ready
                                    gameActionControls.showImBackButton = false;
                                    gameActionControls.checkSeatOutNextHand = false;
                                    // ⏺ enable all betting buttons if it's my turn
                                    // if (this.playerTurnId === this.memberProfile.Id) {
                                    //     this.betControls.showBetButtons = true;
                                    // }
                                } else if (player.status === PlayerStatus.LeaveSeat) { // LeaveSeat

                                    gameActionControls.showImBackButton = false;
                                }
                            }
                        } else {
                            seats[i] = null
                        }
                    }
                    const idTable = serverMsgInfo.idTable;

                    const game: Game = {
                        idTable,
                        tableName,
                        currency,
                        currencyId: tableSum.currency,
                        isFast,

                        currentHandNumber,
                        maxNumOfPlayers,
                        seats,
                        variant,
                        gameHistoryEvents: [],
                        gameActionControls,
                        gamePreBetControls,
                        gameBetControls,
                        gamePrePlayHandInfo: {
                            previousHighestBet: 0,
                        },
                        limit,
                        handType: '',
                        tableCards: [],

                        potTotalValue: 0,
                        mainPotValue: 0,
                        mainPot2Value: 0,

                        hasSexyDealer: false,
                        isReplay: false,
                        isTournament: false,
                        pauseOverlay: {
                            show: false,
                            timer: 0
                        },
                        playerTurnId: -1,

                        cardsInHand: [],
                        onMove: false,
                        folded: false,

                        chat: [],
                        playersStatistics: {},
                        replayEvents: [],
                        chargedRabbitHunting: []

                    }

                    // Call Time
                    if (tableSum.callTimeConfiguration?.isCallTime) {
                        this._gameService.getCallTimeStatus(idTable)
                    }

                    return GamesActions.upsertOne({ game })
                })
            )
    );





    onOpenGame$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onOpenGame),
                tap(({ idTable }) => this._router.navigate(['game', idTable])),
                map(({ idTable }) => GamesActions.setActiveTable({ idTable }))
            ))


    onOpenHandReplay$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onOpenHandReplay),
                tap(({ handId }) => this._gameService.getHandReplayData(handId)),
                tap(({ handId }) => this._router.navigate(['game', -1 * handId])),

            ), { dispatch: false })



    getHandReplayGameEventData(game: Game) {
        let gameReplayEventsIndex = game.gameReplayEventsIndex! + 1;
        let gameEvent: any = cloneDeep(game.gameReplayEvents![gameReplayEventsIndex]);
        if (!gameEvent) {
            gameReplayEventsIndex = 0;
            gameEvent = cloneDeep(game.gameReplayEvents![gameReplayEventsIndex]);

        }
        return { gameEvent, gameReplayEventsIndex }
    }

    onHandReplayNextEvent$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onHandReplayActionNext),
                switchMap(({ idTable }) => this._store.pipe(
                    select(GamesSelectors.selectEntityById(idTable)),
                    filter(el => !!el),
                    map(el => el!),
                    take(1)
                )),
                map((game) => {

                    const gameEventData = this.getHandReplayGameEventData(game)
                    this._ws.stream.next(gameEventData.gameEvent)

                    if (gameEventData.gameReplayEventsIndex === 0) {
                        // Cards in hand
                        const cardsInHand = [] as CardData[]

                        // Clear Navigation Header
                        let folded = false;
                        let onMove = false

                        const playerTurnId = null;
                        const tableCards = [] as CardData[];

                        return GamesActions.updateOne({
                            idTable: game.idTable,
                            game: {
                                gameReplayEventsIndex: gameEventData.gameReplayEventsIndex,
                                gameReplayAction: ReplayAction.None,

                                cardsInHand,
                                onMove,
                                folded,
                                playerTurnId,
                                tableCards
                            }
                        })
                    }

                    return GamesActions.updateOne({
                        idTable: game.idTable,
                        game: {
                            gameReplayEventsIndex: gameEventData.gameReplayEventsIndex,
                            gameReplayAction: ReplayAction.None
                        }
                    })
                })
            ))

    onHandReplayPlay$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onHandReplayActionPlay),
                switchMap(({ idTable }) => this._store.pipe(
                    select(GamesSelectors.selectEntityById(idTable)),
                    filter(el => !!el),
                    map(el => el!),
                    take(1)
                )),
                map((game) => {
                    const gameEventData = this.getHandReplayGameEventData(game)
                    this._ws.stream.next(gameEventData.gameEvent)

                    if (gameEventData.gameReplayEventsIndex === 0) {
                        // Cards in hand
                        const cardsInHand = [] as CardData[]

                        // Clear Navigation Header
                        let folded = false;
                        let onMove = false

                        const playerTurnId = null;
                        const tableCards = [] as CardData[];

                        return GamesActions.updateOne({
                            idTable: game.idTable,
                            game: {
                                gameReplayEventsIndex: gameEventData.gameReplayEventsIndex,
                                gameReplayAction: ReplayAction.None,

                                cardsInHand,
                                onMove,
                                folded,
                                playerTurnId,
                                tableCards
                            }
                        })
                    }
                    return GamesActions.updateOne({
                        idTable: game.idTable,
                        game: {
                            gameReplayEventsIndex: gameEventData.gameReplayEventsIndex,
                            gameReplayAction: ReplayAction.Play
                        }
                    })
                })
            ))

    onHandReplayPause$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onHandReplayActionPause),
                switchMap(({ idTable }) => this._store.pipe(
                    select(GamesSelectors.selectEntityById(idTable)),
                    filter(el => !!el),
                    map(el => el!),
                    take(1)
                )),
                map((game) => {
                    return GamesActions.updateOne({
                        idTable: game.idTable,
                        game: {
                            gameReplayAction: ReplayAction.Pause
                        }
                    })
                })
            ))

    onHandReplayRestart$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onHandReplayActionRestart),
                switchMap(({ idTable }) => this._store.pipe(
                    select(GamesSelectors.selectEntityById(idTable)),
                    filter(el => !!el),
                    map(el => el!),
                    take(1)
                )),
                map((game) => {
                    const gameEventData = this.getHandReplayGameEventData(game)
                    this._ws.stream.next(gameEventData.gameEvent)

                    // Cards in hand
                    const cardsInHand = [] as CardData[]

                    // Clear Navigation Header
                    let folded = false;
                    let onMove = false

                    const playerTurnId = null;
                    const tableCards = [] as CardData[];

                    return GamesActions.updateOne({
                        idTable: game.idTable,
                        game: {
                            gameReplayEventsIndex: 0,
                            gameReplayAction: ReplayAction.Restart,
                            action: {
                                type: 'HandReplayRestart'
                            },

                            cardsInHand,
                            onMove,
                            folded,
                            playerTurnId,
                            tableCards,
                        }
                    })
                })
            ))

    getHandHistory$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.getHandHistory),
                tap(({ handId }) => this._gameService.getHandHistory(handId)),
            ), { dispatch: false })

    updateHandHistory$ = createEffect(() => this._ws.getDataResponse<HandHistoryData>(ServerResponse.HandHistory)
        .pipe(
            map(handHistoryData => {
                let handHistory = cloneDeep(handHistoryData);
                handHistory.hands = handHistory.hands.map(handData => {
                    const hand: Hand = { ...handData, tableVariant: VariantType2[handData.variant] }
                    hand.cards = hand.cards.map(card => ({ ...card, name: this.cardDecoder(card.suit, card.number) } as unknown as CardData))
                    return hand
                })

                return GamesActions.updateHandHistory(handHistory as HandHistory)
            })
        ))


    // When user navigate to Lobby, Tournament View, etc..
    onCloseGameView$ = createEffect(
        () =>
            this._router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                filter(router => !(router as NavigationEnd)?.url.includes('/game')),
                map(() => GamesActions.unsetActiveTable())
            )
    )









    // +++++ Game Actions from Server +++++

    sendCardHidden$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgSendCardHidden>(ServerMessageType.SendCardHidden)
            .pipe(
                switchMap((serverMsgSendCardHidden) => combineLatest([
                    of(serverMsgSendCardHidden),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgSendCardHidden.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgSendCardHidden, gameData, userProfile]) => {
                    let { idTable, idPlayer, cards, allCards, date } = serverMsgSendCardHidden;

                    const cardsData = cards ? cards.map(card => ({ ...card, name: this.cardDecoder(card.suit, card.number) } as unknown as CardData)) : []
                    const allCardsData = allCards ? allCards.map(card => ({ ...card, name: this.cardDecoder(card.suit, card.number) } as unknown as CardData)) : []

                    const action = {
                        type: 'SendCardHidden',
                        data: {
                            idPlayer,
                            cards: cardsData,
                            allCards: allCardsData,
                            date: new Date(date),
                            seatPosition: serverMsgSendCardHidden.value
                        }
                    }

                    // Cards In Hand
                    let cardsInHand = cloneDeep(gameData.cardsInHand);

                    if (idPlayer === userProfile.id) {
                        cardsInHand = [...cardsInHand, ...cardsData]
                    }

                    // Seats Update Cards

                    const seats = cloneDeep(gameData.seats);


                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.cards = [...seat.cards, ...cardsData]
                            }
                        })

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    if (idPlayer === userProfile.id) {
                        gameBetControls.showPostBigBlind = false;
                    }

                    return GamesActions.updateOne({ idTable, game: { action, cardsInHand, gameBetControls, seats } })
                })
            )
    );


    gameStatusChanged$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgGameStatusChanged>(ServerMessageType.GameStatusChanged)
            .pipe(
                switchMap((serverMsgGameStatusChanged) => combineLatest([
                    of(serverMsgGameStatusChanged),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgGameStatusChanged.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgGameStatusChanged, gameData, userProfile]) => {
                    let { idTable, cards, allCards, date, value } = serverMsgGameStatusChanged;


                    const cardsData = cards ? cards.map(card => ({ ...card, name: this.cardDecoder(card.suit, card.number) } as unknown as CardData)) : []
                    const allCardsData = allCards ? allCards.map(card => ({ ...card, name: this.cardDecoder(card.suit, card.number) } as unknown as CardData)) : []


                    const action = {
                        type: 'GameStatusChanged',
                        data: {
                            status: value,
                            cards,
                            allCards: allCardsData,
                            date: new Date(date),
                        }
                    }

                    // Game Status
                    const gameStatus = value;

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    gameBetControls.showReplaceCardsButtons = false

                    const gameActionControls = cloneDeep(gameData.gameActionControls);


                    // GamePreBetControls
                    const gamePreBetControls = cloneDeep(gameData.gamePreBetControls);
                    if (!gameData.isReplay) {
                        this.uncheckPrePlayButtons(gamePreBetControls)
                    }


                    // GameHistoryEvents
                    let gameHistoryEvents = [...gameData.gameHistoryEvents];


                    if (cards) {
                        let gameHistoryEventData;
                        if (<GameStatus>value === GameStatus.Flop) {
                            gameHistoryEventData = GameEvent.DealFlop;
                        } else if (<GameStatus>value === GameStatus.Turn) {
                            gameHistoryEventData = GameEvent.DealTurn;
                        } else if (<GameStatus>value === GameStatus.River) {
                            gameHistoryEventData = GameEvent.DealRiver;
                        }
                        const gameHistoryEvent: GameHistoryEvent = { source: 'Game', data: gameHistoryEventData!, cards: cardsData }
                        gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];
                    }

                    const seats = cloneDeep(gameData.seats);

                    const myPlayer = seats.find(el => el?.id === userProfile.id)!
                    if (myPlayer) {
                        myPlayer.alreadyBetInThisRound = 0;
                    }

                    let tableCards = [...gameData.tableCards]

                    let mainPotValue = gameData.mainPotValue;
                    let mainPot2Value = gameData.mainPot2Value;
                    let potTotalValue = gameData.potTotalValue;
                    let startedRabbitHuntingPlayerId = gameData.startedRabbitHuntingPlayerId;
                    switch (gameStatus) {
                        case GameStatus.WaitingPlayers:
                            // reset all players
                            seats
                                .filter(el => !!el)
                                .map(el => el!)
                                .forEach((seat) => { // only players, where seat is not null, null means its empty
                                    seat.cards = [];
                                    seat.isDealer = false; // hide dealer
                                    seat.isPlayerTurn = false;
                                    gameBetControls.showBetButtons = false
                                    this.setReplayButtonsVisibility(gameData.isReplay, gameActionControls, gameBetControls)
                                })

                            tableCards = [];
                            mainPotValue = 0;
                            mainPot2Value = 0;
                            potTotalValue = this.getPotTotalValue(seats, mainPotValue)
                            break;

                        case GameStatus.Flop:
                            tableCards = allCardsData;
                            break;

                        case GameStatus.Turn:
                            tableCards = allCardsData;
                            break;

                        case GameStatus.River:
                            tableCards = allCardsData;
                            break;

                        case GameStatus.Showdown:
                            tableCards = allCardsData;
                            startedRabbitHuntingPlayerId = undefined
                            break;
                    }


                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            gameBetControls,
                            gamePreBetControls,
                            gameHistoryEvents,
                            gameStatus,
                            gameActionControls,
                            tableCards,
                            potTotalValue,
                            mainPotValue,
                            mainPot2Value,
                            startedRabbitHuntingPlayerId
                        }
                    })

                })
            )
    );


    fold$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgFold>(ServerMessageType.Fold)
            .pipe(
                switchMap((serverMsgFold) => combineLatest([
                    of(serverMsgFold),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgFold.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(TableSummariesSelectors.selectEntityById(serverMsgFold.idTable)),
                        filter(tableSum => !!tableSum),
                        map((tableSum) => tableSum!),
                        take(1)
                    ),
                ])),

                map(([serverMsgFold, gameData, userProfile, tableSum]) => {
                    let { idTable, idPlayer, date, value, value2 } = serverMsgFold;
                    const playerBalance = value2
                    const action = {
                        type: 'Fold',
                        data: {
                            idPlayer,
                            absoluteMoneyBet: value,
                            playerBalance,
                            date: new Date(date),
                        }
                    }

                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PlayerFold}` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    // Seats
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.money = playerBalance
                            }
                        })

                    // GameActionControls
                    const gameActionControls = cloneDeep(gameData.gameActionControls);


                    // Navigation Header
                    let folded = gameData.folded;
                    let onMove = gameData.onMove;

                    if (idPlayer === userProfile.id) {
                        this.setBuyChipsVisibility({
                            tourSum: undefined,
                            tableSum,
                            isReplay: gameData.isReplay,
                            actionControls: gameActionControls,
                            myPlayer: gameData.seats.find(el => el?.id === userProfile.id)!
                        })
                        folded = true;
                        onMove = false;
                    }





                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            gameHistoryEvents,
                            gameActionControls,
                            seats,
                            folded,
                            onMove
                        }
                    })
                })
            )
    );



    playerPlayStatistic$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPlayerPlayStatistic>(ServerMessageType.PlayerPlayStatistic)
            .pipe(
                switchMap((serverMsgFold) => combineLatest([
                    of(serverMsgFold),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgFold.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    )
                ])),

                map(([serverMsgPlayerPlayStatistic, gameData]) => {
                    const { idTable, idPlayer, values } = serverMsgPlayerPlayStatistic;

                    const nbHandsPlayed = values[0];
                    const nbHandsWon = values[1];
                    const totalBet = values[2];
                    const totalWon = values[3];
                    const profit = Math.max(totalWon - totalBet);
                    const nbHandsLost = nbHandsPlayed - nbHandsWon;

                    // PlayerPlayStatistic
                    const playersStatistics = cloneDeep(gameData.playersStatistics);
                    playersStatistics[idPlayer] = {
                        idPlayer,
                        nbHandsPlayed,
                        nbHandsWon,
                        nbHandsLost,
                        totalBet,
                        totalWon,
                        profit,
                        moneyTotalBet: gameData.isTournament ? `${totalBet}` : GameCurrencyPipe.prototype.transform(totalBet, gameData.currency),
                        moneyTotalWon: gameData.isTournament ? `${totalWon}` : GameCurrencyPipe.prototype.transform(totalWon, gameData.currency),
                        moneyProfit: gameData.isTournament ? `${profit}` : GameCurrencyPipe.prototype.transform(profit, gameData.currency)
                    }


                    return GamesActions.updateOne({
                        idTable, game: {
                            playersStatistics
                        }
                    })
                })
            )
    );

    setBuyChipsVisibility(data: {
        tourSum?: TournamentSummary,
        tableSum?: TableSummary,
        isReplay: boolean,
        actionControls: GameActionControls,
        myPlayer: GamePlayer
    }) {
        let shouldShow = false;

        if (data.tourSum !== undefined) {
            data.actionControls.showTipButton = false;


            if (data.tourSum.reBuyEndIn > 0 && data.tourSum.reBuyNbLimit > data.myPlayer.nbRebuy) {

                const reBuyThreshold = data.tourSum.reBuyThreshold
                const reBuyThreshold2x = data.tourSum.reBuyThreshold + data.tourSum.tournamentStartupChips;

                if (data.tourSum?.doubleRebuy) {
                    shouldShow = data.myPlayer.money <= reBuyThreshold2x;
                } else if (data.tourSum.reBuyThreshold > 0) {
                    shouldShow = data.myPlayer.money <= reBuyThreshold;
                }
            }


        } else {
            shouldShow = true;
        }

        if (data.isReplay) {
            data.actionControls.showBuyRebuyChipsButton = false;
            return;
        }

        if (data.tourSum) {
            data.actionControls.showBuyRebuyChipsButton = shouldShow;
            return;
        }

        if (data.myPlayer) {
            if (data.myPlayer.money >= this.getBlind() * data.tableSum!.takeSeatMax * 2) {
                data.actionControls.showBuyRebuyChipsButton = false;
                return;
            }
        } else {
            data.actionControls.showBuyRebuyChipsButton = false;
            return;
        }

        data.actionControls.showBuyRebuyChipsButton = shouldShow;
    }

    getBlind(): number { // TO DO
        return 0;
    }

    handStart$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgHandStart>(ServerMessageType.HandStart)
            .pipe(
                switchMap((serverMsgHandStart) => combineLatest([
                    of(serverMsgHandStart),
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgHandStart.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgHandStart, userProfile, gameData]) => {

                    const { idTable, date, value } = serverMsgHandStart;
                    const handNumber = value;

                    // Action
                    const action = {
                        type: 'HandStart',
                        data: {
                            handNumber,
                            date: date ? new Date(date) : new Date(),
                        }
                    }

                    // GameActionControls
                    const gameActionControls = cloneDeep(gameData.gameActionControls);

                    // enable/disable sitout button
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            seat.cards = [];
                            seat.isDealer = false; // hide dealer
                            if (seat.id === userProfile.id) {
                                if (seat.status === PlayerStatus.Sitout) {
                                    gameActionControls.showImBackButton = true;
                                } else if (seat.status === PlayerStatus.SitoutNextHand) {
                                    gameActionControls.showImBackButton = false;
                                    gameActionControls.checkSeatOutNextHand = true;
                                } else if (seat.status === PlayerStatus.Ready) {
                                    gameActionControls.showImBackButton = false;
                                    gameActionControls.checkSeatOutNextHand = false;
                                }
                            }
                        })


                    // Table Cards
                    const tableCards: CardData[] = []

                    // Main Pots
                    const mainPotValue = 0;
                    const mainPot2Value = 0;

                    // GameHistoryEvents
                    const gameHistoryEvent = { source: 'HandStart', data: GameEvent.HandStart }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    // GamePreBetControls
                    const gamePreBetControls = cloneDeep(gameData.gamePreBetControls);
                    if (!gameData.isReplay) {
                        this.hidePrePlayButtons(gamePreBetControls)
                    }
                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    this.setReplayButtonsVisibility(gameData.isReplay, gameActionControls, gameBetControls)

                    if (gameData.hasSexyDealer) {
                        gameActionControls.showTipButton = true;
                    }

                    // Hand Number
                    let currentHandNumber = gameData.currentHandNumber;
                    let previousHandNumber;
                    if (currentHandNumber !== handNumber) {
                        previousHandNumber = currentHandNumber;
                    }
                    currentHandNumber = handNumber;

                    const pauseOverlay = {
                        show: false,
                        timer: 0
                    }
                    return GamesActions.updateOne({
                        idTable,
                        game: {
                            action,
                            gameActionControls,
                            gameHistoryEvents,
                            gamePreBetControls,
                            gameBetControls,
                            currentHandNumber,
                            previousHandNumber,
                            tableCards,
                            mainPotValue,
                            mainPot2Value,
                            pauseOverlay,
                            seats
                        },
                    })

                })
            )
    );

    uncheckPrePlayButtons(gamePreBetControls: GamePreBetControls) {
        gamePreBetControls.values[PreBetType.call].checked = false;
        gamePreBetControls.values[PreBetType.check].checked = false;
        gamePreBetControls.values[PreBetType.checkFold].checked = false;
        gamePreBetControls.values[PreBetType.callAny].checked = false;
    }

    hidePrePlayButtons(gamePreBetControls: GamePreBetControls) {
        gamePreBetControls.values[PreBetType.call].visible = false;
        gamePreBetControls.values[PreBetType.check].visible = false;
        gamePreBetControls.values[PreBetType.checkFold].visible = false;
        gamePreBetControls.values[PreBetType.callAny].visible = false;
    }

    setReplayButtonsVisibility(isReplay: boolean, actionControls: GameActionControls, betControls: GameBetControls) {
        if (isReplay) {
            actionControls.showReplayButtons = true;
            betControls.showBetButtons = false;
            return;
        }

        actionControls.showReplayButtons = false;

    }

    moveToPot$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgMoveToPot>(ServerMessageType.MoveToPot)
            .pipe(
                switchMap((serverMsgMoveToPot) => combineLatest([
                    of(serverMsgMoveToPot),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgMoveToPot.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgMoveToPot, gameData]) => {

                    let { idTable, date, pots } = serverMsgMoveToPot;

                    const action = {
                        type: 'MoveToPot',
                        data: {
                            pots,
                            date: new Date(date),
                        }
                    }

                    let mainPotValue = gameData.mainPotValue;
                    let mainPot2Value = gameData.mainPot2Value;

                    for (let i = 0; i < pots.length; i++) {
                        if (i === 0) {
                            mainPotValue = pots[i].amount;
                        } else if (i === 1) {
                            mainPot2Value = pots[i].amount;
                        }
                    }

                    // GamePreBetControls
                    const gamePreBetControls = cloneDeep(gameData.gamePreBetControls);
                    if (!gameData.isReplay) {
                        this.hidePrePlayButtons(gamePreBetControls)
                    }


                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            gamePreBetControls,
                            mainPotValue,
                            mainPot2Value
                        }
                    })

                })
            )
    );






    endOfHand$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgEndOfHand>(ServerMessageType.EndOfHand)
            .pipe(
                switchMap((serverMsgEndOfHand) => combineLatest([
                    of(serverMsgEndOfHand),
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgEndOfHand.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(TableSummariesSelectors.selectEntityById(serverMsgEndOfHand.idTable)),
                        filter(tableSum => !!tableSum),
                        map((tableSum) => tableSum!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(PlayerBalanceSelectors.selectEntities),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgEndOfHand, userProfile, gameData, tableSum, playerBalances]) => {
                    let { idTable, date, value } = serverMsgEndOfHand;

                    const action = {
                        type: 'EndOfHand',
                        data: {
                            handNumber: value,
                            date: new Date(date),
                        }
                    }

                    // GameActionControls
                    const gameActionControls = cloneDeep(gameData.gameActionControls);
                    gameActionControls.showTipButton = false
                    gameActionControls.disableChat = false

                    // GamePreBetControls
                    const gamePreBetControls = cloneDeep(gameData.gamePreBetControls);
                    this.uncheckPrePlayButtons(gamePreBetControls)

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    this.setReplayButtonsVisibility(gameData.isReplay, gameActionControls, gameBetControls)

                    // GameBetControls: hide bet buttons
                    gameBetControls.showBetButtons = false;
                    gameBetControls.showFoldButton = false;

                    gameBetControls.showOfferRabbitHunting = false;

                    // GameHistoryEvents
                    const gameHistoryEvent = { source: 'Game', data: GameEvent.EndOfHand }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    // Cards in hand
                    const cardsInHand = [] as CardData[]

                    // Main Pot Values
                    const mainPotValue = gameData.mainPotValue;
                    const mainPot2Value = gameData.mainPot2Value;

                    // Clear Navigation Header
                    let folded = false;
                    let onMove = false

                    // Hide Dealer & BuyChips
                    const playerBalance = playerBalances[gameData.currencyId]!;
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            seat.isDealer = false; // hide dealer
                            seat.cards = [];
                            seat.potValue = 0;
                            // if it's me
                            if (seat.id === userProfile.id) {
                                // if no money
                                if (seat.money === 0) {
                                    if (!gameData.isReplay && !gameData.isTournament && seat.status !== PlayerStatus.LeaveSeat) {
                                        this._gameService.buyChips({
                                            tableId: idTable,
                                            dialogTimeout: 240,
                                            tableSum,
                                            player: seat,
                                            gameData,
                                            playerBalance,
                                        })
                                    }
                                }

                            }
                        })


                    const playerTurnId = null;
                    const tableCards = [] as CardData[];
                    const startedRabbitHuntingPlayerId = undefined;
                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            gameActionControls,
                            gameBetControls,
                            gamePreBetControls,
                            gameHistoryEvents,

                            mainPotValue,
                            mainPot2Value,
                            seats,
                            playerTurnId,
                            tableCards,

                            cardsInHand,
                            onMove,
                            folded,
                            startedRabbitHuntingPlayerId
                        }
                    })

                })
            )
    );

    dealer$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgDealer>(ServerMessageType.Dealer)
            .pipe(
                switchMap((serverMsgDealer) => combineLatest([
                    of(serverMsgDealer),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgDealer.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),

                map(([serverMsgDealer, gameData]) => {
                    let { idTable, date, idPlayer } = serverMsgDealer;

                    const action = {
                        type: 'Dealer',
                        data: {
                            idPlayer,
                            date: new Date(date),
                        }
                    }

                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PlayerDealer}` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];


                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            seat.cards = [];
                            seat.isDealer = false; // hide dealer
                            if (seat.id === idPlayer) {
                                seat.isDealer = true;
                            }
                        })

                    return GamesActions.updateOne({ idTable, game: { action, gameHistoryEvents, seats } })
                })
            )
    );

    getPlayerNameById(idPlayer: number, seats: (GamePlayer | null)[]) {
        const player = seats.find(el => el?.id === idPlayer);
        return player ? player.name : '';
    }

    getPlayerById(idPlayer: number, seats: (GamePlayer | null)[]) {
        return seats.find(el => el?.id === idPlayer);
    }

    isPlaying(playerStatus: PlayerStatus) {
        return playerStatus === PlayerStatus.Ready ||
            playerStatus === PlayerStatus.LeftNextHand ||
            playerStatus === PlayerStatus.SitoutNextHand;
    }

    bidAnte$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgBidAnte>(ServerMessageType.BidAnte)
            .pipe(
                switchMap((serverMsgBidAnte) => combineLatest([
                    of(serverMsgBidAnte),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgBidAnte.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgBidAnte, gameData]) => {
                    let { idTable, date, idPlayer, value, value2 } = serverMsgBidAnte;
                    const playerBalance = value2

                    const action = {
                        type: 'BidAnte',
                        data: {
                            idPlayer,
                            date: new Date(date),
                            absoluteMoneyBet: value,
                            playerBalance
                        }
                    }

                    // Seats
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.money = playerBalance
                            }
                        })

                    // Pot total value
                    const potTotalValue = this.getPotTotalValue(seats, gameData.mainPotValue)

                    // GameHistoryEvents
                    const money = value && gameData.currency ? GameCurrencyPipe.prototype.transform(value!, gameData.currency) : value;

                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PlayerBidAnte} [${money}]` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];


                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            gameHistoryEvents,
                            seats,
                            potTotalValue
                        }
                    })

                })
            )
    );

    bidSmallBlind$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgBidSmallBlind>(ServerMessageType.BidSmallBlind)
            .pipe(
                switchMap((serverMsgBidSmallBlind) => combineLatest([
                    of(serverMsgBidSmallBlind),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgBidSmallBlind.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgBidSmallBlind, gameData]) => {
                    let { idTable, date, idPlayer, value, value2 } = serverMsgBidSmallBlind;

                    const absoluteMoneyBet = value ?? 0;
                    const playerBalance = value2 ?? 0;

                    const action = {
                        type: 'BidSmallBlind',
                        data: {
                            idPlayer,
                            date: new Date(date),
                            absoluteMoneyBet,
                            playerBalance
                        }
                    }

                    // Seats
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.alreadyBetInThisRound = absoluteMoneyBet;
                                seat.money = playerBalance;
                            }
                        })

                    // Pot total value
                    const potTotalValue = this.getPotTotalValue(seats, gameData.mainPotValue)

                    // GameHistoryEvents
                    const money = value && gameData.currency ? GameCurrencyPipe.prototype.transform(value!, gameData.currency) : value;



                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PlayerBidSmallBlind} [${money}]` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];



                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            seats,
                            potTotalValue,
                            gameHistoryEvents
                        }
                    })

                })
            )
    );

    bidBigBlind$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgBidBigBlind>(ServerMessageType.BidBigBlind)
            .pipe(
                switchMap((serverMsgBidBigBlind) => combineLatest([
                    of(serverMsgBidBigBlind),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgBidBigBlind.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgBidBigBlind, gameData]) => {
                    let { idTable, date, idPlayer, value, value2 } = serverMsgBidBigBlind;

                    const absoluteMoneyBet = value ?? 0;
                    const playerBalance = value2 ?? 0;


                    const action = {
                        type: 'BidBigBlind',
                        data: {
                            idPlayer,
                            date: new Date(date),
                            absoluteMoneyBet,
                            playerBalance
                        }
                    }

                    // Seats
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.alreadyBetInThisRound = absoluteMoneyBet;
                                seat.money = playerBalance;
                            }
                        })

                    // Pot total value
                    const potTotalValue = this.getPotTotalValue(seats, gameData.mainPotValue)


                    // GameHistoryEvents
                    const money = value && gameData.currency ? GameCurrencyPipe.prototype.transform(value!, gameData.currency) : value;

                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PlayerBidBigBlind} [${money}]` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            seats,
                            potTotalValue,
                            gameHistoryEvents
                        }
                    })

                })
            )
    );

    bidCheck$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgBidCheck>(ServerMessageType.BidCheck)
            .pipe(
                switchMap((serverMsgBidCheck) => combineLatest([
                    of(serverMsgBidCheck),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgBidCheck.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgBidCheck, gameData]) => {
                    let { idTable, date, idPlayer, value, value2 } = serverMsgBidCheck;

                    const absoluteMoneyBet = value ?? 0;
                    const playerBalance = value2 ?? 0;

                    const action = {
                        type: 'BidCheck',
                        data: {
                            idPlayer,
                            date: new Date(date),
                            absoluteMoneyBet,
                            playerBalance
                        }
                    }

                    // Seats
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.alreadyBetInThisRound = absoluteMoneyBet;
                                seat.money = playerBalance;
                                seat.isPlayerTurn = false;
                            }
                        })

                    // Pot total value
                    const potTotalValue = this.getPotTotalValue(seats, gameData.mainPotValue)

                    // GameHistoryEvents
                    const money = value && gameData.currency ? GameCurrencyPipe.prototype.transform(value!, gameData.currency) : value;

                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PlayerBidCheck} ${money ? `[${money}]` : ``}` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            seats,
                            potTotalValue,
                            gameHistoryEvents
                        }
                    })

                })
            )
    );

    bidBet$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgBidBet>(ServerMessageType.BidBet)
            .pipe(
                switchMap((serverMsgBidBet) => combineLatest([
                    of(serverMsgBidBet),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgBidBet.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgBidBet, gameData]) => {
                    let { idTable, date, idPlayer, value, value2 } = serverMsgBidBet;

                    const absoluteMoneyBet = value ?? 0;
                    const playerBalance = value2 ?? 0;

                    const action = {
                        type: 'BidBet',
                        data: {
                            idPlayer,
                            date: new Date(date),
                            absoluteMoneyBet,
                            playerBalance
                        }
                    }

                    // Seats
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.alreadyBetInThisRound = absoluteMoneyBet;
                                seat.money = playerBalance;
                                seat.isPlayerTurn = false;
                            }
                        })

                    // Pot total value
                    const potTotalValue = this.getPotTotalValue(seats, gameData.mainPotValue)

                    // GameHistoryEvents
                    const money = value && gameData.currency ? GameCurrencyPipe.prototype.transform(value!, gameData.currency) : value;

                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PlayerBidBet}${money ? `[${money}]` : ''}` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            seats,
                            potTotalValue,
                            gameHistoryEvents
                        }
                    })
                })
            )
    );

    bidCall$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgBidCall>(ServerMessageType.BidCall)
            .pipe(
                switchMap((serverMsgBidCall) => combineLatest([
                    of(serverMsgBidCall),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgBidCall.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgBidCall, gameData]) => {
                    let { idTable, date, idPlayer, value, value2 } = serverMsgBidCall;

                    const absoluteMoneyBet = value ?? 0;
                    const playerBalance = value2 ?? 0;

                    const action = {
                        type: 'BidCall',
                        data: {
                            idPlayer,
                            date: new Date(date),
                            absoluteMoneyBet,
                            playerBalance
                        }
                    }

                    // Seats
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.alreadyBetInThisRound = absoluteMoneyBet;
                                seat.money = playerBalance;
                                seat.isPlayerTurn = false;
                            }
                        })

                    // Pot total value
                    const potTotalValue = this.getPotTotalValue(seats, gameData.mainPotValue)


                    // GameHistoryEvents
                    const money = value && gameData.currency ? GameCurrencyPipe.prototype.transform(value!, gameData.currency) : value;

                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PlayerBidCall} [${money}]` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            seats,
                            potTotalValue,
                            gameHistoryEvents
                        }
                    })

                })
            )
    );

    bidRaise$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgBidRaise>(ServerMessageType.BidRaise)
            .pipe(
                switchMap((serverMsgBidRaise) => combineLatest([
                    of(serverMsgBidRaise),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgBidRaise.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgBidRaise, gameData]) => {
                    let { idTable, date, idPlayer, value, value2 } = serverMsgBidRaise;

                    const absoluteMoneyBet = value ?? 0;
                    const playerBalance = value2 ?? 0;

                    const action = {
                        type: 'BidRaise',
                        data: {
                            idPlayer,
                            date: new Date(date),
                            absoluteMoneyBet: value,
                            playerBalance: value2
                        }
                    }

                    // Seats
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.alreadyBetInThisRound = absoluteMoneyBet;
                                seat.money = playerBalance;
                                seat.isPlayerTurn = false;
                            }
                        })

                    // Pot total value
                    const potTotalValue = this.getPotTotalValue(seats, gameData.mainPotValue)


                    // GameHistoryEvents
                    const money = value && gameData.currency ? GameCurrencyPipe.prototype.transform(value!, gameData.currency) : value;

                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PlayerBidRaise} [${money}]` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            seats,
                            potTotalValue,
                            gameHistoryEvents
                        }
                    })

                })
            )
    );

    bidStraddle$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgBidStraddle>(ServerMessageType.BidStraddle)
            .pipe(
                switchMap((serverMsgBidStraddle) => combineLatest([
                    of(serverMsgBidStraddle),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgBidStraddle.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgBidStraddle, gameData]) => {
                    let { idTable, date, idPlayer, value, value2 } = serverMsgBidStraddle;

                    const absoluteMoneyBet = value ?? 0;
                    const playerBalance = value2 ?? 0;

                    const action = {
                        type: 'BidStraddle',
                        data: {
                            idPlayer,
                            date: new Date(date),
                            absoluteMoneyBet,
                            playerBalance
                        }
                    }

                    // Seats
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.alreadyBetInThisRound = absoluteMoneyBet;
                                seat.money = playerBalance;
                                seat.isPlayerTurn = false;
                            }
                        })

                    // Pot total value
                    const potTotalValue = this.getPotTotalValue(seats, gameData.mainPotValue)

                    // GameHistoryEvents
                    const money = value && gameData.currency ? GameCurrencyPipe.prototype.transform(value!, gameData.currency) : value;

                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PostedStraddle} [${money}]` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];


                    return GamesActions.updateOne({ idTable, game: { action, seats, potTotalValue, gameHistoryEvents } })

                })
            )
    );

    bidAllIn$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgBidAllIn>(ServerMessageType.BidAllIn)
            .pipe(
                switchMap((serverMsgBidAllIn) => combineLatest([
                    of(serverMsgBidAllIn),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgBidAllIn.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgBidAllIn, gameData]) => {
                    let { idTable, date, idPlayer, value, value2 } = serverMsgBidAllIn;

                    const absoluteMoneyBet = value ?? 0;
                    const playerBalance = value2 ?? 0;

                    const action = {
                        type: 'BidAllIn',
                        data: {
                            idPlayer,
                            date: new Date(date),
                            absoluteMoneyBet,
                            playerBalance
                        }
                    }

                    // GameActionControls
                    const gameActionControls = cloneDeep(gameData.gameActionControls);
                    gameActionControls.disableChat = true // Chat is not allowed during All-in

                    // Seats
                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.alreadyBetInThisRound = absoluteMoneyBet;
                                seat.money = playerBalance;
                            }
                        })

                    // Pot total value
                    const potTotalValue = this.getPotTotalValue(seats, gameData.mainPotValue)


                    // GameHistoryEvents
                    const money = value && gameData.currency ? GameCurrencyPipe.prototype.transform(value!, gameData.currency) : value;

                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PlayerBidAllIn} [${money}]` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            gameHistoryEvents,
                            seats,
                            potTotalValue,
                            gameActionControls
                        }
                    })

                })
            )
    );


    potResult$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPotResult>(ServerMessageType.PotResult)
            .pipe(
                map((serverMsgBidAllIn) => {
                    let { idTable, date, pot } = serverMsgBidAllIn;

                    pot.winners = pot.winners.map(data => {
                        data.breakers = data.breakers || []
                        return data
                    })

                    pot.loosers = pot.loosers.map(data => {
                        data.breakers = data.breakers || []
                        return data
                    })

                    const action = {
                        type: 'PotResult',
                        data: {
                            date: new Date(date),
                            pot
                        }
                    }
                    return GamesActions.updateOne({ idTable, game: { action } })

                })
            )
    );

    communityCards$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgCommunityCards>(ServerMessageType.CommunityCards)
            .pipe(
                switchMap((serverMsgCommunityCards) => combineLatest([
                    of(serverMsgCommunityCards),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgCommunityCards.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgCommunityCards, gameData]) => {
                    let { idTable, date, cards, communityCardsR2T1, communityCardsR2T2 } = serverMsgCommunityCards;

                    cards = cards ? cards.map(card => ({ ...card, name: this.cardDecoder(card.suit, card.number) })) : []
                    communityCardsR2T1 = communityCardsR2T1 ? communityCardsR2T1.map(card => ({ ...card, name: this.cardDecoder(card.suit, card.number) })) : []
                    communityCardsR2T2 = communityCardsR2T2 ? communityCardsR2T2.map(card => ({ ...card, name: this.cardDecoder(card.suit, card.number) })) : []

                    const action = {
                        type: 'CommunityCards',
                        data: {
                            date: new Date(date),
                            cards,
                            communityCardsR2T1,
                            communityCardsR2T2
                        }
                    }

                    // GameHistoryEvents
                    const gameHistoryEvent = { source: 'Game', data: GameEvent.CommunityCards, cards: [...cards, ...communityCardsR2T1, ...communityCardsR2T2] }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    return GamesActions.updateOne({ idTable, game: { action, gameHistoryEvents } })

                })
            )
    );




    returnBackMoney$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgReturnBackMoney>(ServerMessageType.ReturnBackMoney)
            .pipe(
                map((serverMsgReturnBackMoney) => {
                    let { idTable, date, idPlayer, value, value2 } = serverMsgReturnBackMoney;

                    const action = {
                        type: 'ReturnBackMoney',
                        data: {
                            idPlayer,
                            amountToReturn: value,
                            playerBalance: value2,
                            date: new Date(date),
                        }
                    }
                    return GamesActions.updateOne({ idTable, game: { action } })

                })
            )
    );



    /**
     * potSplitted when overbidding return the extra to the player. [IdTable], [IdPlayer], Value: amt returned to the player. Value2: player Balance
     */

    potSplitted$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPotSplitted>(ServerMessageType.PotSplitted)
            .pipe(
                map((serverMsgPotSplitted) => {
                    let { idTable, date, pots } = serverMsgPotSplitted;

                    const action = {
                        type: 'PotSplitted',
                        data: {
                            pots,
                            date: new Date(date),
                        }
                    }
                    return GamesActions.updateOne({ idTable, game: { action } })

                })
            )
    );







    skipNextHand$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgSkipNextHand>(ServerMessageType.SkipNextHand)
            .pipe(
                switchMap((serverMsgSkipNextHand) => combineLatest([
                    of(serverMsgSkipNextHand),
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgSkipNextHand.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgSkipNextHand, userProfile, gameData]) => {
                    let { idTable } = serverMsgSkipNextHand;


                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    // IF CurrentPlayer
                    const index = gameData.seats.findIndex(el => el?.id === userProfile.id);
                    if (index > -1) {
                        gameBetControls.showPostBigBlind = true;
                    }

                    return GamesActions.updateOne({ idTable, game: { gameBetControls } })

                })
            )
    );




    playerTurnCardsReturnChange$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPlayerTurnCardsReturnChange>(ServerMessageType.PlayerTurnCardsReturnChange)
            .pipe(
                switchMap((serverMsgPlayerTurnCardsReturnChange) => combineLatest([
                    of(serverMsgPlayerTurnCardsReturnChange),
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgPlayerTurnCardsReturnChange.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgPlayerTurnCardsReturnChange, userProfile, gameData]) => {
                    let { idTable, date } = serverMsgPlayerTurnCardsReturnChange;


                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    // IF CurrentPlayer
                    const index = gameData.seats.findIndex(el => el?.id === userProfile.id);
                    if (index > -1) {
                        gameBetControls.showReplaceCardsButtons = true;
                    }


                    return GamesActions.updateOne({ idTable, game: { gameBetControls } })

                })
            )
    );

    // # PlayerBuyChips, PlayerLeave, PlayerStatus
    playerBuyChips$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPlayerBuyChips>(ServerMessageType.PlayerBuyChips)
            .pipe(
                switchMap((serverMsgPlayerBuyChips) => combineLatest([
                    of(serverMsgPlayerBuyChips),
                    this.getGameDataByTableId(serverMsgPlayerBuyChips.idTable),
                    this.getUserProfile(),
                    this.getTableSummary(serverMsgPlayerBuyChips.idTable),
                    // this.getTournamentById(serverMsgPlayerBuyChips.idTable),
                ])),
                map(([serverMsgPlayerBuyChips, gameData, userProfile, tableSum]) => {

                    let { idTable, idPlayer, date, value } = serverMsgPlayerBuyChips;

                    const action = {
                        type: 'PlayerBuyChips',
                        data: {
                            idPlayer,
                            chipsTotal: value,
                            date: new Date(date),
                        }
                    }

                    // GameActionControls
                    const gameActionControls = cloneDeep(gameData.gameActionControls);

                    if (idPlayer === userProfile.id) {
                        const isReplay = gameData.isReplay;
                        const isTournament = gameData.isTournament;
                        const myPlayer = gameData.seats.find(el => el?.id === userProfile.id);

                        const tournamentSummary = undefined; // ⏺
                        const sitNGoSummary = undefined; // ⏺

                        this.checkBuyChipsVisibility(gameActionControls, isReplay, isTournament, tableSum, tournamentSummary, sitNGoSummary, myPlayer);

                    }


                    return GamesActions.updateOne({ idTable, game: { action, gameActionControls } })
                })
            )
    );

    playerLeave$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPlayerLeave>(ServerMessageType.PlayerLeave)
            .pipe(
                switchMap((serverMsgPlayerLeave) => combineLatest([
                    of(serverMsgPlayerLeave),
                    this.getGameDataByTableId(serverMsgPlayerLeave.idTable),
                    this.getUserProfile(),
                    this.getTableSummary(serverMsgPlayerLeave.idTable),
                    // this.getTournamentById(serverMsgPlayerLeave.idTable),
                ])),
                map(([serverMsgPlayerLeave, gameData, userProfile, tableSum]) => {
                    let { idTable, idPlayer, date, playerData } = serverMsgPlayerLeave;

                    const action = {
                        type: 'PlayerLeave',
                        data: {
                            idPlayer,
                            playerData,
                            date: new Date(date),
                        }
                    }

                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.PlayerLeaveTable}` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];


                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    // GameActionControls
                    const gameActionControls = cloneDeep(gameData.gameActionControls);

                    // GamePreBetControls
                    const gamePreBetControls = cloneDeep(gameData.gamePreBetControls);

                    if (idPlayer === userProfile.id) {
                        gameBetControls.showBetButtons = false; // hide action buttons

                        const isReplay = gameData.isReplay;
                        const isTournament = gameData.isTournament;
                        const myPlayer = gameData.seats.find(el => el?.id === userProfile.id);
                        const tournamentSummary = undefined; // ⏺
                        const sitNGoSummary = undefined; // ⏺

                        this.checkBuyChipsVisibility(gameActionControls, isReplay, isTournament, tableSum, tournamentSummary, sitNGoSummary, myPlayer);


                        gameActionControls.showTipButton = false;
                        gameBetControls.showPostBigBlind = false;
                        gamePreBetControls.show = false;
                        gameActionControls.showImBackButton = false;

                        if (gameData.isTournament) {
                            gameActionControls.showLeaveTableButton = true
                        }
                    }
                    this.setReplayButtonsVisibility(gameData.isReplay, gameActionControls, gameBetControls)

                    const seats = cloneDeep(gameData.seats);
                    const seatIndex = seats.findIndex(el => el?.id === idPlayer);
                    if (seatIndex > -1) {
                        seats[seatIndex] = null;
                    }

                    return GamesActions.updateOne({
                        idTable, game: {
                            action,
                            gameHistoryEvents,
                            seats,
                            gameBetControls,
                            gameActionControls,
                            gamePreBetControls
                        }
                    })
                })
            )
    );

    playerStatus$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPlayerStatus>(ServerMessageType.PlayerStatus)
            .pipe(
                switchMap((serverMsgPlayerStatus) => combineLatest([
                    of(serverMsgPlayerStatus),
                    this.getGameDataByTableId(serverMsgPlayerStatus.idTable),
                    this.getUserProfile(),
                    this.getTableSummary(serverMsgPlayerStatus.idTable),
                    // this.getTournamentById(serverMsgPlayerStatus.idTable),
                ])),
                map(([serverMsgPlayerStatus, gameData, userProfile, tableSum]) => {

                    let { idTable, idPlayer, date, value, value2, postedStraddle } = serverMsgPlayerStatus;
                    const status = value;
                    const playerBalance = value2;

                    const action = {
                        type: 'PlayerStatus',
                        data: {
                            idPlayer,
                            status,
                            playerBalance,
                            postedStraddle,
                            date: new Date(date),
                        }
                    }

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    // GameActionControls
                    const gameActionControls = cloneDeep(gameData.gameActionControls);

                    // GamePreBetControls
                    const gamePreBetControls = cloneDeep(gameData.gamePreBetControls);

                    if (idPlayer === userProfile.id) {
                        gameBetControls.showReplaceCardsButtons = false

                        if (status === PlayerStatus.Sitout) {
                            gameActionControls.showImBackButton = true;
                            gameActionControls.checkSeatOutNextHand = true;
                            gamePreBetControls.show = false;
                            // disable all bitting buttons
                            gameBetControls.showBetButtons = false;
                        } else if (status === PlayerStatus.SitoutNextHand) {
                            gameActionControls.showImBackButton = false;
                            gameActionControls.checkSeatOutNextHand = true;
                            gamePreBetControls.show = false;
                        } else if (status === PlayerStatus.Ready) { // Ready
                            gameActionControls.showImBackButton = false;
                            gameActionControls.checkSeatOutNextHand = false;
                            // enable all betting buttons if it's my turn
                            if (gameData.playerTurnId === userProfile.id) {
                                gameBetControls.showBetButtons = true;
                            }
                        } else if (status === PlayerStatus.LeaveSeat) { // LeaveSeat
                            const isReplay = gameData.isReplay;
                            const isTournament = gameData.isTournament;
                            const myPlayer = gameData.seats.find(el => el?.id === userProfile.id);
                            const tournamentSummary = undefined; // ⏺
                            const sitNGoSummary = undefined; // ⏺

                            this.checkBuyChipsVisibility(gameActionControls, isReplay, isTournament, tableSum, tournamentSummary, sitNGoSummary, myPlayer);

                            gameActionControls.showImBackButton = false;
                        }
                        this.setReplayButtonsVisibility(gameData.isReplay, gameActionControls, gameBetControls)

                    }

                    const seats = cloneDeep(gameData.seats);
                    seats
                        .filter(el => !!el)
                        .map(el => el!)
                        .forEach((seat) => { // only players, where seat is not null, null means its empty
                            if (seat.id === idPlayer) {
                                seat.status = status
                            }
                        })

                    return GamesActions.updateOne({
                        idTable,
                        game: {
                            action,
                            gameBetControls,
                            gameActionControls,
                            gamePreBetControls,
                            seats
                        }
                    })
                })
            )
    );


    playerTakeSeat$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPlayerTakeSeat>(ServerMessageType.PlayerTakeSeat)
            .pipe(
                switchMap((ServerMsgPlayerTakeSeat) => combineLatest([
                    of(ServerMsgPlayerTakeSeat),
                    this.getGameDataByTableId(ServerMsgPlayerTakeSeat.idTable),
                    this.getUserProfile(),
                    this._store.pipe(
                        select(PlayerBalanceSelectors.selectEntities),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this.getTableSummary(ServerMsgPlayerTakeSeat.idTable),
                    // this.getTournamentById(ServerMsgPlayerTakeSeat.idTable),
                ])),
                map(([ServerMsgPlayerTakeSeat, gameData, userProfile, playerBalances, tableSum]) => {

                    let { idTable, idPlayer, date, value, value2, playerData } = ServerMsgPlayerTakeSeat;
                    const seatPosition = value ?? 0;
                    const buyChipsDialogTimeout = value2;

                    const action = {
                        type: 'PlayerTakeSeat',
                        data: {
                            idPlayer,
                            seatPosition,
                            buyChipsTimeout: buyChipsDialogTimeout,
                            playerData,
                            date: new Date(date),
                        }
                    }


                    // seats
                    let seats = cloneDeep(gameData.seats);
                    seats[seatPosition] = playerData as unknown as GamePlayer;

                    const playerBalance = playerBalances[gameData.currencyId]!;

                    // GameActionControls
                    const gameActionControls = cloneDeep(gameData.gameActionControls);

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    this.setReplayButtonsVisibility(gameData.isReplay, gameActionControls, gameBetControls)

                    if (idPlayer === userProfile.id) {
                        if (playerData.money === undefined) {

                            seats[seatPosition]!.money = 0;
                            this._gameService.buyChips({
                                tableId: idTable,
                                dialogTimeout: buyChipsDialogTimeout,
                                tableSum,
                                player: seats[seatPosition]!,
                                gameData,
                                playerBalance,
                            })
                        }


                        const isReplay = gameData.isReplay;
                        const isTournament = gameData.isTournament;
                        const myPlayer = gameData.seats.find(el => el?.id === userProfile.id);
                        const tournamentSummary = undefined; // ⏺
                        const sitNGoSummary = undefined; // ⏺


                        this.checkBuyChipsVisibility(gameActionControls, isReplay, isTournament, tableSum, tournamentSummary, sitNGoSummary, myPlayer);

                    }

                    // GameHistoryEvents
                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, seats), data: `${GameEvent.PlayerTakeSeat}` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    // ⏺ Player Call Time to do
                    // ⏺ Waiting List 
                    // ⏺ Player Notes
                    return GamesActions.updateOne({ idTable, game: { action, gameHistoryEvents, seats, gameActionControls, gameBetControls } })
                })
            )
    );


















    playerTurnChangeOnMove = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPlayerTurnChange>(ServerMessageType.PlayerTurnChange)
            .pipe(
                switchMap((serverMsgPlayerTurnChange) => combineLatest([
                    of(serverMsgPlayerTurnChange),
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgPlayerTurnChange.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),

                map(([{ idTable, idPlayer }, userProfile, gameData]) => {
                    let onMove = false

                    const seats = gameData.seats;
                    for (let i = 0; i < seats.length; i++) {
                        if (seats[i] !== null) {
                            if (seats[i]!.id === idPlayer) {
                                if (idPlayer === userProfile.id) {
                                    onMove = true
                                }
                            }
                        }
                    }

                    return GamesActions.updateOne({ idTable, game: { onMove } })
                })
            ))




    playerTurnChange$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPlayerTurnChange>(ServerMessageType.PlayerTurnChange)
            .pipe(
                switchMap((serverMsgPlayerTurnChange) => combineLatest([
                    of(serverMsgPlayerTurnChange),
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgPlayerTurnChange.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),

                map(([serverMsgPlayerTurnChange, userProfile, gameData]) => {

                    let { idTable, idPlayer, date, value, value2, value3, values } = serverMsgPlayerTurnChange;

                    const alreadyBetInThisRound = values[1] ?? 0;
                    const minimumBet = value ?? 0;
                    const minimumRaise = value2 ?? 0;
                    const maximumRaise = value3 ?? 0;

                    const playerBalance = values[0] ?? 0;
                    const previousRoundBet = values[2] ?? 0;
                    const potValue = values[3] ?? 0;
                    const highestBet = values[4] ?? 0;
                    const timeToPlay = values[5] ?? 0;
                    const isBringIn = values[6] ?? 0;


                    const action = {
                        type: 'playerTurnChange',
                        data: {
                            idPlayer,
                            minimumBet,
                            minimumRaise,
                            maximumRaise,
                            playerBalance,
                            alreadyBetInThisRound,
                            previousRoundBet,
                            potValue,
                            highestBet,
                            timeToPlay,
                            isBringIn,
                            date: new Date(date),
                        }
                    }


                    // GamePrePlayHandInfo
                    const gamePrePlayHandInfo = cloneDeep(gameData.gamePrePlayHandInfo);

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    // GamePreBetControls
                    const gamePreBetControls = cloneDeep(gameData.gamePreBetControls);

                    // Seats
                    const seats = cloneDeep(gameData.seats);

                    let handType = gameData.handType;

                    for (let i = 0; i < seats.length; i++) {
                        if (seats[i] !== null) {
                            if (seats[i]!.id === idPlayer) {


                                // If it's my turn
                                if (idPlayer === userProfile.id) {

                                    seats[i]!.currentCheckValue = alreadyBetInThisRound;
                                    gameBetControls.callValue = minimumBet;

                                    if (seats[i]!.status === PlayerStatus.Sitout) {

                                        gameBetControls.showBetButtons = false;
                                    } else {

                                        gameBetControls.showBetButtons = true;
                                    }

                                    const canRaise = minimumRaise > 0;

                                    gameBetControls.minimumBet = minimumBet;
                                    gameBetControls.showPostBigBlind = false;
                                    gameBetControls.showFoldButton = true;
                                    gameBetControls.showCheckButton = false;
                                    gameBetControls.showCallButton = false;
                                    gameBetControls.showRaiseButton = false;
                                    gameBetControls.showSlider = false;
                                    gameBetControls.showPotButton = false;
                                    gameBetControls.showHalfPotButton = false;
                                    gameBetControls.showQuarterPotButton = false;
                                    gameBetControls.showThreeQuartersPot = false;
                                    gameBetControls.isBetPreload = false;
                                    gameBetControls.isBringIn = isBringIn;

                                    const canUse345BbQuickBet = (
                                        canRaise && gameData.gameStatus === GameStatus.PreFlop && minimumBet < this.getBlind() * 4
                                    );
                                    const bb2 = this.getBlind() * 2 * 2; // *2-Big Blind *2-number of BB
                                    const bb3 = this.getBlind() * 2 * 3; // *2-Big Blind *3-number of BB
                                    const bb4 = this.getBlind() * 2 * 4; // *2-Big Blind *4-number of BB
                                    const halfPot = Math.ceil(potValue / 2);
                                    const quarterPot = Math.ceil(potValue / 4);
                                    const threeQuartersPot = Math.ceil(potValue * 0.75);

                                    gamePreBetControls.show = false;

                                    gameBetControls.betSlider = {
                                        min: Math.min(minimumRaise, maximumRaise),
                                        max: maximumRaise, // minimumBet === -1 ? playerBalance : maximumRaise,
                                        step: this.getBlind(),
                                        value: Math.min(minimumRaise, maximumRaise),
                                        playerBalance: playerBalance,

                                        bigBlind: {
                                            min: Math.round(Math.min(minimumRaise, maximumRaise) / (this.getBlind() * 2)),
                                            max: Math.round(maximumRaise / (this.getBlind() * 2)),
                                            step: Math.round(this.getBlind() / (this.getBlind() * 2)),
                                            value: Math.round(Math.min(minimumRaise, maximumRaise) / (this.getBlind() * 2)),
                                            playerBalance: Math.round(playerBalance / (this.getBlind() * 2)),
                                        }
                                    };
                                    this._gameService.updateRaiseButton({ minimumBet, isBringIn, betControls: gameBetControls });

                                    if (maximumRaise > potValue) {
                                        gameBetControls.showPotButton = true;
                                    } else {
                                        gameBetControls.showPotButton = false;
                                    }

                                    if (maximumRaise !== 0) {
                                        gameBetControls.showRaiseButton = true;
                                        seats[i]!.currentPotValue = potValue;
                                    } else {
                                        seats[i]!.currentPotValue = minimumBet;
                                    }

                                    gameBetControls.potValue = seats[i]!.currentPotValue!

                                    // -1 cannot check or call
                                    if (minimumBet < 0) {
                                        gameBetControls.showCallButton = false; // play sound to alert the user he must fold or go all-in
                                        gamePreBetControls.values[PreBetType.callAny].checked = false;
                                        gamePreBetControls.values[PreBetType.call].checked = false;
                                        gamePreBetControls.values[PreBetType.check].checked = false;
                                    } else {
                                        // nobody raise - check
                                        if (minimumBet === alreadyBetInThisRound) {
                                            gameBetControls.showCheckButton = true;
                                        } else {
                                            gameBetControls.showCallButton = true; // someone raise - call
                                            gameBetControls.justForShowCallValue = minimumBet - alreadyBetInThisRound;
                                            gamePreBetControls.values[PreBetType.check].checked = false;

                                            const amount = gameBetControls.justForShowCallValue / (this.getBlind() * 2)
                                            gameBetControls.justForShowCallValueBB = `${Math.round((amount + Number.EPSILON) * 100) / 100} BB`


                                        }
                                    }

                                    if (maximumRaise === (seats[i]!.money + alreadyBetInThisRound)) {
                                        seats[i]!.currentAllInValue = maximumRaise - alreadyBetInThisRound;
                                    } else {
                                        if (gameData.limit !== Limit.NL) {
                                            seats[i]!.currentAllInValue = maximumRaise;
                                        }
                                    }

                                    if (gameData.limit === Limit.FL) {
                                        if (!gameBetControls.showCallButton) {
                                            gameBetControls.showRaiseButton = true;
                                        } else {
                                            gameBetControls.showRaiseButton = false;
                                        }

                                        // this.betControls.raiseButtonText += `\r\n${this.getConvertedAmountText(this.betControls.betSlider.max)}`;


                                        gameBetControls.showSlider = false;
                                        gameBetControls.showPotButton = false;



                                        if (playerBalance === seats[i]!.currentAllInValue) {
                                            gameBetControls.raiseButtonText = 'BUTTON.ALLIN';
                                            gameBetControls.showRaiseButton = true;
                                        }
                                    }

                                    if (gameData.limit === Limit.PL) {

                                        gameBetControls.showMaxButton = false;

                                        if (potValue < playerBalance) {
                                            gameBetControls.showPotButton = true;
                                        } else {
                                            gameBetControls.showMaxButton = true;
                                        }


                                        if (gameBetControls.betSlider.min === gameBetControls.betSlider.max) {
                                            gameBetControls.showSlider = false;
                                        }
                                    }

                                    if (canRaise) {
                                        gameBetControls.showRaiseButton = true;
                                        if (gameData.limit !== Limit.FL) {
                                            gameBetControls.showSlider = true;
                                        }
                                    }

                                    if (canUse345BbQuickBet && minimumRaise <= bb2 && maximumRaise >= bb2) {
                                        gameBetControls.showBB2Button = true;
                                    } else {
                                        gameBetControls.showBB2Button = false;
                                    }
                                    if (canUse345BbQuickBet && minimumRaise <= bb3 && maximumRaise >= bb3) {
                                        gameBetControls.showBB3Button = true;
                                    } else {
                                        gameBetControls.showBB3Button = false;
                                    }
                                    if (canUse345BbQuickBet && minimumRaise <= bb4 && maximumRaise >= bb4) {
                                        gameBetControls.showBB4Button = true;
                                    } else {
                                        gameBetControls.showBB4Button = false;
                                    }

                                    const anyBbButtonVisible =
                                        gameBetControls.showBB3Button || gameBetControls.showBB4Button || gameBetControls.showBB2Button;
                                    if (!anyBbButtonVisible && canRaise && minimumRaise <= quarterPot && maximumRaise >= quarterPot) {
                                        gameBetControls.showQuarterPotButton = true;
                                    } else {
                                        gameBetControls.showQuarterPotButton = false;
                                    }
                                    if (!anyBbButtonVisible && canRaise && minimumRaise <= halfPot && maximumRaise >= halfPot) {
                                        gameBetControls.showHalfPotButton = true;
                                    } else {
                                        gameBetControls.showHalfPotButton = false;
                                    }

                                    if (!anyBbButtonVisible && canRaise && minimumRaise <= threeQuartersPot && maximumRaise >= threeQuartersPot) {
                                        gameBetControls.showThreeQuartersPot = true;
                                    } else {
                                        gameBetControls.showThreeQuartersPot = false;
                                    }

                                    // betting buttons override for pot limit games
                                    if (gameData.limit === Limit.PL) {
                                        if (gameBetControls.betSlider.value === gameBetControls.potValue / 4) {
                                            gameBetControls.showQuarterPotButton = false;
                                        }

                                        if (gameBetControls.betSlider.value === gameBetControls.potValue / 2) {
                                            gameBetControls.showHalfPotButton = false;
                                        }

                                        if (gameBetControls.betSlider.value === gameBetControls.potValue * 0.75) {
                                            gameBetControls.showThreeQuartersPot = false;
                                        }

                                        if (gameBetControls.potValue === this.getBlind() * 2 * 3) {
                                            gameBetControls.showBB3Button = false;
                                        }

                                        if (gameBetControls.potValue === this.getBlind() * 2 * 4) {
                                            gameBetControls.showBB4Button = false;
                                        }
                                    }

                                    if (gameData.limit === Limit.FL) {
                                        gameBetControls.showHalfPotButton = false;
                                        gameBetControls.showQuarterPotButton = false;
                                    }


                                    // ⏺ play sound
                                    // if (!this.isReplay) {
                                    //     this.playSound(this.assetsLoader.sound.WaitingOver);
                                    // }


                                    // PreBetControls
                                    if (gamePrePlayHandInfo) {
                                        if (highestBet > gamePrePlayHandInfo.previousHighestBet) {
                                            gamePreBetControls.values[PreBetType.call].checked = false; // not enought money for the call
                                        }
                                    }


                                    if (minimumBet < 0) {
                                        if (gamePreBetControls.values[PreBetType.checkFold].checked) {
                                            if (!gameData.isReplay) {
                                                this.uncheckPrePlayButtons(gamePreBetControls);
                                            }
                                            this._gameService.actionFold({
                                                betControls: gameBetControls,
                                                currentHandNumber: gameData.currentHandNumber,
                                                handType,
                                                tableId: gameData.idTable
                                            });
                                        }
                                    } else {
                                        if (gamePreBetControls.values[PreBetType.checkFold].checked) {
                                            if (!gameData.isReplay) {
                                                this.uncheckPrePlayButtons(gamePreBetControls);
                                            }
                                            if (highestBet === 0 || highestBet === alreadyBetInThisRound) {
                                                this._gameService.actionCheck({
                                                    betControls: gameBetControls,
                                                    checkValue: gameBetControls.callValue,
                                                    currentHandNumber: gameData.currentHandNumber,
                                                    tableId: gameData.idTable
                                                });
                                            } else {
                                                this._gameService.actionFold({
                                                    betControls: gameBetControls,
                                                    currentHandNumber: gameData.currentHandNumber,
                                                    handType,
                                                    tableId: gameData.idTable
                                                });
                                            }
                                        } else if (gamePreBetControls.values[PreBetType.check].checked) {
                                            if (!gameData.isReplay) {
                                                this.uncheckPrePlayButtons(gamePreBetControls);
                                            }
                                            this._gameService.actionCheck({
                                                betControls: gameBetControls,
                                                checkValue: gameBetControls.callValue,
                                                currentHandNumber: gameData.currentHandNumber,
                                                tableId: gameData.idTable
                                            });
                                        } else if (gamePreBetControls.values[PreBetType.call].checked) {
                                            if (!gameData.isReplay) {
                                                this.uncheckPrePlayButtons(gamePreBetControls);
                                            }
                                            this._gameService.actionCall({
                                                betControls: gameBetControls,
                                                callValue: gameBetControls.callValue,
                                                currentHandNumber: gameData.currentHandNumber,
                                                tableId: gameData.idTable
                                            });
                                        } else if (gamePreBetControls.values[PreBetType.callAny].checked) {
                                            if (!gameData.isReplay) {
                                                this.uncheckPrePlayButtons(gamePreBetControls);
                                            }
                                            this._gameService.actionCall({
                                                betControls: gameBetControls,
                                                callValue: gameBetControls.callValue,
                                                currentHandNumber: gameData.currentHandNumber,
                                                tableId: gameData.idTable
                                            });
                                        } else {
                                            if (!gameData.isReplay) {
                                                this.uncheckPrePlayButtons(gamePreBetControls);
                                            }
                                        }
                                    }
                                } else {
                                    // on other player turn
                                    gameBetControls.showBetButtons = false;

                                    const myPlayer = this.getPlayerById(userProfile.id, seats);

                                    if (myPlayer) {
                                        if (this.isPlaying(myPlayer.status) && myPlayer.cards.length > 0) {
                                            gamePreBetControls.show = true;
                                            gamePreBetControls.selected = undefined; // v4 fix

                                            gamePreBetControls.values[PreBetType.callAny].visible = true;
                                            gamePreBetControls.values[PreBetType.checkFold].visible = true;

                                            // [ ]fold, [ ]call
                                            // [ ]check/fold, [ ]check
                                            if (minimumBet === myPlayer.currentCheckValue) {
                                                // nobody raise - check
                                                gamePreBetControls.values[PreBetType.checkFold].text = 'Check/Fold';
                                                gamePreBetControls.values[PreBetType.check].visible = true;

                                                gamePreBetControls.values[PreBetType.call].visible = false;
                                                gamePreBetControls.values[PreBetType.call].checked = false;

                                            } else {
                                                if (myPlayer.alreadyBetInThisRound === highestBet) {
                                                    gamePreBetControls.values[PreBetType.checkFold].text = 'Check/Fold';
                                                    gamePreBetControls.values[PreBetType.check].visible = true;

                                                    gamePreBetControls.values[PreBetType.call].visible = false;
                                                    gamePreBetControls.values[PreBetType.call].checked = false;
                                                } else {
                                                    // someone else raise - call
                                                    gamePreBetControls.values[PreBetType.call].visible = true;
                                                    if (minimumBet > 0) {
                                                        gamePreBetControls.values[PreBetType.call].value = `HERE(${gameData.isTournament ? minimumBet - myPlayer.alreadyBetInThisRound : GameCurrencyPipe.prototype.transform(minimumBet - myPlayer.alreadyBetInThisRound, gameData.currency)})`;
                                                    }

                                                    if (highestBet !== gameData.gamePrePlayHandInfo.previousHighestBet) {
                                                        gamePreBetControls.values[PreBetType.call].checked = false;
                                                    }

                                                    if (highestBet > (myPlayer.money + (myPlayer.currentCheckValue ?? 0))) {
                                                        gamePreBetControls.values[PreBetType.call].checked = false; // not enought money for the call
                                                    }

                                                    gamePreBetControls.values[PreBetType.checkFold].text = 'Fold';
                                                    gamePreBetControls.values[PreBetType.check].visible = false;
                                                    gamePreBetControls.values[PreBetType.check].checked = false;
                                                }
                                            }
                                        } else {
                                            gamePreBetControls.show = false;
                                        }
                                    }
                                }
                            } else {
                                // end turn on PixiJS
                            }
                        }
                    }
                    return GamesActions.updateOne({ idTable, game: { action, handType, gamePreBetControls, gameBetControls, seats } })
                })
            )
    );













    checkBuyChipsVisibility(
        actionControls: GameActionControls,
        isReplay: boolean = false,
        isTournament: boolean = false,
        tableSum: TableSummary,
        tourSum?: TournamentSummary,
        sngSum?: SitNGoSummary,
        myPlayer?: GamePlayer | null
    ) {
        let shouldShow = false;

        if (tourSum && myPlayer) {
            actionControls.showTipButton = false;

            if (tourSum?.reBuyEndIn > 0 && tourSum?.reBuyNbLimit > myPlayer?.nbRebuy) {

                const reBuyThreshold = tourSum.reBuyThreshold
                const reBuyThreshold2x = tourSum.reBuyThreshold + tourSum.tournamentStartupChips;

                if (tourSum?.doubleRebuy) {
                    shouldShow = myPlayer.money <= reBuyThreshold2x;
                } else if (tourSum?.reBuyThreshold > 0) {
                    shouldShow = myPlayer.money <= reBuyThreshold;
                }
            }

        } else {
            shouldShow = true;
        }


        if (isReplay) {
            actionControls.showBuyRebuyChipsButton = false;
            return;
        }

        if (isTournament) {
            actionControls.showBuyRebuyChipsButton = shouldShow;
            return;
        }

        if (myPlayer) {
            if (myPlayer.money >= this._gameService.getBlind({ tableSum, tourSum, sngSum }) * tableSum.takeSeatMax * 2) {
                actionControls.showBuyRebuyChipsButton = false;
                return;
            }
        } else {
            actionControls.showBuyRebuyChipsButton = false;
            return;
        }

        actionControls.showBuyRebuyChipsButton = shouldShow;
    }




    // # Call Time

    callTimeStatus$ = createEffect(() => this._ws.getDataResponse<PlayerCallTimeData[]>(ServerResponse.CallTimeStatus)
        .pipe(
            tap((callTimeStatus) => console.log('#### ALL - playerCallTimeData', callTimeStatus)),
            mergeMap((callTimeStatus) => callTimeStatus),
            tap((playerCallTimeData) => {
                console.log('#### Single playerCallTimeData', playerCallTimeData)
            }),
            switchMap((playerCallTimeData) => combineLatest([
                of(playerCallTimeData),
                this.getGameDataByTableId(playerCallTimeData.idTable),
            ])),
            map(([playerCallTimeData, gameData]) => {
                // Seats Update Cards
                const idTable = playerCallTimeData.idTable;
                const seats = cloneDeep(gameData.seats);
                seats
                    .filter(el => !!el)
                    .map(el => el!)
                    .forEach((seat) => { // only players, where seat is not null, null means its empty
                        if (seat.id === playerCallTimeData.idPlayer) {
                            seat.playerCallTime = playerCallTimeData;
                        }
                    })
                const action = {
                    type: 'CallTimeStatus',
                    data: {
                        playerCallTime: playerCallTimeData
                    }
                }
                return GamesActions.updateOne({
                    idTable, game: {
                        action,
                        seats
                    }
                })
            }),
        )
    )


    callTimeCallTimeInProgress$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgInfo>(ServerMessageType.CallTimeCallTimeInProgress)
            .pipe(), { dispatch: false })

    callTimeNoActivated$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgInfo>(ServerMessageType.CallTimeNoActivated)
            .pipe(), { dispatch: false })



    // Winner Messages
    winnerByFold$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgWinnerByFold>(ServerMessageType.WinnerByFold)
            .pipe(
                switchMap((serverMsgWinnerByFold) => combineLatest([
                    of(serverMsgWinnerByFold),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgWinnerByFold.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgWinnerByFold, gameData]) => {
                    let { idTable, idPlayer, date, value, value2, cards } = serverMsgWinnerByFold;

                    const action = {
                        type: 'WinnerByFold',
                        data: {
                            idPlayer,
                            winMoney: value,
                            balance: value2,
                            date: new Date(date),
                        }
                    }

                    // GameHistoryEvents
                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.WinnerByFold}` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];


                    return GamesActions.updateOne({ idTable, game: { action, gameHistoryEvents } })
                })
            )
    );

    winnerByStrongestHand$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgWinnerByStrongestHand>(ServerMessageType.WinnerByStrongestHand)
            .pipe(
                switchMap((serverMsgWinnerByStrongestHand) => combineLatest([
                    of(serverMsgWinnerByStrongestHand),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgWinnerByStrongestHand.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgWinnerByStrongestHand, gameData]) => {
                    let { idTable, idPlayer, date, value, value2, cards } = serverMsgWinnerByStrongestHand;

                    cards = cards ? cards.map(card => ({ ...card, name: this.cardDecoder(card.suit, card.number) })) : []

                    const action = {
                        type: 'WinnerByStrongestHand',
                        data: {
                            idPlayer,
                            winMoney: value,
                            balance: value2,
                            date: new Date(date),
                        }
                    }

                    // GameHistoryEvents
                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.WinnerByStrongestHand}`, cards }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];


                    return GamesActions.updateOne({ idTable, game: { action, gameHistoryEvents } })
                })
            )
    );

    winnerSplit$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgWinnerSplit>(ServerMessageType.WinnerSplit)
            .pipe(
                switchMap((serverMsgWinnerSplit) => combineLatest([
                    of(serverMsgWinnerSplit),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgWinnerSplit.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([ServerMsgWinnerSplit, gameData]) => {
                    let { idTable, idPlayer, date, value, value2, cards } = ServerMsgWinnerSplit;

                    const action = {
                        type: 'WinnerSplit',
                        data: {
                            idPlayer,
                            winMoney: value,
                            balance: value2,
                            date: new Date(date),
                        }
                    }

                    // GameHistoryEvents
                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.WinnerByStrongestHand}` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    return GamesActions.updateOne({ idTable, game: { action, gameHistoryEvents } })
                })
            )
    );
    // -----------------------



    // Timebank

    timeBankStatus$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgTimeBankStatus>(ServerMessageType.TimeBankStatus)
            .pipe(
                map((serverMsgTimeBankStatus) => {
                    let { idTable, date, value } = serverMsgTimeBankStatus;

                    const action = {
                        type: 'TimeBankStatus',
                        data: {
                            banktime: value,
                            date: new Date(date),
                        }
                    }
                    return GamesActions.updateOne({ idTable, game: { action } })

                })
            )
    );


    timeBankUsing$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgTimeBankUsing>(ServerMessageType.TimeBankUsing)
            .pipe(
                map((serverMsgTimeBankUsing) => {
                    let { idTable, date, value, idPlayer } = serverMsgTimeBankUsing;

                    const action = {
                        type: 'TimeBankUsing',
                        data: {
                            banktime: value,
                            idPlayer,
                            date: new Date(date),
                        }
                    }
                    return GamesActions.updateOne({ idTable, game: { action } })

                })
            )
    );


    /**
    * CHAT
    */
    onServerMessageTypeChat$ = createEffect(() =>
        this._store.pipe(
            select(ConfigSelectors.getDomainSettings),
            filter(domainSettings => !!domainSettings),
            take(1),
            switchMap(domainSettings => this._ws.getServerMsg<ServerMsgGameChat>(ServerMessageType.Chat)
                .pipe(
                    filter(serverMsgLobbyChat => !!serverMsgLobbyChat.idTable),


                    switchMap((serverMsgLobbyChat) => combineLatest([
                        of(serverMsgLobbyChat),
                        this.getGameDataByTableId(serverMsgLobbyChat.idTable),
                    ])),


                    map(([serverMsgGameChat, gameData]) => {
                        let { idTable, date, text, idPlayer } = serverMsgGameChat;

                        const chat = cloneDeep(gameData.chat);

                        // Message Color
                        let messageColor = '#D4E157'; // default color
                        const message = chat.find(message => message.player.id === idPlayer);
                        if (message) {
                            messageColor = message.color; // get existing color from previous messages
                        } else {
                            const messagesGroupedByPlayers = groupBy(chat, chat => chat.player.id);
                            switch (Object.keys(messagesGroupedByPlayers).length) {
                                case 0:
                                    messageColor = '#29B6F6';
                                    break;
                                case 1:
                                    messageColor = '#EF5350';
                                    break;
                                case 2:
                                    messageColor = '#FFA726';
                                    break;
                                case 3:
                                    messageColor = '#66BB6A';
                                    break;
                                case 4:
                                    messageColor = '#AB47BC';
                                    break;
                                case 5:
                                    messageColor = '#FFEE58';
                                    break;
                                case 6:
                                    messageColor = '#EC407A';
                                    break;
                                case 7:
                                    messageColor = '#5C6BC0';
                                    break;
                                case 8:
                                    messageColor = '#26C6DA';
                                    break;

                            }
                        }

                        // To do: add adapter service from(serverMsgGameChat)
                        const chatMessage: GameChat = {
                            text,
                            player: {
                                id: idPlayer,
                                name: serverMsgGameChat.playerData.name,
                                avatar: serverMsgGameChat.playerData.avatar ? `${domainSettings!.httpUrl}/avatar/${serverMsgGameChat.playerData.avatar}` : `${environment.dataSource}/assets/${domainSettings!.code}/player/avatar.png`,
                                rank: serverMsgGameChat.playerData.rank,
                            },
                            date: new Date(date),
                            localTimestamp: new Date().getTime(),
                            color: messageColor
                        }
                        chat.push(chatMessage)

                        const action = {
                            type: 'Chat',
                            data: {
                                idPlayer,
                                text
                            }
                        }

                        return GamesActions.updateOne({ idTable, game: { chat, action } })

                    })
                ))));



    onChatSendMessage$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onChatSendMessage),
                tap((chatSendMessage) => this._gameService.sendMessage(chatSendMessage.idTable, chatSendMessage.value))
            ), { dispatch: false })



    onJoinWaitingList$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onJoinWaitingList),
                tap((data) => this._gameService.joinWaitingList(data.idTable))
            ), { dispatch: false }
    )

    onLeaveWaitingList$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onLeaveWaitingList),
                tap((data) => this._gameService.leaveWaitingList(data.idTable))
            ), { dispatch: false }
    )

    onOpenUserInfoDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onOpenUserInfoDialog),
                switchMap((data) => {
                    return this._dialog.open<{ playerId: number, tableId: number, noteText: string, noteColor: string }>(GameDialogPlayerInfoComponent, {
                        width: '330px',
                        data: {
                            tableId: data.tableId,
                            playerId: data.playerId,
                            playerName: data.playerName,
                            noteText: data.noteText,
                            noteColor: data.noteColor
                        }
                    }).closed
                }),
                tap(dialogData => {
                    if (dialogData) {
                        this._gameService.setPlayerNoteText(dialogData.playerId, dialogData.noteText)
                        this._gameService.setPlayerNoteColor(dialogData.playerId, dialogData.noteColor)
                    }
                })
            ), { dispatch: false })





    // playerColor: { [playerId: number]: string }
    // playerNote: { [playerId: number]: string }
    //         {
    // "MemberPreference": {
    //     "RunItTwice": 0,
    //         "AutoMuck": true,
    //             "JumpToTable": true,
    //                 "PlayerNotes": {
    //         "34385": "dsa"
    //     },
    //     "PlayerColor": {
    //         "34385": "#E8B50E"
    //     },
    //     "ExchangeRates": null,
    //         "SelfExclude": "0001-01-01T00:00:00",
    //             "RequestLogError": false,
    //                 "Type": 26
    // },
    // "Response": "MemberPreference"
    // }



    /**
    * HELPER FUNCTIONS ::
    */
    getMaxPlayers(data: { tourSum?: TournamentSummary, sngSum?: SitNGoSummary, tableSum?: TableSummary }): MaxPlayers {

        if (data.tourSum) {
            return data.tourSum.tournamentNbSeatsPerTable as MaxPlayers;
        }

        if (data.sngSum) {
            return data.sngSum.tournamentNbSeatsPerTable as MaxPlayers;
        }

        if (data.tableSum) {
            // TODO: 📖 if replay table
            // if (this.id < 0) {
            //     return 10; // to do | start 5 april
            // }

            return data.tableSum.maxPlayers as MaxPlayers;
        }

        return 10; // default value for seats
    }


    getIsFast(data: { tourSum?: TournamentSummary, sngSum?: SitNGoSummary, tableSum?: TableSummary }): boolean {

        if (data.tourSum) {
            return !!data.tourSum.isFast;
        }

        if (data.sngSum) {
            return !!data.sngSum.isFast;
        }

        if (data.tableSum) {
            // TODO: 📖 if replay table
            // if (this.id < 0) {
            //     return false; // to do | start 5 april
            // }

            return !!data.tableSum.isFast
        }

        return false; // default value for seats
    }

    getVariant(data: { tourSum?: TournamentSummary, sngSum?: SitNGoSummary, tableSum?: TableSummary }): number {

        if (data.tourSum) {
            return data.tourSum.variant;
        }

        if (data.sngSum) {
            return data.sngSum.variant;
        }

        if (data.tableSum) {
            // TODO: 📖 if replay table
            // if (this.id < 0) {
            //     return 0;
            // }

            return data.tableSum.variant2;
        }

        return 0; // default value for variant

    }


    cardDecoder(cardSuit: number, cardNumber: number, reversed = false) {
        if (cardSuit === undefined || cardNumber === undefined) { return ''; }

        let cardNumberText: string;
        let cardSuitText: string;

        if (cardNumber === CardNumber.Jack) {
            cardNumberText = 'J';
        } else if (cardNumber === CardNumber.Queen) {
            cardNumberText = 'Q';
        } else if (cardNumber === CardNumber.King) {
            cardNumberText = 'K';
        } else if (cardNumber === CardNumber.Ace) {
            cardNumberText = 'A';
        } else if (cardNumber === CardNumber.T) {
            cardNumberText = 'T';
        } else {
            cardNumberText = cardNumber.toString();
        }

        if (cardSuit === CardSuit.Hearts) {
            cardSuitText = 'h';
        } else if (cardSuit === CardSuit.Diamods) {
            cardSuitText = 'd';
        } else if (cardSuit === CardSuit.Clubs) {
            cardSuitText = 'c';
        } else if (cardSuit === CardSuit.Spades) {
            cardSuitText = 's';
        } else {
            cardSuitText = '';

        }

        return reversed ? cardNumberText + cardSuitText : cardSuitText + cardNumberText;
    }



    playerSetRunItTwice$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgPlayerSetRunItTwice>(ServerMessageType.PlayerSetRunItTwice)
            .pipe(
                switchMap((serverMsgPlayerSetRunItTwice) => combineLatest([
                    of(serverMsgPlayerSetRunItTwice),
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(serverMsgPlayerSetRunItTwice.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([serverMsgPlayerTurnCardsReturnChange, userProfile, gameData]) => {
                    let { idTable, idPlayer, value } = serverMsgPlayerTurnCardsReturnChange;

                    // GameActionControls
                    const gameActionControls = cloneDeep(gameData.gameActionControls);

                    if (idPlayer === userProfile.id) {
                        gameActionControls.checkRunItTwice = value
                    }

                    return GamesActions.updateOne({ idTable, game: { gameActionControls } })

                })
            )
    );





    getPotTotalValue(seats: (GamePlayer | null)[], mainPot: number): number {
        let potsOnTableTotalValue = 0;

        seats
            .filter(el => !!el)
            .map(el => el!)
            .forEach(seat => {
                potsOnTableTotalValue += seat.potValue ?? 0;
            })
        if (mainPot) {
            potsOnTableTotalValue += mainPot;
        }

        return potsOnTableTotalValue;
    }














    // GAME ACTIONS
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++




    // Take a seat
    onTakeSeat$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.takeSeat),
                map(({ idTable, seatPosition }) => this._gameService.takeSeat(idTable, seatPosition))
            ), { dispatch: false })

    // 👾 On Pre Bet Control            
    onSetPreBetControl$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.setPreBetControl),
                switchMap((setPreBetControl) => combineLatest([
                    of(setPreBetControl),
                    this._store.pipe(
                        select(GamesSelectors.selectEntityById(setPreBetControl.idTable)),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                ])),
                map(([setPreBetControl, gameData]) => {
                    let { idTable, preBetType, checked } = setPreBetControl;

                    // GamePreBetControls
                    const gamePreBetControls = cloneDeep(gameData.gamePreBetControls);

                    Object.keys(gamePreBetControls.values).forEach(key => {
                        if ((+key as PreBetType) === preBetType) {
                            gamePreBetControls.values[+key as PreBetType].checked = checked;
                        } else {
                            gamePreBetControls.values[+key as PreBetType].checked = false;
                        }
                    })

                    gamePreBetControls.selected = setPreBetControl.checked ? setPreBetControl.preBetType : undefined;

                    return GamesActions.updateOne({ idTable, game: { gamePreBetControls } })
                })
            ))

    // 🕹️ On Action Control
    onIamBack$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.actionControlIAmBack),
                tap(({ idTable }) => this._gameService.actionImBack(idTable))
            ), { dispatch: false })

    onLeaveSeat$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.actionControlLeaveSeat),
                switchMap(({ idTable }) => combineLatest([
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this.getGameDataByTableId(idTable),
                    this._store.pipe(
                        select(TablesSelectors.selectEntityById(idTable)),
                        filter(tableData => !!tableData && !!tableData!.tableSummary),
                        map(tableData => tableData!),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    )
                ])),

                map(([userProfile, gameData, table]) => {
                    const idTable = table.tableSummary!.id;
                    const callTimeConfiguration = table.tableSummary!.callTimeConfiguration
                    const myPlayerSeat = gameData.seats.find(el => el?.id === userProfile.id)
                    const playerCallTime = myPlayerSeat?.playerCallTime;
                    if (myPlayerSeat && callTimeConfiguration && callTimeConfiguration.isCallTime && (!playerCallTime || playerCallTime.canLeaveTable === false)) {
                        return GamesActions.openCallTimeDialog({ idTable, playerCallTime })
                    } else {
                        const onMove = false;
                        const folded = false;
                        const cardsInHand = [] as CardData[];
                        this._gameService.requestLeaveSeat(idTable);
                        return GamesActions.updateOne({ idTable, game: { onMove, folded, cardsInHand } })
                    }
                })
            ))


    onLeaveTable$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.actionControlLeaveTable),
                switchMap(({ idTable }) => combineLatest([
                    this._store.pipe(
                        select(UserSelectors.selectUserProfile),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    ),
                    this.getGameDataByTableId(idTable),
                    this._store.pipe(
                        select(TablesSelectors.selectEntityById(idTable)),
                        filter(tableData => !!tableData && !!tableData!.tableSummary),
                        map(tableData => tableData!),
                        filter(el => !!el),
                        map(el => el!),
                        take(1)
                    )
                ])),

                map(([userProfile, gameData, table]) => {
                    const idTable = table.tableSummary!.id;
                    const callTimeConfiguration = table.tableSummary!.callTimeConfiguration
                    const myPlayerSeat = gameData.seats.find(el => el?.id === userProfile.id)
                    const playerCallTime = myPlayerSeat?.playerCallTime;
                    if (myPlayerSeat && callTimeConfiguration && callTimeConfiguration.isCallTime && (!playerCallTime || playerCallTime.canLeaveTable === false)) {
                        return GamesActions.openCallTimeDialog({ idTable, playerCallTime })
                    } else {
                        this._gameService.requestLeaveTable(idTable)
                        this._router.navigate(['lobby'])
                        return GamesActions.removeOne({ idTable })
                    }
                })
            ))

    onOpenCallTimeDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.openCallTimeDialog),
                switchMap(({ idTable, playerCallTime }) => {
                    const dialog = this._dialog.open<boolean>(GameCallTimeDialogComponent, { width: '300px', data: playerCallTime })
                    return combineLatest([dialog.closed, of(idTable)])

                }),
                tap(([dialogData, idTable]) => {
                    if (dialogData) {
                        this._gameService.startCallTime(idTable)
                    }
                })
            ), { dispatch: false }
    )

    onActionControlBuyRebuyChips$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.actionControlBuyRebuyChips),
                switchMap(({ idTable }) => combineLatest([
                    this.getGameDataByTableId(idTable),
                    this.getTableSummary(idTable),
                    this.getUserProfile(),
                    this.getPlayerBalances()
                ])),
                tap(([gameData, tableSum, userProfile, playerBalances]) => {
                    const tableId = gameData.idTable;
                    const player = gameData.seats.find(seat => seat?.id === userProfile.id);
                    const playerBalance = playerBalances[gameData.currencyId]!;
                    if (!player) return;
                    this._gameService.buyChips({
                        tableId,
                        dialogTimeout: 240, // ⏺
                        tableSum,
                        player,
                        gameData,
                        playerBalance,
                    })
                })
            ), { dispatch: false })



    // 🎲 On Bet Action
    onFold$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionFold),
                switchMap(({ idTable }) => this._store.pipe(
                    select(GamesSelectors.selectEntityById(idTable)),
                    filter(el => !!el),
                    map(el => el!),
                    take(1)
                )),
                switchMap((gameData) => {
                    // Show Check Button is Confirmation Dialog
                    if (gameData.gameBetControls.showCheckButton) {
                        //
                        const dialog = this._dialog.open(GenericDialogComponent, { width: '300px' })
                        dialog.componentInstance!.title = 'Fold';
                        dialog.componentInstance!.text = 'Are you sure you want to fold? You can check for free?'
                        dialog.componentInstance!.dissmissBtn = 'Cancel'
                        dialog.componentInstance!.confirmBtn = 'FOLD'
                        return combineLatest([dialog.closed, of(gameData)])
                    }
                    return combineLatest([of(true), of(gameData)])
                }),
                map(([foldConfirmed, gameData]) => {
                    if (foldConfirmed) {
                        return GamesActions.foldConfirmed({ idTable: gameData.idTable })
                    } else {
                        return GamesActions.foldCanceled({ idTable: gameData.idTable })
                    }
                })
            ))


    foldConfirmed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.foldConfirmed),
                switchMap(({ idTable }) => this._store.pipe(
                    select(GamesSelectors.selectEntityById(idTable)),
                    filter(el => !!el),
                    map(el => el!),
                    take(1)
                )),
                map((gameData) => {

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    gameBetControls.isBetPreload = false;

                    let handType = gameData.handType;

                    this._gameService.actionFold({
                        betControls: gameBetControls,
                        currentHandNumber: gameData.currentHandNumber,
                        handType,
                        tableId: gameData.idTable
                    });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { handType, gameBetControls } })

                })
            ))


    onCall$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionCall),
                switchMap(({ idTable }) => this._store.pipe(
                    select(GamesSelectors.selectEntityById(idTable)),
                    filter(el => !!el),
                    map(el => el!),
                    take(1)
                )),
                map((gameData) => {
                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    gameBetControls.isBetPreload = false;

                    let handType = gameData.handType;

                    this._gameService.actionCall({
                        betControls: gameBetControls,
                        callValue: gameBetControls.callValue,
                        currentHandNumber: gameData.currentHandNumber,
                        tableId: gameData.idTable
                    });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { handType, gameBetControls } })

                })
            ))


    onCheck$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionCheck),
                switchMap(({ idTable }) => this._store.pipe(
                    select(GamesSelectors.selectEntityById(idTable)),
                    filter(el => !!el),
                    map(el => el!),
                    take(1)
                )),
                map((gameData) => {
                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    gameBetControls.isBetPreload = false;

                    let handType = gameData.handType;

                    this._gameService.actionCheck({
                        betControls: gameBetControls,
                        checkValue: gameBetControls.callValue,
                        currentHandNumber: gameData.currentHandNumber,
                        tableId: gameData.idTable
                    });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { handType, gameBetControls } })

                })
            ))


    onRaise$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionRaise),
                switchMap(({ idTable }) => this._store.pipe(
                    select(GamesSelectors.selectEntityById(idTable)),
                    filter(el => !!el),
                    map(el => el!),
                    take(1)
                )),
                map((gameData) => {
                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    gameBetControls.isBetPreload = false;

                    let handType = gameData.handType;

                    const raiseValue = gameBetControls.betSlider.value === 0 ? gameBetControls.betSlider.max : gameBetControls.betSlider.value;

                    this._gameService.actionRaise({
                        betControls: gameBetControls,
                        raiseValue,
                        currentHandNumber: gameData.currentHandNumber,
                        tableId: gameData.idTable
                    });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { handType, gameBetControls } })

                })
            ))

    onBB2$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionBB2),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                switchMap((gameData) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId)
                    ])
                }),
                map(([gameData, tableSum, tournament]) => {
                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    gameBetControls.betSlider.value = this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 4;
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))

    onBB3$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionBB3),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                switchMap((gameData) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId)
                    ])
                }),
                map(([gameData, tableSum, tournament]) => {
                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    gameBetControls.betSlider.value = this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2 * 3;
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))

    onBB4$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionBB4),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                switchMap((gameData) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId)
                    ])
                }),
                map(([gameData, tableSum, tournament]) => {
                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    gameBetControls.betSlider.value = this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2 * 4;
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))


    onPot$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionPot),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                switchMap((gameData) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId),
                        this.getUserProfile()
                    ])
                }),
                map(([gameData, tableSum, tournament, userProfile]) => {
                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    const seats = cloneDeep(gameData.seats);

                    const myPlayer = seats.find(el => el?.id === userProfile.id)!
                    const potValue = myPlayer.currentPotValue ?? 0

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    gameBetControls.betSlider.value = potValue
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);
                    this._gameService.updateRaiseButton({ minimumBet: gameBetControls.minimumBet, isBringIn: gameBetControls.isBringIn, betControls: gameBetControls });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))

    onHalfPot$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionHalfPot),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                switchMap((gameData) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId),
                        this.getUserProfile()
                    ])
                }),
                map(([gameData, tableSum, tournament, userProfile]) => {
                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    const seats = cloneDeep(gameData.seats);

                    const myPlayer = seats.find(el => el?.id === userProfile.id)!
                    const potValue = myPlayer.currentPotValue ?? 0

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    gameBetControls.betSlider.value = Math.ceil(potValue / 2);
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);
                    this._gameService.updateRaiseButton({ minimumBet: gameBetControls.minimumBet, isBringIn: gameBetControls.isBringIn, betControls: gameBetControls });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))

    onQuarterPot$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionQuarterPot),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                switchMap((gameData) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId),
                        this.getUserProfile()
                    ])
                }),
                map(([gameData, tableSum, tournament, userProfile]) => {
                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    const seats = cloneDeep(gameData.seats);

                    const myPlayer = seats.find(el => el?.id === userProfile.id)!
                    const potValue = myPlayer.currentPotValue ?? 0

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    gameBetControls.betSlider.value = Math.ceil(potValue / 4);
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);
                    this._gameService.updateRaiseButton({ minimumBet: gameBetControls.minimumBet, isBringIn: gameBetControls.isBringIn, betControls: gameBetControls });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))

    onThreeQuartersPot$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionThreeQuartersPot),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                switchMap((gameData) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId),
                        this.getUserProfile()
                    ])
                }),
                map(([gameData, tableSum, tournament, userProfile]) => {
                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    const seats = cloneDeep(gameData.seats);

                    const myPlayer = seats.find(el => el?.id === userProfile.id)!
                    const potValue = myPlayer.currentPotValue ?? 0

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    gameBetControls.betSlider.value = Math.ceil(potValue * 0.75);
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);
                    this._gameService.updateRaiseButton({ minimumBet: gameBetControls.minimumBet, isBringIn: gameBetControls.isBringIn, betControls: gameBetControls });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))




    // RABBIT HUNTING


    /**
     * CODE_DEBT
     * Rabbit Hunting Animation text for player who requested Rabbit Hunting
     */
    freeRabbitHunting$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgFreeRabbitHunting>(ServerMessageType.FreeRabbitHunting)
            .pipe(
                tap((data) => console.log("🐇 Rabbit Hunting - FreeRabbitHunting", data)),

                map((freeRabbitHunting) => {
                    const { idTable, value } = freeRabbitHunting;
                    return GamesActions.freeRabbitHunting({ idTable, value })
                })
            ))


    chargedRabbitHunting$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgChargedRabbitHunting>(ServerMessageType.ChargedRabbitHunting)
            .pipe(
                tap((data) => console.log("🐇 Rabbit Hunting - ChargedRabbitHunting", data)),

                switchMap(chargedRabbitHuntingData => {
                    return combineLatest([
                        of(chargedRabbitHuntingData),
                        this._store.pipe(
                            select(CurrenciesSelectors.selectEntityById(chargedRabbitHuntingData.value3)),
                            filter(currency => !!currency),
                            map((currency) => currency!),
                            take(1))
                    ])
                }),
                map(([chargedRabbitHuntingData, currencyInfo]) => {
                    const { idTable, value, value2, value3 } = chargedRabbitHuntingData;
                    const chargedRabbitHunting: ChargedRabbitHunting = {
                        type: value,
                        amount: value2,
                        currencyId: value3,
                        currency: currencyInfo
                    }
                    return GamesActions.chargedRabbitHunting({ idTable, chargedRabbitHunting })
                })
            ))

    rabbitHuntingPrice$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgRabbitHuntingPrice>(ServerMessageType.RabbitHuntingPrice)
            .pipe(
                tap((data) => console.log("🐇 Rabbit Hunting - RabbitHuntingPrice", data)),

                switchMap(rabbitHuntingPriceData => {
                    return combineLatest([
                        of(rabbitHuntingPriceData),
                        this._store.pipe(
                            select(CurrenciesSelectors.selectEntityById(rabbitHuntingPriceData.value2)),
                            filter(currency => !!currency),
                            map((currency) => currency!),
                            take(1))
                    ])
                }),
                map(([chargedRabbitHuntingData, currencyInfo]) => {
                    const { idTable, value, value2, value3 } = chargedRabbitHuntingData;
                    const rabbitHuntingPrice: RabbitHuntingPrice = {
                        type: value3,
                        amount: value,
                        currencyId: value2,
                        currency: currencyInfo
                    }
                    return GamesActions.updateRabbitHuntingPrice({ idTable, rabbitHuntingPrice })
                })
            ))


    startedRabbitHunting$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgStartedRabbitHunting>(ServerMessageType.StartedRabbitHunting)
            .pipe(
                tap(({ idPlayer, idTable }) => console.log("🐇 Rabbit Hunting - StartedRabbitHunting", idPlayer, idTable)),
                switchMap(({ idPlayer, idTable }) => combineLatest([
                    of({ idPlayer, idTable }),
                    this.getGameDataByTableId(idTable),
                ])),
                map(([{ idPlayer, idTable }, gameData]) => {
                    const gameHistoryEvent: GameHistoryEvent = { source: this.getPlayerNameById(idPlayer, gameData.seats), data: `${GameEvent.RequestedRabbitHunting}` }
                    const gameHistoryEvents = [...gameData.gameHistoryEvents, gameHistoryEvent];

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    gameBetControls.showOfferRabbitHunting = false;

                    return GamesActions.updateOne({
                        idTable,
                        game: {
                            gameHistoryEvents,
                            startedRabbitHuntingPlayerId: idPlayer,
                            gameBetControls
                        }
                    })
                })
            ))

    hideRabbitHuntingButton$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgHideRabbitHuntingButton>(ServerMessageType.HideRabbitHuntingButton)
            .pipe(
                tap(({ idTable }) => console.log("🐇 Rabbit Hunting - ServerMsgHideRabbitHuntingButton", idTable)),
                switchMap(({ idTable }) => combineLatest([
                    of(idTable),
                    this.getGameDataByTableId(idTable),
                ])),
                map(([idTable, gameData]) => {
                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    gameBetControls.showOfferRabbitHunting = false;

                    return GamesActions.updateOne({
                        idTable,
                        game: {
                            gameBetControls
                        }
                    })
                })
            ))


    offerRabbitHunting$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgOfferRabbitHunting>(ServerMessageType.OfferRabbitHunting)
            .pipe(
                tap(({ idTable }) => console.log("🐇 Rabbit Hunting - ServerMsgOfferRabbitHunting", idTable)),
                switchMap(({ idTable }) => combineLatest([
                    of(idTable),
                    this.getGameDataByTableId(idTable),
                ])),
                map(([idTable, gameData]) => {
                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    gameBetControls.showOfferRabbitHunting = true;

                    return GamesActions.updateOne({
                        idTable,
                        game: {
                            gameBetControls
                        }
                    })
                })
            ))



    onOfferRabbitHunting$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onOfferRabbitHunting),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                map((gameData) => {

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);
                    gameBetControls.showOfferRabbitHunting = false;

                    this._gameService.offerRabbitHunting(gameData.idTable, gameData.currentHandNumber);

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))

    // BET SLIDER

    onBetActionSliderMin$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionSliderMin),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                switchMap((gameData) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId),
                        this.getUserProfile()
                    ])
                }),
                map(([gameData, tableSum, tournament, userProfile]) => {
                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    gameBetControls.betSlider.value = gameBetControls.betSlider.min;
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);
                    this._gameService.updateRaiseButton({ minimumBet: gameBetControls.minimumBet, isBringIn: gameBetControls.isBringIn, betControls: gameBetControls });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))

    onBetActionSliderMax$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionSliderMax),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                switchMap((gameData) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId),
                        this.getUserProfile()
                    ])
                }),
                map(([gameData, tableSum, tournament, userProfile]) => {
                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    gameBetControls.betSlider.value = gameBetControls.betSlider.max;
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);
                    this._gameService.updateRaiseButton({ minimumBet: gameBetControls.minimumBet, isBringIn: gameBetControls.isBringIn, betControls: gameBetControls });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))


    onBetActionSliderDecrease$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionSliderDecrease),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                switchMap((gameData) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId),
                        this.getUserProfile()
                    ])
                }),
                map(([gameData, tableSum, tournament, userProfile]) => {
                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);


                    const smallBlind = this._gameService.getBlind({ tableSum, tourSum, sngSum });
                    const mult = Math.floor(gameBetControls.betSlider.value / smallBlind);

                    // @OLD
                    let step;
                    if (gameBetControls.betSlider.value !== smallBlind * mult) {
                        step = gameBetControls.betSlider.value - smallBlind * mult;
                    } else {
                        step = smallBlind;
                    }
                    // @NEW Step Calc
                    //   step = Math.round((gameBetControls.betSlider.max - gameBetControls.betSlider.min) / 10)
                    gameBetControls.betSlider.value -= step;

                    if (gameBetControls.betSlider.value < gameBetControls.betSlider.min) {
                        gameBetControls.betSlider.value = gameBetControls.betSlider.min;
                    }
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);

                    this._gameService.updateRaiseButton({ minimumBet: gameBetControls.minimumBet, isBringIn: gameBetControls.isBringIn, betControls: gameBetControls });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })

                })
            ))

    onBetActionSliderIncrease$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionSliderIncrease),
                switchMap(({ idTable }) => this.getGameDataByTableId(idTable)),
                switchMap((gameData) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId),
                        this.getUserProfile()
                    ])
                }),
                map(([gameData, tableSum, tournament, userProfile]) => {
                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    const smallBlind = this._gameService.getBlind({ tableSum, tourSum, sngSum });
                    const mult = Math.floor(gameBetControls.betSlider.value / smallBlind);

                    // @OLD
                    let step;
                    if (gameBetControls.betSlider.value !== smallBlind * mult) {
                        step = gameBetControls.betSlider.value - smallBlind * mult;
                    } else {
                        step = smallBlind;
                    }

                    // @NEW Step Calc
                    //     step = Math.round((gameBetControls.betSlider.max - gameBetControls.betSlider.min) / 10)
                    gameBetControls.betSlider.value += step;

                    if (gameBetControls.betSlider.value > gameBetControls.betSlider.max) {
                        gameBetControls.betSlider.value = gameBetControls.betSlider.max;
                    }
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);

                    this._gameService.updateRaiseButton({ minimumBet: gameBetControls.minimumBet, isBringIn: gameBetControls.isBringIn, betControls: gameBetControls });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })

                })
            ))

    onBetActionSliderChange$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionSliderChange),
                switchMap(({ idTable, value }) => combineLatest([of(value), this.getGameDataByTableId(idTable)])),
                switchMap(([value, gameData]) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId),
                        this.getUserProfile(),
                        of(value)
                    ])
                }),
                // filter(([gameData, tableSum, tournament, userProfile, value]) => {
                //     const gameBetControls = cloneDeep(gameData.gameBetControls);
                //     return value !== gameBetControls.betSlider.value
                // }),
                map(([gameData, tableSum, tournament, userProfile, value]) => {

                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    if (gameBetControls.betSlider.max - value < gameBetControls.betSlider.step) {
                        gameBetControls.betSlider.value = gameBetControls.betSlider.max;
                    } else {
                        gameBetControls.betSlider.value = value;
                    }
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);

                    this._gameService.updateRaiseButton({ minimumBet: gameBetControls.minimumBet, isBringIn: gameBetControls.isBringIn, betControls: gameBetControls });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))

    onBetActionSliderInputChange$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onBetActionSliderInputChange),
                switchMap(({ idTable, value }) => combineLatest([of(value), this.getGameDataByTableId(idTable)])),
                switchMap(([value, gameData]) => {
                    return combineLatest([
                        of(gameData),
                        this.getTableSummary(gameData.idTable),
                        this.getTournamentById(gameData.tournamentId),
                        this.getUserProfile(),
                        of(value)
                    ])
                }),
                filter(([gameData, tableSum, tournament, userProfile, value]) => {
                    value = value * gameData.currency.multiplier
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    return value <= gameBetControls.betSlider.max && value >= gameBetControls.betSlider.min
                }),
                map(([gameData, tableSum, tournament, userProfile, value]) => {

                    value = value * gameData.currency.multiplier

                    const tourSum = tournament?.tournamentSummary;
                    const sngSum = tournament?.sitNGoSummary;

                    // GameBetControls
                    const gameBetControls = cloneDeep(gameData.gameBetControls);

                    gameBetControls.betSlider.value = value;
                    gameBetControls.betSlider.bigBlind.value = gameBetControls.betSlider.value / (this._gameService.getBlind({ tableSum, tourSum, sngSum }) * 2);

                    this._gameService.updateRaiseButton({ minimumBet: gameBetControls.minimumBet, isBringIn: gameBetControls.isBringIn, betControls: gameBetControls });

                    return GamesActions.updateOne({ idTable: gameData.idTable, game: { gameBetControls } })
                })
            ))

    onRunItTwiceTable$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onRunItTwiceTable),
                tap(({ idTable, value }) => {
                    this._gameService.runItTwiceTable(idTable, value)
                })
            ), { dispatch: false })

    onRunItTwiceGlobal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(GamesActions.onRunItTwiceGlobal),
                tap(({ value }) => {
                    this._gameService.runItTwiceGlobal(value)
                })
            ), { dispatch: false })

    private getUserProfile() {
        return this._store.pipe(
            select(UserSelectors.selectUserProfile),
            filter(el => !!el),
            map(el => el!),
            take(1)
        )
    }

    private getGameDataByTableId(idTable: number) {
        return this._store.pipe(
            select(GamesSelectors.selectEntityById(idTable)),
            filter(el => !!el),
            map(el => el!),
            take(1)
        )
    }

    private getTournamentById(tournamentId?: number) {
        if (!tournamentId) {
            return of(null)
        }

        return this._store.pipe(
            select(TournamentsSelectors.selectEntityById(tournamentId)),
            filter(tableSum => !!tableSum),
            map((tableSum) => tableSum!),
            take(1)
        )
    }

    private getTableSummary(idTable: number) {
        return this._store.pipe(
            select(TableSummariesSelectors.selectEntityById(idTable)),
            filter(tableSum => !!tableSum),
            map((tableSum) => tableSum!),
            take(1)
        )

    }

    private getPlayerBalances() {
        return this._store.pipe(
            select(PlayerBalanceSelectors.selectEntities),
            filter(el => !!el),
            map(el => el!),
            take(1)
        )
    }


}