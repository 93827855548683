import { Injectable, inject } from '@angular/core';
import { ServerRequestMessage } from '@app/enums/server-request';
import { CurrencyInfo } from '@app/models/currency-info';
import { DomainSettings } from '@app/models/domain-settings';
import { GlobalSettings } from '@app/models/global-settings';
import { MemberProfile, MemberProfileDTO } from '@app/models/member-profile';
import { PlayerLevelStatus, PlayerLevelStatusDTO } from '@app/models/player-level-status';
import { WsService } from '@app/services/ws.service';
import { environment } from '@environments/environment';
import { camelCase } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly _ws = inject(WsService)


  updateMemberProfile(memberProfileDTO: MemberProfileDTO, domainSettings: DomainSettings): MemberProfile {
    const memberProfile: MemberProfile = {
      ...memberProfileDTO,
      dateJoinedDateFormat: new Date(memberProfileDTO.dateJoined),
      profileForm: memberProfileDTO.profileForm.map((formField) => {
        formField.name = camelCase(formField.name) as keyof MemberProfile;
        return formField
      }).sort((a) => a.isReadOnly ? -1 : 1),
      profilePicture: memberProfileDTO.avatar ? `${domainSettings.httpUrl}/avatar/${memberProfileDTO.avatar}` : `${environment.dataSource}/assets/${domainSettings.code}/player/avatar.png`,
      newAvatar: memberProfileDTO.newAvatar ? `${domainSettings.httpUrl}/avatar/${memberProfileDTO.newAvatar}` : undefined
    }
    return memberProfile
  }

  changePassword(password: string) {
    this._ws.sendData({
      type: ServerRequestMessage.AccountChangePassword,
      str1: password
    });
  }

  checkUsernameExist(username: string) {
    this._ws.sendData({
      type: ServerRequestMessage.AccountVerifyUsernameUnique,
      str1: username
    });
  }

  checkEmailExist(email: string) {
    this._ws.sendData({
      type: ServerRequestMessage.AccountVerifyEmailUnique,
      str1: email
    });
  }

  updateAccount(memberProfile: Partial<MemberProfile>) {
    this._ws.sendData({
      type: ServerRequestMessage.UpdateAccount,
      memberProfile
    });
  }

  uploadAvatar(imageBase64: string) {
    this._ws.sendData({
      type: ServerRequestMessage.AvatarUpload,
      str1: imageBase64
    });
  }

  selfExclude(period: number, password: string) {
    this._ws.sendData({
      type: ServerRequestMessage.SelfExclude,
      value: period,
      Str1: password
    });
  }

  toPlayerLevelStatus(playerLevelStatus: PlayerLevelStatusDTO, currencyInfo: CurrencyInfo, globalSettings: GlobalSettings): PlayerLevelStatus {
    let rakebackLevelStructure;
    let currentLevel;
    let nextLevel;

    if (globalSettings?.rakebackLevelStructuresData) {
      rakebackLevelStructure = globalSettings?.rakebackLevelStructuresData[currencyInfo.code.toLocaleLowerCase()]
      currentLevel = rakebackLevelStructure?.levels.find(el => el.level === playerLevelStatus.level)
      nextLevel = rakebackLevelStructure?.levels.find(el => el.level === playerLevelStatus.level + 1)
    }


    return { ...playerLevelStatus, currencyInfo, rakebackLevelStructure, currentLevel, nextLevel }
  }


  autoMuck(turnOn: boolean) {
    this._ws.sendData({
      type: ServerRequestMessage.SetAutoMuck,
      value: turnOn
    });
  }

  jumpToTable(turnOn: boolean) {
    this._ws.sendData({
      type: ServerRequestMessage.SetJumpToTable,
      value: turnOn
    });
  }

}