import { APP_INITIALIZER, Inject, Renderer2, importProvidersFrom, inject } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppComponent } from '@app/app.component';
import { RouterModule } from '@angular/router';
import { routes } from '@app/app-routing.module';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ToastrModule } from 'ngx-toastr';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';

import { WSEffects } from '@app/store/features/web-socket/web-socket.effects';
import { ConfigEffects } from '@app/store/features/config/config.effects';
import { AuthService } from '@app/features/auth/services/auth.service';


import { ActionReducerMap } from "@ngrx/store";
import * as WebSocket from '@app/store/features/web-socket/web-socket.reducer';
import * as Config from '@app/store/features/config/config.reducer';
import * as ConfigActions from '@app/store/features/config/config.actions';

import * as Auth from '@app/store/features/auth/auth.reducer';
import { AuthEffects } from '@app/store/features/auth/auth.effects';
import { DOCUMENT } from '@angular/common';
import { ThemeService } from '@app/services/theme.service';
import { Observable, combineLatest, lastValueFrom, take, tap } from 'rxjs';
import { provideToastr } from 'ngx-toastr';
import { ConfigService } from '@app/services/config.service';
import { WINDOW_PROVIDERS } from '@app/services/window.providers';
import { DialogModule } from '@angular/cdk/dialog';

export interface State {
  ws: WebSocket.State;
  config: Config.State;
  auth: Auth.State;
}

export const reducers: ActionReducerMap<State> = {
  ws: WebSocket.reducer,
  config: Config.reducer,
  auth: Auth.reducer,
}




function initializeAppFactory(themeService: ThemeService, configService: ConfigService, store: Store<any>): () => Observable<any> {
  return () => combineLatest([themeService.applyTheme(), configService.loadConfig()])
    .pipe(
      tap((a) => console.log("APP initializeAppFactory 🚁", a)),
      tap(([theme, domainSettings]) => store.dispatch(ConfigActions.updateDomainSettings({ domainSettings }))),
      take(1)
    );
}

bootstrapApplication(AppComponent, {
  providers: [
    WINDOW_PROVIDERS,
    importProvidersFrom(
      RouterModule.forRoot(routes),
      StoreModule.forRoot(reducers),
      EffectsModule.forRoot([
        ConfigEffects,
        WSEffects,
        AuthEffects,
      ]),
      DialogModule
    ),
    provideAnimations(),
    provideHttpClient(),
    provideToastr({
      timeOut: 60000,
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'increasing',
      tapToDismiss: false,
      enableHtml: true,
      closeButton: true
    }),
    // { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: APP_INITIALIZER, useFactory: initializeAppFactory, deps: [ThemeService, ConfigService, Store], multi: true },

  ]
}).catch(err => console.error(err));