import { createReducer, on } from '@ngrx/store';
import * as ConfigActions from '@app/store/features/config/config.actions';
import { SkinSettings } from '@app/models/skin-settings';
import { GlobalSettings } from '@app/models/global-settings';
import { JackPotInfo } from '@app/models/jackpot-info';
import { DomainSettings } from '@app/models/domain-settings';

interface LocalConfig {
    assetsLoaded: boolean;
    gameInitialized: boolean;
}

export interface ConfigTheme {
    card: number;
    backCard: number;
    table: number;
    carpet: number;
}

export interface AuthSettings {
    token: string;
    url: string;
}

export interface State extends LocalConfig {
    // emoticonsInfo: EmoticonInfo[];

    casinosInfo: any[];
    jackPotsInfo: JackPotInfo[];
    appReadyToUse: boolean; // will be reacived from the server after the login

    globalSettings: GlobalSettings | null; // mostly used for the game
    skinSettings: SkinSettings | null; // (firebase) will be reacived from the server after the login
    domainSettings: DomainSettings | null; // creator configuration for domainname
    authSettings: AuthSettings | null; // REST API Config
    assets: {
        default: {
            loaded: number;
        },

        //  later extandable with themes
        theme: ConfigTheme
    }
}

export const initialState: State = {
    // emoticonsInfo: [],

    casinosInfo: [],
    jackPotsInfo: [],

    appReadyToUse: false,
    assetsLoaded: true,
    gameInitialized: false,



    globalSettings: null,
    skinSettings: null,
    domainSettings: null,
    authSettings: null,
    assets: {
        default: {
            loaded: 0
        },
        theme: {
            card: 0, // CardStyle ID
            backCard: 0, // BackCardStyle ID
            table: 0, // TableStyle ID
            carpet: 0, // TableStyle ID
        }
    }
};

export const reducer = createReducer(
    initialState,
    on(ConfigActions.updateAppReadyToUse, (state, { appReadyToUse }) => ({ ...state, appReadyToUse })), // CODE_DEBT: gameInitialized is set to appReadyToUse
    on(ConfigActions.updateGameInitialized, (state, { gameInitialized }) => ({ ...state, gameInitialized })),
    on(ConfigActions.updateGlobalSettings, (state, { globalSettings }) => ({ ...state, globalSettings })),
    on(ConfigActions.updateSkinSettings, (state, { skinSettings }) => ({ ...state, skinSettings })),

    on(ConfigActions.updateCasinosInfo, (state, { casinosInfo }) => ({ ...state, casinosInfo })),
    on(ConfigActions.updateJackPotsInfo, (state, { jackPotsInfo }) => ({ ...state, jackPotsInfo })),

    on(ConfigActions.updateDomainSettings, (state, { domainSettings }) => ({ ...state, domainSettings })),
    on(ConfigActions.updateAuthSettings, (state, { authSettings }) => ({ ...state, authSettings })),

    on(ConfigActions.onAssetsLoading, (state, { percentage }) => {
        const assets = { ...state.assets, default: { loaded: percentage } };
        return { ...state, assets }
    }),

    // theme
    on(ConfigActions.updateAssetsThemeSelectCard, (state, { id }) => {
        const theme = { ...state.assets.theme, card: id };
        const assets = { ...state.assets, theme };

        return { ...state, assets }
    }),

    on(ConfigActions.updateAssetsThemeSelectBackCard, (state, { id }) => {
        const theme = { ...state.assets.theme, backCard: id };
        const assets = { ...state.assets, theme };

        return { ...state, assets }
    }),

    on(ConfigActions.updateAssetsThemeSelectTable, (state, { id }) => {
        const theme = { ...state.assets.theme, table: id };
        const assets = { ...state.assets, theme };

        return { ...state, assets }
    }),

    on(ConfigActions.updateAssetsThemeSelectCarpet, (state, { id }) => {
        const theme = { ...state.assets.theme, carpet: id };
        const assets = { ...state.assets, theme };
        return { ...state, assets }
    })
);