import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { StatsPlayerOnline } from '../../../features/lobby/models/stats-player-online';
import { LobbyPreviewNav } from '../../../features/lobby/enums/lobby-preview-nav';

import * as LobbyActions from './lobby.actions';
import { LobbyFilterVariant } from '../../../features/lobby/enums/lobby-filter-variant';
import { LobbyFilterStake } from '../../../features/lobby/enums/lobby-filter-stake';
import { LobbyFilterLimit } from '../../../features/lobby/enums/lobby-filter-limit';
import { LobbyFilterSpeed } from '../../../features/lobby/enums/lobby-filter-speed';
import { LobbyFilterSeat } from '../../../features/lobby/enums/lobby-filter-seat';
import { LobbyFilterTableType } from '../../../features/lobby/enums/lobby-filter-table-type';

import * as AuthActions from '@app/store/features/auth/auth.actions';

export interface LobbyFilters {
    tableType?: LobbyFilterTableType; // int [optional] ONE table type, see list of TableType bellow. If the value is not present, all tables types are returnes (in multiple messages per types).
    variants: LobbyFilterVariant; // int[] array of VariantType2 to be returned, if the array is NOT present, no filter will be made on variant.
    stakes: LobbyFilterStake; // int[] array of StakeType. Return tables with BB matching the configured stake range (micro to high).  if the array is NOT present, no filter wil be made on stake.
    limits: LobbyFilterLimit; // int[] array of LimitTypes. Return table matching the limit types. If the array is NOT present, no filter will be made on limit.
    speeds: LobbyFilterSpeed; // int[] array of SpeedType. Return the game speed to be returned, if the array is NOT presetnt, no filter will be made on speed.

    nbSeats: number; // int[] array of number. Return table that have number of seats in the list. If the array is NOT present all tables are returned regardless the number of seats.

    hideFull: boolean; // bool, if set to true, will not return tables considered full. if false do nothing. Default value is false.
    hideEmpty: boolean; // bool, if set to true will not return tables with no players. if false do nothing, Default value is false.
    showPrivate: boolean; // bool, if set to true will return tables that are password protected. if false will return table NOT passworded protected. Default value is false.
    freerollOnly: boolean; // bool, if set to true, will return tournament marked as free roll only. if false no filter is made.
    showOnlyWithTip: boolean; // bool, if set to true will return only table where you can tip the dealer.
}

export interface StateActivePreview {
    tableId?: number;
    tournamentId?: number;
}
export interface State {
    statsPlayerOnline: StatsPlayerOnline;
    activeNav?: LobbyFilterTableType;
    activePreview: StateActivePreview;
    activePreviewNav?: LobbyPreviewNav;
    filters: LobbyFilters;

    error?: string; // Example: Phone number must be verified 
}

export const initialState: State = {
    error: undefined,
    statsPlayerOnline: {
        total: 0,
        playingRingTable: 0,
        playingTournament: 0,
        idles: 0
    },
    activeNav: undefined,
    activePreview: {
        tableId: undefined,
        tournamentId: undefined
    },
    activePreviewNav: undefined,

    filters: {
        tableType: undefined,
        variants: LobbyFilterVariant.any,
        stakes: LobbyFilterStake.Any,
        limits: LobbyFilterLimit.Any,
        speeds: LobbyFilterSpeed.Any,
        nbSeats: LobbyFilterSeat.Any,
        hideFull: false,
        hideEmpty: false,
        showPrivate: false,
        freerollOnly: false,
        showOnlyWithTip: false
    }
};

export const reducer = createReducer(
    initialState,
    on(LobbyActions.updateStatsPlayerOnline, (state, { statsPlayerOnline }) => (
        {
            ...state,
            statsPlayerOnline
        }
    )),
    on(LobbyActions.updateActiveNav, (state, { activeNav }) => (
        {
            ...state,
            activeNav
        }
    )),
    on(LobbyActions.updateActivePreview, (state, { tableId, tournamentId }) => (
        {
            ...state,
            activePreview: {
                tableId,
                tournamentId
            }
        }
    )),
    on(LobbyActions.updateActivePreviewNav, (state, { activeNav }) => (
        {
            ...state,
            activePreviewNav: activeNav
        }
    )),
    on(LobbyActions.updateFiltersChange, (state, { filters }) => (
        {
            ...state,
            filters
        }
    )),
    on(LobbyActions.updateError, (state, { error }) => (
        {
            ...state,
            error
        }
    )),

    on(AuthActions.onLogout, (state) => (initialState)),

)