export enum ServerRequestMessage {
    Connect = 1,  // 🟡 Str1 = username, Str2 = password, Str3 = autologin token (if provided)
    Disconnect = 2, // no param
    // First message sent Text: name of the session and Value1: date installed,
    // Str1 = Name, Str2 = Skin/ReferralName, Str3 = Client Version, Str4 = OsVersion
    Version = 3,
    Ping = 4, // calculate the delay and server time

    MemberProfile = 5, // use in websocket message type

    UpdateAccount = 8, // use in websocket message type

    // Game xx
    TakeSeat = 10, // Value2=  Position
    ChangeStatus = 11, // Value2 = PlayerStatus
    BuyChips = 12, // Value2 = amount
    Bid = 13, // Value2 = amount
    Muck = 14,
    // agree to pay the BB on next hand just to be included, IdTable is required, Value = 0 will pay bb, otherwise will be canceled
    PayBBOnNextHand = 15,
    SetRunItTwice = 16, // player agree to run it twice
    Chat = 20, // Str1 = message
    TableSetWaiting = 21, // Value2: 1 = join, 0 = leave
    SitoutOnNextBb = 22, // IdTable required, Value 1: sitout, value 0: nothing stay playing
    PlayStatisticsReset = 23, // IdTable required

    TipUpgrade = 24, // upgrade dealer
    TipChange = 25, // change dealer

    ReturnCards = 27,

    AskRabbitHunting = 28,

    CallTimeAccepted = 30,
    CallTimeStatus = 31,

    CasinoInfo = 38, // 3rd part games integration

    BidStraddle = 45,

    // Lobby 1xx
    GetTables = 100, // 🟡 Value1 = moneyType, Value2: filters [various combined], Value3: filter by number of seats
    GetTableSummary = 101, // value1 = idTable  to rejoin a specific table
    // value1 = idTournament  request the tournament summary of a single tournament, will return a TournamentSummary message
    GetTournamentSummary = 102, // 🟣
    GetTableDetails = 103, // 🟡 IdTable = idTable to display player at the table in the lobby
    JoinTable = 104, // IdTable is required, Str1 contain the password if any
    LeaveTable = 105,
    PrivateTablePassword = 106, // Str1 = password
    //  The number of BB to automatically reload. Value2: reload trigger (0:disabled, 1:Min, 2:default, 3:max),
    // Value3 reload chip to (0:min, 1:default, 2:max)
    SetAutoReload = 110,
    SubscribeStatsPlayerOnline = 111, // view how many player are online
    MTTView = 120, // 🟣 view or not the tournament (receive update)
    MTTRegister = 121, // register or not to particiate in the tournament
    SetMTTAutoRebuy = 123, // set automatic rebuy of chips when out of money during tournaments, must be send to each new session
    SetMTTAutoAddOn = 124, // set automatic purchase of addon during tournaments break must be send to each new session
    // subscribe to receive real time stats in the lobby. Value = 1 to subscribe, 0 to unsubscribe.
    // Can be send right after the authentication
    SubscribeLiveLobby = 125, // 🟣


    // subscribe to receivel EXTRA real time information from a table such as waiting list and player chips.
    // IdTable is required, can be 0 to unsubscribe
    SubscribeLiveLobbySelected = 126,
    RequestBlindSchedule = 127, // 🟣

    GetMixTablesDetails = 128, // 🟡
    GetChatLobbyHistory = 129,

    EmoticonsGetList = 152, // Shop
    EmotikensGetPackages = 153, // Shop
    EmotikensBuyPackage = 154, // Shop
    EmoticonUnlock = 155,  // Shop

    CurrencyGetPackages = 160, // Shop
    CurrencyBuyPackage = 161, // Shop
    GetTickets = 162,// Shop
    BuyTicket = 163,// Shop

    ShopSettings = 164,

    NewGameLayout = 170,
    CurrencyPackagesResponse = 180,// Shop

    // Account 2xx
    AccountVerifyUsernameUnique = 200,
    AccountVerifyEmailUnique = 201,
    AccountRequestSecurityCode = 202, // the member request his security code because he forgot his password
    AccountChangePassword = 203,
    ImageUpload = 204, // Str1 is the base64 of the image, Value is the type of image (1,2,3,4...)
    AvatarUpload = 206,
    GetReferalCode = 210, // keep to avoid protobuf error
    CheckPromoCode = 210, // validate if the promo code is valid or not

    RequestPhoneCode = 211,
    SubmitPhoneCode = 212,

    SubmitGovermentId = 216,

    // Cashier 300
    RequestPlayMoney = 300, // temporary?


    GiftUnlock = 310, // user request to unlock a gift or part of a gift. Value = id of the Gift, Value2 = amount requested to be unlocked
    DepositNetTeller = 320,
    // Player initial a deposit for a specific gateway, Value1 = gateway, Value2 = amount, Value3 = currency.
    // A reply will be returned with the url to be navigated too
    DepositInitiate = 321,
    // Player request Value1= gateway, Value2 = amount, Currency must be specified.
    // A reply will be returned with a message WithdrawalRequestResponse an error
    WithdrawalRequest = 322,
    WithdrawalCancel = 323, // will cancel all pending withdrawal request.  Currency must be specified
    TDSTransferPreview = 330, // compute the fee of a TDS transfer, Value positive is transfer OUT of the TDS, negative into
    TDSTransfer = 331,  // do a TDS transfer Value positive is transfer OUT of the TDS, negative into
    RequestTransactionHistory = 332, // request the list of transaction (deposit and withdrawal)

    TransferMoney = 399, // transfer money to another user

    HandReplay = 400, // Value = HandNumber
    HandReplay2 = 401, // Value = HandNumber, return the json form and not in a big text format
    // Value = HandNumber, return previous and future hand around a specific handnumber.
    // If the value is 0, recent hands will be returned
    HandHistory = 402,
    HandStrength = 403,

    SetAutoMuck = 601, // set option to auto muck
    SetJumpToTable = 602, // Jumpt to table when it's your turn to play (Value = 1 or 0)
    SetPlayerNotes = 603, // Value = idPlayer, Str1 = note, the server will truncate it to 1KB max

    SetPlayerColor = 608,

    // From [int/currency] the currency of the exchange that represent the source.
    // To [int/currency] the currency of the exchange that represent the destination.
    // Requesting exchange rate for USD to Rupees, server return the ratio 50 between the 2.
    // Send: {"type":604,"Currency":"1","Value":"4"}
    ExchangeRate = 604,
    SelfExclude = 606,
    SetStraddle = 607,

    GetAuthToken = 900, // Casino onOpen
    InvalidateAuthToken = 901,
    PlayerAlignmentInitiate = 902
}