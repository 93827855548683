<div class="dialog-header">
    <div class="dialog-header-title">
        Show Cards? ({{timer}}s)
    </div>
</div>
<div class="dialog-footer">
    <div class="app-game-dialog-ask-show-cards-footer">
        <button class="app-game-dialog-ask-show-cards-footer-button-accept" appButton
            (click)="confirm()">Yes</button>


            <button class="app-game-dialog-ask-show-cards-footer-button-cancel secondary" appButton
            (click)="confirm()">No</button>
    </div>
</div>
