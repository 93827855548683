import { Component, EventEmitter, Input, OnInit, Output, Self, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, skip } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export type InputType = 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  host: { class: 'app-input' },
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputComponent,
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor {
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Input() icon: string = '';
  @Input() symbol: string = '';

  @Input() type: InputType = 'text';
  @Input({
    transform: (errors: { [index: string]: string | number | boolean } | null | undefined) => {
      return Object.keys(errors ?? {}).map(key => {
        return { key, value: errors?.[key] }
      })
    }
  }) errors: { key: string, value: string | number | boolean }[] = [];
  @Input() value: string | number | boolean = '';
  @Input() rounded = false;

  @Input() min: string | null = null;
  @Input() max: string | null = null;
  @Input() step: string | null = null;

  @Output() enter = new EventEmitter<void>();

  control = new FormControl<string | number | boolean>('');
  id = uuidv4();

  onChange = (_: any) => { };

  onTouch = (_: any) => { };

  writeValue(value: any): void {

    this.control.setValue(value, { onlySelf: true, emitEvent: false })
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {

    this.onTouch = fn
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({ emitEvent: false })
    }

    this.control.enable({ emitEvent: false })
  }

  constructor() {
    this.control.valueChanges.pipe()
      .pipe(
        takeUntilDestroyed()
      )
      .subscribe(value => {

        this.onChange(value)
        this.onTouch(value)
      })
  }

  onRadioClick() {
    this.control.setValue(this.value)
  }

  onEnter() {
    this.enter.emit();
  }
}
