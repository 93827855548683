import { Assets, Graphics, Text, Application, Container, Texture, Sprite, Loader, TextStyle, ITextStyle } from 'pixi.js';
import { CardData } from '../models/card-data';


export class Card {

    container = new Container();
    cardSprite: Sprite;
    info: CardData;

    constructor(
        textures: {
            cardTexture: Texture,
            rabbitHuntingCardCover: Texture,
        },
        data: {
            info: CardData,
        }
    ) {
        this.cardSprite = new Sprite(textures.cardTexture);
        this.container.addChild(this.cardSprite);

        if (data.info.isRabbitHunting) {
            const rabbitHuntingCardCover = new Sprite(textures.rabbitHuntingCardCover)
            this.container.addChild(rabbitHuntingCardCover)
        }
        this.info = data.info;
    }

    grayscaleCard() {
        this.cardSprite.tint = 0x666666
    }
    ungrayscaleCard() {
        this.cardSprite.tint = 0xFFFFFF
    }

    update(dt: number) {

    }
}   
