import { CasinoInfo } from '@app/models/casino-info';
import { DomainSettings } from '@app/models/domain-settings';
import { GlobalSettings } from '@app/models/global-settings';
import { JackPotInfo } from '@app/models/jackpot-info';
import { SkinSettings } from '@app/models/skin-settings';
import { createAction, props } from '@ngrx/store';

export const updateAppReadyToUse = createAction('[Config] updateAppReadyToUse', props<{ appReadyToUse: boolean }>());
export const updateGameInitialized = createAction('[Config] updateGameInitialized', props<{ gameInitialized: boolean }>());
export const updateSkinSettings = createAction('[Config] updateSkinSettings', props<{ skinSettings: SkinSettings }>());
export const updateGlobalSettings = createAction('[Config] updateGlobalSettings', props<{ globalSettings: GlobalSettings }>());
export const updateCasinosInfo = createAction('[Config] updateCasinosInfo', props<{ casinosInfo: CasinoInfo[] }>());
export const updateJackPotsInfo = createAction('[Config] updateJackPotsInfo', props<{ jackPotsInfo: JackPotInfo[] }>());

export const updateDomainSettings = createAction('[Config] updateDomainSettings', props<{ domainSettings: DomainSettings }>());
export const connectWebSocket = createAction('[Config] connectWebSocket', props<{ domainSettings: DomainSettings }>());


export const openCasino = createAction('[Config] openCasino', props<{ amount: number, casinoInfo: CasinoInfo }>());

export const onAssetsLoading = createAction('[Config] onAssetsLoading', props<{ percentage: number }>());

// Theme
export const themeSelectCard = createAction('[Config] themeSelectCard', props<{ id: number }>());
export const themeSelectBackCard = createAction('[Config] themeSelectBackCard', props<{ id: number }>());
export const themeSelectTable = createAction('[Config] themeSelectTable', props<{ id: number }>());
export const themeSelectCarpet = createAction('[Config] themeSelectCarpet', props<{ id: number }>());

export const updateAssetsThemeSelectCard = createAction('[Config] updateAssetsThemeSelectCard', props<{ id: number }>());
export const updateAssetsThemeSelectBackCard = createAction('[Config] updateAssetsThemeSelectBackCard', props<{ id: number }>());
export const updateAssetsThemeSelectTable = createAction('[Config] updateAssetsThemeSelectTable', props<{ id: number }>());
export const updateAssetsThemeSelectCarpet = createAction('[Config] updateAssetsThemeSelectCarpet', props<{ id: number }>());
