import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DialogBase } from '@app/components/dialog/dialog-base';
import { Legend } from '@app/features/lobby/consts/legend.constants';
import { ButtonComponent } from '@app/components/button/button.component';

@Component({
  selector: 'app-lobby-dialog-legends',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './lobby-dialog-legends.component.html',
  styleUrls: ['./lobby-dialog-legends.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'dialog app-lobby-dialog-legends' }
})
export class LobbyDialogLegendsComponent extends DialogBase {

  legend: any[] = Object.keys(Legend).map((legendKey) => Legend[(legendKey as keyof typeof Legend)])
}
