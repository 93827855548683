import { ExclusionPeriod } from '@app/features/main/presenters/main-dialog-self-exculsion/main-dialog-self-exculsion.component';
import { CurrencyInfo } from '@app/models/currency-info';
import { GlobalSettings } from '@app/models/global-settings';
import { MemberPreferences, MemberProfile } from '@app/models/member-profile';
import { PlayerBalance } from '@app/models/player-balance';
import { PlayerLevelStatus } from '@app/models/player-level-status';
import { SkinSettings } from '@app/models/skin-settings';
import { createAction, props } from '@ngrx/store';

export const updateProfile = createAction('[User] updateProfile', props<{ profile: MemberProfile }>());
export const updateSelectedCurrency = createAction('[User] updateSelectedCurrency', props<{ currency: CurrencyInfo }>());
export const updatePlayerBalance = createAction('[User] updatePlayerBalance', props<{ playerBalance: PlayerBalance[] }>());
export const updateMemberPreferences = createAction('[User] updateMemberPreferences', props<{ preferences: MemberPreferences }>());


export const changePassword = createAction('[User] changePassword', props<{ password: string }>());

// in app
export const checkEmailExist = createAction('[User] checkEmailExist', props<{ email: string }>());
export const updateEmailExist = createAction('[User] updateEmailExist', props<{ email: string, exist: boolean }>());

export const checkUsernameExist = createAction('[User] checkUsernameExist', props<{ username: string, }>());
export const updateUsernameExist = createAction('[User] updateUsernameExist', props<{ username: string, exist: boolean }>());

export const updateAccount = createAction('[User] updateAccount', props<{ memberProfile: Partial<MemberProfile> }>());
export const changeProfilePicture = createAction('[User] changeProfilePicture', props<{ imageBase64: string }>());
export const selfExclude = createAction('[User] selfExclude', props<{ period: ExclusionPeriod, password: string }>());

export const neverRabbitHunt = createAction('[User] neverRabbitHunt', props<{ turnOn: boolean }>());
export const autoMuck = createAction('[User] autoMuck', props<{ turnOn: boolean }>());
export const muteSound = createAction('[User] muteSound', props<{ turnOff: boolean }>());
export const jumpToTable = createAction('[User] jumpToTable', props<{ turnOn: boolean }>());
