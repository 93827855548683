
import { Injectable, inject } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';

import * as CurrenciesActions from './currencies.actions';
import { WsService } from '@app/services/ws.service';
import { TableSummary, TableSummaryDTO } from '@app/models/table-summary';
import { LiveLobbyRing } from '@app/models/live-lobby-ring';
import { CurrencyService } from '@app/services/currency.service';
import { CurrencyInfoDTO } from '@app/models/currency-info';
import * as LobbyActions from '@app/store/features/lobby/lobby.actions';
import * as ConfigSelectors from '@app/store/features/config/config.selectors';
import { fi } from 'date-fns/locale';

@Injectable()
export class CurrenciesEffects {
    private readonly _ws = inject(WsService);
    private readonly _actions$ = inject(Actions);
    private readonly _store = inject(Store);
    private readonly _currencyService = inject(CurrencyService);

    // 


    currenciesInfo$ = createEffect(() => this._ws.getDataResponse<CurrencyInfoDTO[]>(ServerResponse.CurrenciesInfo)
        .pipe(
            switchMap(currenciesInfoDTO => {
                return this._store.pipe(
                    select(ConfigSelectors.getGlobalSettings),
                    filter(globalSettings => !!globalSettings),
                    map(globalSettings => {
                        const currencies = currenciesInfoDTO.map(currencyInfoDTO => this._currencyService.updateCurrencyInfo(currencyInfoDTO, globalSettings!))
                        return CurrenciesActions.setAll({ currencies })
                    })
                )
            }),

        ));

    changeCurrency$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CurrenciesActions.selectCurrency),
                map(({ selected }) => LobbyActions.onChangeCurrency({ currency: selected }))
            ))

} 