import { Assets, Graphics, Text, Application, Container, Texture, Sprite, Loader, TextStyle, ITextStyle } from 'pixi.js';
import { CardData } from '../models/card-data';
import { DragAndDrop } from '@app/helpers/drag-drop';
import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';

export enum CardStatus {
    NO_ANIMATION = 0,
    SELECTED_ANIMATION = 1,
    UNSELECTED_ANIMATION = 2
}
export class Card {
    status: CardStatus = CardStatus.NO_ANIMATION;
    container = new Container();
    cardSprite: Sprite;
    info: CardData;


    private isExpanded: boolean = false;
    selected: boolean = false;

    onCardSelected$ = new Subject<{ info: CardData, selected: boolean }>();
    cardSelected$: Observable<{ info: CardData, selected: boolean }> = this.onCardSelected$.asObservable();
    private position: { x: number, y: number } = { x: 0, y: 0 };

    isRotated: boolean = false;

    constructor(
        textures: {
            cardTexture: Texture,
            rabbitHuntingCardCover: Texture,
        },
        data: {
            info: CardData,
            isRotated?: boolean
        }
    ) {
        this.isRotated = !!data.isRotated;
        this.cardSprite = new Sprite(textures.cardTexture);
        this.container.addChild(this.cardSprite);

        if (data.info.isRabbitHunting) {
            const rabbitHuntingCardCover = new Sprite(textures.rabbitHuntingCardCover)
            this.container.addChild(rabbitHuntingCardCover)
        }
        this.info = data.info;

        this.cardSprite.cursor = 'default';
        this.cardSprite.eventMode = 'none';
        this.cardSprite.on('pointerdown', (event) => {
            this.selectCard();
        })


        // 🛠️ DEV TOOL
        if (environment.devMode) {
            new DragAndDrop(this.container);
        }


    }

    updateIsExpanded(isExpanded: boolean) {
        this.isExpanded = isExpanded
        if (!this.isExpanded && this.selected) {
            this.container.position.set(this.position.x, this.position.y - this.container.height * 0.2);
        }
    }

    markAsSelected() {
        this.selected = true;
        this.container.position.set(this.position.x, this.position.y - this.container.height * 0.2);
        this.cardSprite.tint = 0x999999
    }

    setPosition(x: number, y: number) {
        this.position = { x, y };
        this.container.position.set(x, y);
    }

    grayscaleCard() {
        this.cardSprite.tint = 0x666666
    }
    ungrayscaleCard() {
        this.cardSprite.tint = 0xFFFFFF
    }

    update(dt: number) {
        if (this.status === CardStatus.SELECTED_ANIMATION) {

            const targetY = this.position.y - this.container.height * 0.2;
            const newY = this.container.position.y - 3
            if (newY <= targetY) {
                this.status = CardStatus.NO_ANIMATION;
                this.container.position.set(this.position.x, targetY);

                return;
            } else {
                this.container.position.set(this.position.x, newY);
            }

        } else if (this.status === CardStatus.UNSELECTED_ANIMATION) {

            const targetY = this.position.y;
            const newY = this.container.position.y + 3

            if (newY >= targetY) {
                this.status = CardStatus.NO_ANIMATION;
                this.container.position.set(this.position.x, targetY);
                return;
            } else {
                this.container.position.set(this.position.x, newY);
            }

        }

    }

    setCardSelectionStatus(isSelectable: boolean) {

        if (isSelectable) {
            this.cardSprite.cursor = 'pointer';
            this.cardSprite.eventMode = 'static';
            return;
        }

        this.cardSprite.cursor = 'default';
        this.cardSprite.eventMode = 'none';
    }

    private selectCard() {
        if (this.status !== CardStatus.NO_ANIMATION) {
            return
        }
        if (this.selected) {
            this.selected = false;
            this.cardSprite.tint = 0xFFFFFF

            /**
             * @description
             * Expand the card is for vertical and mobile view
             */
            if (!this.isExpanded) {
                this.status = CardStatus.UNSELECTED_ANIMATION;
            }

        } else {

            this.selected = true;
            this.cardSprite.tint = 0x999999

            if (!this.isExpanded) {
                this.status = CardStatus.SELECTED_ANIMATION;

            }
        }

        this.onCardSelected$.next({ info: this.info, selected: this.selected });
    }
}   
