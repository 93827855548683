import { Assets, Graphics, Text, Application, Container, Texture, Sprite, Loader, TextStyle, ITextStyle } from 'pixi.js';
import { Point } from '../models/point';
import { CardData } from '../models/card-data';
import { Card } from './card';
import { Observable, Subject, combineLatest, map, merge, of, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GameCardSorting } from '@app/store/features/games/games.reducer';
import { CardSuits } from '../helpers/card-decoder';
import { sortCards } from '../helpers/sort-card';




export class PlayerCardsController {
    readonly playerZoneWidth = 355
    readonly playerZoneHeight = 295
    readonly playerAvatarCircleSize = 150
    readonly infoBoxWidth = 260
    readonly inRowVisibleCardWidth = 86

    container = new Container();


    cards: Card[] = [];
    expandedCardsBackground: Graphics = new Graphics();

    cards$ = new Subject<Card[]>();
    cardsSelected$: Observable<{ cards: CardData[], indexes: number[] }>;

    isSelectable: boolean = false;
    isExpanded: boolean = false;

    constructor() {
        this.expandedCardsBackground = new Graphics();
        this.expandedCardsBackground.beginFill(0x000000, 0.9);
        this.expandedCardsBackground.drawRect(0, 0, 0, 0);
        this.expandedCardsBackground.endFill();
        this.container.addChild(this.expandedCardsBackground);

        this.cardsSelected$ = this.cards$.pipe(
            switchMap(cards =>
                combineLatest([
                    of(cards),
                    merge(...cards.map(card => card.cardSelected$))
                ])
            ),
            map(([cards, data]) => {
                const selectedCards = cards.filter(card => card.selected).map(card => card.info!).map(card => {
                    delete card.textures; // remove textures from card info because of RxJS circular structure error
                    return card;
                });
                const indexes = selectedCards.map(card => cards.findIndex(c => c.info.name === card.name));
                return { cards: selectedCards, indexes }
            })
        )
        if (false) {
            setTimeout(() => {
                this.addCards([
                    // #1
                    { suit: 0, number: 9, name: 'c2', isHidden: false, isRabbitHunting: undefined, textures: { cardTexture: Assets.get(`playerCardAtlas-0`).textures[`style_id_0_c2.png`], rabbitHuntingCardCover: {} as any } },
                    { suit: 0, number: 9, name: 'c3', isHidden: false, isRabbitHunting: undefined, textures: { cardTexture: Assets.get(`playerCardAtlas-0`).textures[`style_id_0_c3.png`], rabbitHuntingCardCover: {} as any } },
                    { suit: 0, number: 9, name: 'c4', isHidden: false, isRabbitHunting: undefined, textures: { cardTexture: Assets.get(`playerCardAtlas-0`).textures[`style_id_0_c4.png`], rabbitHuntingCardCover: {} as any } },
                    { suit: 0, number: 9, name: 'c5', isHidden: false, isRabbitHunting: undefined, textures: { cardTexture: Assets.get(`playerCardAtlas-0`).textures[`style_id_0_c5.png`], rabbitHuntingCardCover: {} as any } },
                    { suit: 0, number: 9, name: 'c6', isHidden: false, isRabbitHunting: undefined, textures: { cardTexture: Assets.get(`playerCardAtlas-0`).textures[`style_id_0_c6.png`], rabbitHuntingCardCover: {} as any } },

                    //  { suit: 0, number: 9, name: 'h9', isHidden: false, isPublic:true, isRabbitHunting: undefined, textures: { cardTexture: Assets.get(`playerCardAtlas-0`).textures[`style_id_0_c2.png`], rabbitHuntingCardCover: {} as any } },

                    // #2
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h9.png'] },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h8.png'] },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h7.png'] },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h6.png'] },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h5.png'] },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h4.png'] },
                    // // { suit: 0, number: 9, name: 'h9', isHidden: false, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: false, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },

                    // #3
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },

                ], GameCardSorting.Default, false)

                setTimeout(() => {
                    this.expandCards()

                    setTimeout(() => {
                        //   this.collapseCards()
                    }, 5000);
                }, 3000);
            }, 1000);


        }
    }



    update(dt: number) {
        this.cards.forEach(card => {
            card.update(dt);
        });
    }

    addCards(cards: CardData[], cardSorting: GameCardSorting, isRotated: boolean = false) {
        sortCards(cards, cardSorting)
            .forEach((cardData, index) => {
                const cardIndex = isRotated && cardData.isHidden ? 0 : this.container.children.length;
                let card = new Card(cardData.textures!, { info: cardData, isRotated });
                card = this.setCard(card, index, isRotated, !!cardData.isPublic, cards)

                card.setCardSelectionStatus(this.isSelectable)
                this.cards.push(card);
                this.container.addChildAt(card.container, cardIndex);
            });


        this.cards$.next(this.cards)
    }

    updateCardSorting(cardSorting: GameCardSorting) {

        this.container.removeChildren();
        this.expandedCardsBackground.clear();
        this.container.addChild(this.expandedCardsBackground);

        const cards = this.cards.map(card => card.info!);
        sortCards(cards, cardSorting)
            .forEach((cardData, index) => {
                let card = this.cards.find(c => c.info.name === cardData.name);
                const cardIndex = card!.isRotated && cardData.isHidden ? 0 : this.container.children.length;

                card = this.setCard(card!, index, card!.isRotated, !!card?.info.isPublic, cards)
                this.container.addChildAt(card.container, cardIndex);

            })
    }



    /**
     * @description
     * Expand and collapse cards used for Draw games on mobile devices and vertical table rotation
     */

    expandCollapseCards() {
        if (!this.isExpanded) {
            this.expandCards()
        } else {
            this.collapseCards()
        }
        this.isExpanded = !this.isExpanded;
    }

    expandCards() {
        if (this.isExpanded) {
            return
        }
        let cardsWidth = 0;
        let cardsHeight = 0;
        let cardsX = 0;
        let cardsY = 0;
        this.cards.forEach((card, index) => {
            card.container.scale.set((this.inRowVisibleCardWidth * 1.25) / card.cardSprite.texture.width)
            const offset = 5;
            const x = this.playerZoneWidth / 2 - ((this.cards.length / 2 - index) * (card.container.width + offset))
            const y = -20
            card.setPosition(x, y)
            card.updateIsExpanded(true)
            if (index !== this.cards.length - 1) {
                cardsWidth += card.container.width + offset
            } else {
                cardsWidth += card.container.width

            }
            if (index === 0) {
                cardsHeight = card.container.height
                cardsX = x;
                cardsY = y;
            }
        })

        this.expandedCardsBackground.clear();
        this.expandedCardsBackground.beginFill(0x000000, 0.5);
        this.expandedCardsBackground.drawRoundedRect(cardsX - 10, cardsY - 10, cardsWidth + 20, cardsHeight + 20, 25);
        this.expandedCardsBackground.endFill();
        this.setCardsSelectionStatus(true)

    }

    collapseCards() {
        if (!this.isExpanded) {
            return
        }
        this.cards.forEach((card, index) => {
            const cardsPosition: Point = this.cardPosition(index, false, this.cards.length);
            card.setPosition(cardsPosition.x, cardsPosition.y)
            card.container.scale.set(this.inRowVisibleCardWidth / card.cardSprite.texture.width)
            card.updateIsExpanded(false)
        })
        this.expandedCardsBackground.clear();
        this.setCardsSelectionStatus(false)
    }

    setCardsSelectionStatus(isSelectable: boolean) {
        this.isSelectable = isSelectable;
        this.cards.forEach(card => {
            card.setCardSelectionStatus(isSelectable)
        })
    }

    /**
     * @description
     * Draw games on Restore cards selection
     */
    markCardsAsSelected(cards: CardData[]) {
        cards.forEach(card => {
            const selectedCard = this.cards.find(c => c.info.name === card.name);
            if (selectedCard) {
                selectedCard.markAsSelected()
            }
        })
    }

    setCard(card: Card, index: number, rotateCard: boolean, isPublic: boolean, cards: CardData[]) {
        if (!rotateCard) {
            const cardsPosition: Point = this.cardPosition(index, isPublic, cards.length);

            card.setPosition(cardsPosition.x, cardsPosition.y)
            card.container.scale.set(this.inRowVisibleCardWidth / card.cardSprite.texture.width)

            return card
        }



        const setX = this.playerZoneWidth / 2 - this.infoBoxWidth / 2 - this.playerZoneWidth * 0.02
        const setY = this.playerAvatarCircleSize / 2 + this.playerZoneHeight * 0.07

        switch (index) {
            case 0:
                card.setPosition(setX, setY);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.8);
                break;
            case 1:
                card.setPosition(setX - 20, setY + 20);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.75);

                break;

            case 2:
                card.setPosition(setX - 35, setY + 45);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.7);

                break;

            case 3:
                card.setPosition(setX - 40, setY + 75);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.65);
                break;

            case 4:
                card.setPosition(setX - 40, setY + 105);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.6);

                break;

            case 5:
                card.setPosition(setX - 30, setY + 135);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.55);
                break;

            case 6:
                card.setPosition(setX - 15, setY + 160);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.5);
                break;
        }


        return card
    }



    private cardPosition(cardIndex: number, isPublic: boolean, numberOfCards: number): Point {


        let offset: number = 0;
        let cardsWidth;

        while (!cardsWidth || cardsWidth > this.playerZoneWidth) {
            if (offset > 0) {
                offset -= 0.01
            } else {
                offset = 0.5 // default offset
            }

            cardsWidth = this.inRowVisibleCardWidth + (numberOfCards - 1) * (this.inRowVisibleCardWidth * (offset))
        }


        return { x: this.inRowVisibleCardWidth * offset * cardIndex + (this.playerZoneWidth - cardsWidth) / 2, y: this.playerAvatarCircleSize / 2 + (isPublic ? -20 : 0) } // isPublic : 7Stud

    }

    removeAllCards() {
        this.container.removeChildren();
        this.cards = [];
        this.expandedCardsBackground.clear();
        this.container.addChild(this.expandedCardsBackground);
    }


    /**
     * @description
     * Winner cards selection
     */
    selectCards(cardsToSelect: CardData[] = []) {

        if (cardsToSelect.length === 0) { return }

        this.cards.forEach(card => {
            if (!card || !card.info) { return; }
            if (cardsToSelect.findIndex(selectCard => selectCard.suit === card.info.suit && selectCard.number === card.info.number) === -1) {
                card.grayscaleCard();
            } else {
                card.ungrayscaleCard();
            }
        });
    }

    grayscaleCards() {
        this.cards.forEach(card => {
            card.grayscaleCard()
        })
    }

}
