import { Assets, Graphics, Text, Application, Container, Texture, Sprite, Loader, TextStyle, ITextStyle, ColorMatrixFilter } from 'pixi.js';
import { Point } from '../models/point';
import { PlayerCardsController } from './player-cards-controller';
import { CardData } from '../models/card-data';
import { VariantType2 } from '@app/enums/variant-type-2';
import { PlayerTimebankProgressBar } from './player-timebank-progress-bar';
import { PlayerStatus } from '../enums/player-status.enum';
import { CurrencyInfo } from '@app/models/currency-info';
import { GameCurrencyPipe } from '@app/pipes/game-currency.pipe';
import { GameCardSorting, GameStatus } from '@app/store/features/games/games.reducer';
import { ChatBubble } from './chat-bubble';
import * as GamesActions from '@app/store/features/games/games.actions';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { NoteColors } from '../enums/note-colors';
import { MemberPreferences } from '@app/models/member-profile';
import { CallTime } from './call-time';
import { PlayerCallTime } from '@app/models/player-call-time';
import { Card } from './card';
import { DragAndDrop } from '@app/helpers/drag-drop';
import { environment } from '@environments/environment';
import { CardReplaceInfo } from './card-replace-info';

export enum PlayerGameStatus {
    default = 'default',
    active = 'active',
    fold = 'fold',
    sitout = 'sitout',
    disconnected = 'disconnected',
    seating = 'seating'
}



export class Player {
    private _store: Store;

    container = new Container();

    playerZoneWidth = 355
    playerZoneHeight = 295
    playerAvatarCircleSize = 150

    avatarOverlaySprite: Sprite;
    avatarOverlayBlinkSprite: Sprite;
    avatarPictureSprite: Sprite;

    infoBoxSprite: Sprite;
    infoBoxBlinkSprite: Sprite;

    tableId: number;
    playerId: number;
    playerName: Text;
    playerMoney: {
        amount: Text,
        currency: Text,
        decimal: Text,
        value: number
    }
    playerNote: Sprite | undefined;

    defaultFontStyle: Partial<ITextStyle> = {
        fontFamily: 'Saira Semi Condensed',
        fontSize: 40,
        fontWeight: '500',
        fill: '#ffffff',
    }

    straddleSprite: Sprite;
    dealerSprite: Sprite;
    proPlayerSprite: Sprite;

    timebank: {
        status: Text, // Timebank: 10
        timer: Text, // 6,5,4... countdown
        bar: PlayerTimebankProgressBar; // progress bar
        data: {
            timer: number,
            bankTime: number,
            normalTime: number,
            normalTimeExpired: boolean;
        }
    }

    colorMatrix: ColorMatrixFilter;
    playerCardsController: PlayerCardsController;
    cardReplaceInfo: CardReplaceInfo;

    variant: VariantType2;
    alreadyBetInThisRound: number = 0;
    isMyTurn: boolean = false;
    status: PlayerStatus = PlayerStatus.Ready;
    hasFold: boolean = false;
    numberOfCardsHolding: number = 0;
    memberPreferences: MemberPreferences

    playerZone: Graphics;
    /**
     * Player Class Layers Indexes:
     * ===============================
     * Player Zone - 0
     * Avatar - 1
     * Info Box - 2
     * Dealer - 3
     * Straddle - 4
     * Timebank - 5
     *   
    */

    textures: {
        infoBox: {
            default: Texture,
            active: Texture,
            disconnected: Texture,
            fold: Texture,
            sitout: Texture
        },
        avatar: Texture,
        avatarOverlay: {
            default: Texture,
            active: Texture,
            disconnected: Texture,
            fold: Texture,
            sitout: Texture
        },
        playerStraddle: Texture,
        playerDealer: Texture,
        playerProMark: Texture,

        // playerTimebank: { // not used in v4
        //     barBackground: Texture,
        //     barCap: Texture,
        //     barMiddle: Texture,
        //     barGlow: Texture
        // },
        playerNote: {
            [key: string]: Texture
        }
        chatBubble: Texture,
    }

    isPlayerTurn: boolean = false;
    isMyPlayer: boolean = false;
    showAmountInBigBlinds = false;
    currency: CurrencyInfo;


    name: string;
    playerNameMaxLength: number;


    seatNumber: number;
    fakeSeatNumber?: number;



    private blinkAlpha = 0;
    private blinkSpeed = 0.8; // lower is slower, higher is faster
    private blinkDirection = 1;
    private shouldRevertToDefaultStatus = false;
    private revertTimer = 0;

    private callTime: CallTime;
    private cardSorting: GameCardSorting;

    private capMoney = 0;
    private capMoneyText = new Text('', { ...this.defaultFontStyle, fontSize: 24 });
    private isCapGame = false;

    constructor(
        store: Store,
        textures: {
            infoBox: {
                default: Texture,
                active: Texture,
                disconnected: Texture,
                fold: Texture,
                sitout: Texture
            },
            avatar: Texture,
            avatarOverlay: {
                default: Texture,
                active: Texture,
                disconnected: Texture,
                fold: Texture,
                sitout: Texture
            },
            playerStraddle: Texture,
            playerDealer: Texture,
            playerProMark: Texture,
            // playerTimebank: { // not used in v4
            //     barBackground: Texture,
            //     barCap: Texture,
            //     barMiddle: Texture,
            //     barGlow: Texture
            // },
            playerNote: {
                [key: string]: Texture
            }
            chatBubble: Texture,
            callTime: {
                callTimeAttention: Texture;
                callTimeCheck: Texture;
                callTimeBackground: Texture;
            }
        },
        position: Point,
        data: {
            tableId: number,
            playerId: number,
            playerName: string,
            avatarUrl?: string
            currency: CurrencyInfo,
            balance: number, // Player Current Balance
            status: PlayerStatus,
            isProPlayer: boolean,
            timebank: {
                normalTime: number
            }
            gamePositions: {
                playerNameMaxLength: number,
            },
            isMyPlayer: boolean,
            seatNumber: number,
            cards: CardData[],
            memberPreferences: MemberPreferences,
            playerCallTime?: PlayerCallTime,
            variant: VariantType2,
            postedStraddle: boolean,
            cardSorting: GameCardSorting
            capMoney: number,
            isCapGame: boolean
        }

    ) {

        this._store = store;
        this.currency = data.currency;
        this.textures = textures;
        this.tableId = data.tableId;
        this.playerId = data.playerId
        this.name = data.playerName
        this.playerNameMaxLength = data.gamePositions.playerNameMaxLength;
        this.isMyPlayer = data.isMyPlayer;
        this.seatNumber = data.seatNumber;
        this.memberPreferences = data.memberPreferences;
        this.variant = data.variant;
        this.cardSorting = data.cardSorting;
        this.capMoney = data.capMoney;
        this.isCapGame = data.isCapGame;

        this.createPlayer(textures, data.isProPlayer, data.playerName, data.gamePositions.playerNameMaxLength, data.timebank, data.avatarUrl) // extract to method createPlayer mainly because of async

        if (this.memberPreferences.playerColor[this.playerId]) {
            this._setPlayerNote(this.memberPreferences.playerColor[this.playerId])
        }
        if (data.postedStraddle) {
            this.straddleSprite.visible = true;
        }

        this.callTime = new CallTime(textures.callTime, { playerCallTime: data.playerCallTime })

        this.callTime.container.position.set(this.playerZoneWidth / 2 - this.callTime.width / 2, 0);

        this.container.addChild(this.callTime.container);

        this.updateBalance(data.balance)
        this.updateStatus(data.status)



        if (this.variant)

            if (data.cards.length > 0) {
                this.receiveCards(data.cards);
            }

        this.container.position.set(position.x, position.y);

        this.container.name = `Player #${this.playerId} ${this.name}`;



    }

    updateCardSorting(cardSorting: GameCardSorting) {
        this.cardSorting = cardSorting;
        /**
         * @description We cannot sort hidden cards
         */
        if (this.isMyPlayer) {
            this.playerCardsController.updateCardSorting(cardSorting)
        }
    }

    updateVariant(variant: VariantType2) {
        this.variant = variant;
    }

    updateDataPlayerCallTime(playerCallTime?: PlayerCallTime) {
        this.callTime.updateDataPlayerCallTime(playerCallTime)
    }
    // 6'Clock
    changeSeatPosition(position: Point, seatNumber: number) {
        if (this.seatNumber !== seatNumber) {
            this.fakeSeatNumber = seatNumber
        } else {
            this.fakeSeatNumber = undefined;
        }
        this.container.position.set(position.x, position.y);
    }

    updateStatus(status: PlayerStatus) {
        if (status === PlayerStatus.Sitout) {
            this.setStatus(PlayerGameStatus.sitout);
        } else if (status === PlayerStatus.LeaveSeat) {
            this.setStatus(PlayerGameStatus.sitout);
        } else if (status === PlayerStatus.LeftNextHand) {
            this.setStatus(PlayerGameStatus.sitout);
        } else if (status === PlayerStatus.Ready) {
            this.setStatus(PlayerGameStatus.default);
        } else if (status === PlayerStatus.Disconnected) {
            this.setStatus(PlayerGameStatus.disconnected);
        }
    }

    update(dt: number) {

        // ⏺ CALL TIME 
        // this.timebank.data.timer += dt;
        // this.timebank.bar.update(1 - (this.timebank.data.timer / this.timebank.data.bankTime));
        // this.timebank.status.text = Math.round((this.timebank.data.bankTime - this.timebank.data.timer) / 1000).toString();


        // ------


        if (this.isMyTurn) {

            this.timebank.data.timer += dt;
            if (!this.timebank.data.normalTimeExpired) {
                if (this.timebank.data.timer <= this.timebank.data.normalTime) {
                    this.timebank.bar.update(1 - (this.timebank.data.timer / this.timebank.data.normalTime));
                } else {
                    this.timebank.bar.update(0);
                }
            } else {

                if (this.timebank.data.timer <= this.timebank.data.bankTime) {
                    this.timebank.bar.update(1 - (this.timebank.data.timer / this.timebank.data.bankTime));
                    this.timebank.timer.text = Math.round((this.timebank.data.bankTime - this.timebank.data.timer) / 1000).toString();
                } else {
                    this.timebank.bar.update(0);
                }
            }
            if (this.blinkAlpha <= 0) {
                this.blinkDirection = 1
                this.blinkAlpha = 0
            } else if (this.blinkAlpha >= 1) {
                this.blinkDirection = -1
                this.blinkAlpha = 1
            }

            if (this.blinkDirection === 1) {
                this.blinkAlpha += (dt / 1000) * this.blinkSpeed;
            } else {
                this.blinkAlpha -= (dt / 1000) * this.blinkSpeed;
            }

            this.infoBoxBlinkSprite.alpha = this.blinkAlpha;
            this.avatarOverlayBlinkSprite.alpha = this.blinkAlpha;
        }

        if (this.shouldRevertToDefaultStatus) {
            this.revertTimer += dt;
            if (this.revertTimer >= 2000) {
                this.setStatusText(this.name);
                this.shouldRevertToDefaultStatus = false;
                this.revertTimer = 0;
            }
        }

        // update all cards
        this.playerCardsController.update(dt);


        // ⏺
        // if (this.cardsControllerAdded && !this.showBigCardsActive) {
        //     this.updateCardControllIndex();
        // }

        this.callTime.update(dt);
    }

    async createPlayer(

        textures: {
            infoBox: { default: Texture, active: Texture },
            avatar: Texture,
            avatarOverlay: { default: Texture, active: Texture }
            playerStraddle: Texture,
            playerDealer: Texture,
            playerProMark: Texture,
        },
        isProPlayer: boolean,
        playerName: string,
        playerNameMaxLength: number,
        timebank: {
            normalTime: number
        },
        avatarUrl?: string,
    ) {

        this.colorMatrix = new ColorMatrixFilter();
        this.colorMatrix.greyscale(0.4, false);

        this._createPlayerZone();
        this._createAvatar({ avatar: textures.avatar, avatarOverlay: textures.avatarOverlay }, { avatarUrl });


        this.playerCardsController = new PlayerCardsController()
        this.container.addChild(this.playerCardsController.container);

        this._createInfoBox(textures.infoBox)

        this._createInfoBoxText(playerName, playerNameMaxLength);

        this._createDealer(textures.playerDealer);
        this._createStraddle(textures.playerStraddle);

        this._createProPlayer(textures.playerProMark, isProPlayer);

        this._createTimebank(timebank)

        // this.avatarOverlaySprite.cursor = 'pointer';
        // this.avatarOverlaySprite.eventMode = 'static';
        // this.avatarOverlaySprite.on('pointerdown', (event) => {

        // })

        if (!this.isMyPlayer) {
            this.infoBoxSprite.cursor = 'pointer';
            this.infoBoxSprite.eventMode = 'static' // ℹ️ Emit events and is hit tested. Same as interaction = true in v7, useful for objects like buttons that do not move. https://pixijs.com/8.x/guides/components/interaction
        }


        this.infoBoxSprite.on('pointerdown', (event) => { // ℹ️ pointerdown Fired when a pointer device button is pressed on the display object.
            if (this.isMyPlayer) {
                this.playerCardsController.expandCollapseCards()
            } else {
                this._onOpenUserInfo()
            }

        })


        this.cardReplaceInfo = new CardReplaceInfo();
        this.container.addChild(this.cardReplaceInfo.container);


        this.updateCAPMoney(this.capMoney)
        this.container.addChild(this.capMoneyText);

        if (this.isMyPlayer) {
            this.playerCardsController.cardsSelected$.subscribe((data) => {
                this._store.dispatch(GamesActions.onSelectedCards({ idTable: this.tableId, cards: data.cards, indexes: data.indexes }))
            })

        }


        // TODO: 📖 add player Cards Controller

        // TODO: 📖 add TimeBank Progress Bar class  


        // test:
        // this.infoBoxSprite.filters = [this.colorMatrix];
        // this.avatarPictureSprite.filters = [this.colorMatrix];

        // 🛠️ DEV TOOL
        if (environment.devMode) {
            new DragAndDrop(this.container);
        }
    }

    /**
   * @description
   * Draw games on Restore cards selection
   */
    markCardsAsSelected(cards: CardData[]) {
        this.playerCardsController.markCardsAsSelected(cards)
    }

    setPlayerCardsSelectionStatus(isSelectable: boolean, portraitMode: boolean) {
        if (portraitMode) {
            if (isSelectable) {
                this.infoBoxSprite.cursor = 'pointer';
                this.infoBoxSprite.eventMode = 'static'
            } else {
                this.infoBoxSprite.cursor = 'default';
                this.infoBoxSprite.eventMode = 'none';
                this.playerCardsController.collapseCards()
            }
        } else {
            this.playerCardsController.collapseCards()
            this.playerCardsController.setCardsSelectionStatus(isSelectable);
        }
    }

    setPlayerReplacedCardsNumber(numOfReplacedCards: number) {
        this.cardReplaceInfo.setReplacedCardsNumber(numOfReplacedCards)
        this.cardReplaceInfo.container.position.set(this.playerZoneWidth / 2 - this.cardReplaceInfo.container.width / 2, this.playerZoneHeight - this.cardReplaceInfo.container.height - 2);
    }

    setPlayerAllReplacedCardsNumbersInactive() {
        this.cardReplaceInfo.setAllNumbersInactive()
    }

    removePlayerReplacedCardsNumber() {
        this.cardReplaceInfo.removeReplacedCardsNumber()
    }

    _createPlayerZone() {
        // ## Player zone
        this.playerZone = new Graphics();
        this.playerZone.beginFill(0x0025FF, 0.5);
        this.playerZone.drawRoundedRect(
            0, // x
            0, // y
            this.playerZoneWidth, // Width
            this.playerZoneHeight, // Height
            0 // Radius
        );
        this.playerZone.endFill()
        this.playerZone.alpha = 0 // in debug mode it can be 1, move to enviroment variable
        this.container.addChild(this.playerZone)
    }

    _createAvatar(textures: {
        avatar: Texture,
        avatarOverlay: { default: Texture, active: Texture }

    }, data: { avatarUrl?: string }) {



        const offsetTop = this.playerZoneHeight * 0.1
        // ## Avatar Circle Mask
        const circleWidth = this.playerAvatarCircleSize
        const avatarMask = new Graphics();
        avatarMask.beginFill(0x00FF00, 1);
        avatarMask.lineStyle(0);
        avatarMask.drawCircle(this.playerZoneWidth / 2, circleWidth / 2 + offsetTop, circleWidth / 2); // x and y center of the circle
        avatarMask.endFill();
        this.container.addChild(avatarMask);
        avatarMask.isMask = true;

        // ## Avatar Circle Background - Default theme Black Color behind avatar if not loaded
        const avatarBorder = new Graphics();
        avatarBorder.beginFill(0x000000, 1);
        avatarBorder.lineStyle(0);
        avatarBorder.drawCircle(this.playerZoneWidth / 2, circleWidth / 2 + offsetTop, circleWidth / 2); // x and y center of the circle
        avatarBorder.endFill();
        this.container.addChild(avatarBorder);

        // ## Avatar Picture Sprite Image
        this.avatarPictureSprite = new Sprite(textures.avatar);
        this.avatarPictureSprite.anchor.set(0.5);
        this.avatarPictureSprite.position.set(this.playerZoneWidth / 2, circleWidth / 2 + offsetTop)
        this.avatarPictureSprite.mask = avatarMask;
        this.avatarPictureSprite.scale.set(circleWidth / this.avatarPictureSprite.texture.baseTexture.width)
        this.container.addChild(this.avatarPictureSprite);



        if (data.avatarUrl) {
            Assets.load(data.avatarUrl)
                .then(() => this.avatarPictureSprite.texture = Assets.get(data.avatarUrl!))
                .catch((error) => console.error(error))
        }

        // ## Avatar Default Overlay Background
        this.avatarOverlaySprite = new Sprite(textures.avatarOverlay.default);
        this.avatarOverlaySprite.anchor.set(0.5);
        this.avatarOverlaySprite.position.set(this.playerZoneWidth / 2, circleWidth / 2 + offsetTop)
        this.avatarOverlaySprite.mask = avatarMask;
        this.avatarOverlaySprite.scale.set(circleWidth / this.avatarOverlaySprite.texture.baseTexture.width)
        this.container.addChild(this.avatarOverlaySprite);

        // ## Avatar Active Blink Overlay Background
        this.avatarOverlayBlinkSprite = new Sprite(textures.avatarOverlay.active);
        this.avatarOverlayBlinkSprite.anchor.set(0.5);
        this.avatarOverlayBlinkSprite.position.set(this.playerZoneWidth / 2, circleWidth / 2 + offsetTop)
        this.avatarOverlayBlinkSprite.mask = avatarMask;
        this.avatarOverlayBlinkSprite.scale.set(circleWidth / this.avatarOverlayBlinkSprite.texture.baseTexture.width)
        this.avatarOverlayBlinkSprite.visible = false;
        this.container.addChild(this.avatarOverlayBlinkSprite);
    }

    private _createInfoBox(textures: { default: Texture, active: Texture }) {
        const offsetBottom = this.playerZoneHeight * 0.1; // 10% from bottom and top

        const infoBoxWidth = 260

        this.infoBoxSprite = new Sprite(textures.default);
        this.infoBoxSprite.scale.set(infoBoxWidth / this.infoBoxSprite.texture.baseTexture.width)
        this.infoBoxSprite.position.set((this.playerZoneWidth - this.infoBoxSprite.width) / 2, this.playerZoneHeight - this.infoBoxSprite.height - offsetBottom);
        this.container.addChild(this.infoBoxSprite);


        this.infoBoxBlinkSprite = new Sprite(textures.active);
        this.infoBoxBlinkSprite.scale.set(infoBoxWidth / this.infoBoxSprite.texture.baseTexture.width)
        this.infoBoxBlinkSprite.position.set((this.playerZoneWidth - this.infoBoxSprite.width) / 2, this.playerZoneHeight - this.infoBoxSprite.height - offsetBottom);
        this.container.addChild(this.infoBoxBlinkSprite);
        this.infoBoxBlinkSprite.visible = false;

        // TO DO: 📖 Add events if its needed
        // if (this.gameComponent.getMemberProfile().Id !== this.id) {
        //     this.infoBoxSprite.interactive = true;
        //     this.infoBoxSprite.buttonMode = true;
        //     this.infoBoxSprite.cursor = 'pointer';
        //     this.infoBoxSprite.on('mousedown', (event) => {
        //         event.stopPropagation();
        //         this.gameComponent.clickPlayer(this.id, this.noteText, this.noteColor, this.realNameString);
        //     });
        //     this.infoBoxSprite.on('touchstart', (event) => {
        //         event.stopPropagation();
        //         this.gameComponent.clickPlayer(this.id, this.noteText, this.noteColor, this.realNameString);
        //     });
        // }
    }



    private _createInfoBoxText(playerName: string, playerNameMaxLength: number) {

        const offsetBottom = this.playerZoneHeight * 0.1; // 10% from bottom and top

        // # Player Name
        this.playerName = new Text(this.formatString(playerName, playerNameMaxLength), { ...this.defaultFontStyle, fontSize: 28 });
        this.playerName.name = 'playerName';
        this.playerName.anchor.set(0.5, 0.5);
        this.playerName.position.set(this.playerZoneWidth / 2, this.playerZoneHeight - this.infoBoxSprite.height + this.infoBoxSprite.height / 4 + this.infoBoxSprite.height * 0.05 - offsetBottom);

        this.container.addChild(this.playerName);
        // ----------------------------

        // # Player Money - Amount, Currency, Decimal
        this.playerMoney = {
            amount: new Text('', { ...this.defaultFontStyle, fontSize: 28 }),
            currency: new Text('', { ...this.defaultFontStyle, fontSize: 28 }),
            decimal: new Text('', { ...this.defaultFontStyle, fontSize: 22 }),
            value: 0
        }
        this.playerMoney.amount.position.y = this.playerZoneHeight - this.infoBoxSprite.height * 0.1 - offsetBottom
        this.playerMoney.amount.anchor.set(0.5, 1);

        this.playerMoney.currency.position.y = this.playerZoneHeight - this.infoBoxSprite.height * 0.1 - offsetBottom
        this.playerMoney.currency.anchor.set(1, 1);
        this.playerMoney.currency.alpha = 0.5;


        this.playerMoney.decimal.position.y = this.playerZoneHeight - this.infoBoxSprite.height * 0.1 - 1 - offsetBottom;// 1px small offset up because of the smaller font size
        this.playerMoney.decimal.anchor.set(0, 1);

        this.container.addChild(this.playerMoney.amount);
        this.container.addChild(this.playerMoney.decimal);
        this.container.addChild(this.playerMoney.currency);
        // ----------------------------
        // test: this.updatePlayerMoney('1', '40','$')

    }

    private _createDealer(texture: Texture) {
        this.dealerSprite = new Sprite(texture);
        this.dealerSprite.name = 'dealer';
        this.dealerSprite.visible = false;
        this.dealerSprite.anchor.set(0.5, 0.5);
        this.dealerSprite.scale.set(0.6)
        this.dealerSprite.position.set(this.playerZoneWidth / 2 - this.infoBoxSprite.width / 2, this.playerZoneHeight - this.infoBoxSprite.height);

        this.container.addChild(this.dealerSprite);

    }

    private _createStraddle(texture: Texture) {
        this.straddleSprite = new Sprite(texture);
        this.straddleSprite.name = 'straddle';
        this.straddleSprite.visible = false;
        this.straddleSprite.anchor.set(0.5, 0.5);
        this.straddleSprite.scale.set(0.6)
        this.straddleSprite.position.set(this.playerZoneWidth / 2 + this.infoBoxSprite.width / 2, this.playerZoneHeight - this.infoBoxSprite.height);

        this.container.addChild(this.straddleSprite);

    }

    private _createProPlayer(texture: Texture, isProPlayer: boolean) {
        this.proPlayerSprite = new Sprite(texture);
        this.proPlayerSprite.name = 'proPlayer';
        this.proPlayerSprite.visible = isProPlayer;
        this.proPlayerSprite.anchor.set(0.5, 0.5);
        this.proPlayerSprite.scale.set(0.6)
        this.proPlayerSprite.position.set(this.playerZoneWidth / 2 + this.infoBoxSprite.width / 2, this.playerZoneHeight - this.playerZoneHeight * 0.12);

        this.container.addChild(this.proPlayerSprite);

    }

    private _createTimebank(timebank: {
        normalTime: number
    },) {

        this.timebank = {
            status: new Text('Timebank: 0', { ...this.defaultFontStyle, fill: '#000000', fontSize: 22 }),
            timer: new Text('10', { ...this.defaultFontStyle, fontSize: 72, dropShadow: true, dropShadowColor: '#000000', dropShadowBlur: 2, dropShadowDistance: 5 }),
            bar: new PlayerTimebankProgressBar({ x: 52, y: 210 }),
            data: {
                timer: 10000,
                bankTime: 50000,
                normalTime: timebank.normalTime, // TO DO its hardcorded for now
                normalTimeExpired: false // TO DO its hardcorded for now
            }
        }

        this.timebank.status.anchor.set(0.5, 1);
        this.timebank.status.position.set(this.playerZoneWidth / 2, this.playerZoneHeight);

        this.timebank.timer.anchor.set(0.5, 0.5);
        this.timebank.timer.position.set(this.playerZoneWidth / 2, this.playerAvatarCircleSize / 2 + this.playerZoneHeight * 0.1);

        this.timebank.status.visible = false;
        this.timebank.timer.visible = false;
        this.timebank.bar.container.visible = false;

        this.container.addChild(this.timebank.status);
        this.container.addChild(this.timebank.timer);
        this.container.addChild(this.timebank.bar.container);

        this.timebank.bar.reset();
    }



    updatePlayerMoney(currencySymbol: string, amount: string, decimal = '') {
        this.playerMoney.amount.text = amount;
        this.playerMoney.currency.text = currencySymbol;
        this.playerMoney.decimal.text = decimal ? `.${decimal}` : '';

        let offsetBottom = this.playerZoneHeight * 0.1; // 10% from bottom and top


        if (this.isCapGame) {
            this.playerMoney.amount.style.fontSize = 18
            this.playerMoney.currency.style.fontSize = 18
            this.playerMoney.currency.alpha = 1;
            this.playerMoney.decimal.style.fontSize = 18;

            this.playerMoney.currency.text = `( ${this.playerMoney.currency.text}`;
            this.playerMoney.decimal.text = `${this.playerMoney.decimal.text} )`;

            offsetBottom -= 8;
        } else {
            this.playerMoney.amount.style.fontSize = 28
            this.playerMoney.currency.style.fontSize = 28
            this.playerMoney.currency.alpha = 0.5;
            this.playerMoney.decimal.style.fontSize = 22
        }

        this.playerMoney.amount.position.y = this.playerZoneHeight - this.infoBoxSprite.height * 0.1 - offsetBottom
        this.playerMoney.currency.position.y = this.playerZoneHeight - this.infoBoxSprite.height * 0.1 - offsetBottom
        this.playerMoney.decimal.position.y = this.playerZoneHeight - this.infoBoxSprite.height * 0.1 - 1 - offsetBottom;// 1px small offset up because of the smaller font size


        this.playerMoney.amount.position.x = this.playerZoneWidth / 2 + this.playerMoney.currency.width / 2 - this.playerMoney.decimal.width / 2
        this.playerMoney.currency.position.x = this.playerMoney.amount.position.x - this.playerMoney.amount.width / 2 - 5 // 5px is space
        this.playerMoney.decimal.position.x = this.playerMoney.amount.position.x + this.playerMoney.amount.width / 2

    }




    private formatString(str: string, maxLength: number): string {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + '..';
        } else {
            return str;
        }
    }



    // ++++++++++++++++++++++++++++++++++++++++++++++++

    removeCards() {
        this.hasFold = false;
        this.numberOfCardsHolding = 0;
        this.playerCardsController.removeAllCards();
    }

    foldCards() {
        this.hasFold = true;
        this.playerCardsController.removeAllCards();
        this.numberOfCardsHolding = 0;
        this.stopTimer();
    }

    updateBalance(balance: number) {
        let money = 0;
        if (balance !== undefined) {
            money = balance;
        }

        if (this.showAmountInBigBlinds) {
            // ⏺  to do
        } else {
            this.playerMoney.value = money;
            const value = GameCurrencyPipe.prototype.transform(money, this.currency);
            const currencySymbol = value.split('.')[0].substring(0, 1);
            const amount = value.split('.')[0].substring(1);
            const decimal = value.split('.')[1];

            this.updatePlayerMoney(currencySymbol, amount, decimal)
        }

        this.setPlayerMoneyVisible(true);

        if (money > 0) {
            this.playerName.text = this.formatString(this.name, this.playerNameMaxLength);
        }
    }

    updateIsCapGame(isCapGame: boolean) {
        this.isCapGame = isCapGame;
        this.capMoneyText.visible = this.isCapGame;

        const value = GameCurrencyPipe.prototype.transform(this.playerMoney.value, this.currency);
        const currencySymbol = value.split('.')[0].substring(0, 1);
        const amount = value.split('.')[0].substring(1);
        const decimal = value.split('.')[1];
        this.updatePlayerMoney(currencySymbol, amount, decimal)
    }
    
    updateCAPMoney(capMoney: number) {
        this.capMoney = capMoney;
        this.capMoneyText.visible = this.isCapGame;

        this.capMoneyText.text = GameCurrencyPipe.prototype.transform(this.capMoney, this.currency);
        this.capMoneyText.position.y = this.playerZoneHeight - this.infoBoxSprite.height + this.capMoneyText.height + 16;
        this.capMoneyText.position.x = this.playerZoneWidth / 2 - this.capMoneyText.width / 2;

    }

    setStatusText(statusText: string) {
        if (statusText) {
            this.playerName.text = statusText;
            this.shouldRevertToDefaultStatus = true;
            this.revertTimer = 0;
        }
    }

    receiveCards(cards: CardData[]) {
        this.playerCardsController.removeAllCards();
        const isRotated = !(this.variant === VariantType2.sevenStud || this.variant === VariantType2.sevenStudHiLo || this.variant === VariantType2.razz) && !this.isMyPlayer;
        const cardSorting = this.isMyPlayer ? this.cardSorting : GameCardSorting.Default; // 📚 We cannot sort hidden cards
        this.playerCardsController.addCards(cards, cardSorting, isRotated);
    }





    // Bank Time Actions
    setTimer(newTime: number) {
        this.timebank.bar.reset();
        this.timebank.data.timer = this.timebank.data.normalTime - newTime * 1000;
    }

    setBankTime(bankTime: number = 0) {
        this.timebank.data.bankTime = bankTime * 1000;
        this.timebank.status.text = `Timebank: ${bankTime}`;
    }

    startUsingTimeBank(bankTime: number) {
        this.timebank.data.bankTime = bankTime * 1000;
        this.timebank.data.normalTimeExpired = true;
        this.timebank.data.timer = 0;


        this.timebank.status.text = `Timebank: ${bankTime}`;
        this.timebank.timer.text = `${bankTime}`;
        this.timebank.timer.visible = true;

        this.timebank.bar.changeToTimebankColor();
        this.timebank.bar.reset();
    }

    stopTimer() {

        this.timebank.data.normalTimeExpired = false;

        this.timebank.data.timer = 0;
        this.timebank.bar.changeToDefaultColor()
        this.timebank.bar.reset()
        this.timebank.bar.container.visible = false;

        this.timebank.status.visible = false;
        this.timebank.timer.visible = false;

        this.infoBoxBlinkSprite.visible = false;
        this.avatarOverlayBlinkSprite.visible = false;

        this.blinkAlpha = 1;
    }

    //

    setPlayerMoneyVisible(visible: boolean) {
        this.playerMoney.amount.visible = visible;
        this.playerMoney.currency.visible = visible;
        this.playerMoney.decimal.visible = visible;
    }

    setStatus(playerGameStatus: PlayerGameStatus) {
        switch (playerGameStatus) {
            case PlayerGameStatus.seating:
                this.infoBoxSprite.texture = this.textures.infoBox.default;
                this.avatarOverlaySprite.texture = this.textures.avatarOverlay.default;

                this.infoBoxSprite.filters = [];
                this.avatarOverlaySprite.filters = [];
                this.avatarPictureSprite.filters = [];

                break;
            case PlayerGameStatus.active:
                this.infoBoxSprite.texture = this.textures.infoBox.active;
                this.avatarOverlaySprite.texture = this.textures.avatarOverlay.active;

                this.infoBoxSprite.filters = [];
                this.avatarOverlaySprite.filters = [];
                this.avatarPictureSprite.filters = [];

                this.setPlayerMoneyVisible(true);
                break;
            case PlayerGameStatus.fold:
                this.infoBoxSprite.texture = this.textures.infoBox.fold
                this.avatarOverlaySprite.texture = this.textures.avatarOverlay.fold
                break;
            case PlayerGameStatus.sitout:
                this.infoBoxSprite.texture = this.textures.infoBox.sitout
                this.avatarOverlaySprite.texture = this.textures.avatarOverlay.sitout

                this.infoBoxSprite.filters = [this.colorMatrix];
                this.avatarOverlaySprite.filters = [this.colorMatrix];
                this.avatarPictureSprite.filters = [this.colorMatrix];


                this.setPlayerMoneyVisible(true);
                this.setStatusText('Sitout');

                break;
            case PlayerGameStatus.disconnected:
                this.infoBoxSprite.texture = this.textures.infoBox.disconnected
                this.avatarOverlaySprite.texture = this.textures.avatarOverlay.disconnected

                this.infoBoxSprite.filters = [];
                this.avatarOverlaySprite.filters = [];
                this.avatarPictureSprite.filters = [];

                this.setPlayerMoneyVisible(false);
                this.setStatusText('Disconnected');

                break;
            default:
                this.infoBoxSprite.texture = this.textures.infoBox.default
                this.avatarOverlaySprite.texture = this.textures.avatarOverlay.default

                this.infoBoxSprite.filters = [];
                this.avatarOverlaySprite.filters = [];
                this.avatarPictureSprite.filters = [];


                break;
        }
    }


    takeTurn(isReplay?: boolean) {
        if (isReplay) {
            return;
        }
        this.isMyTurn = true;
        this.timebank.bar.container.visible = true;

        // Blinking Sprites
        this.infoBoxBlinkSprite.visible = true;
        this.avatarOverlayBlinkSprite.visible = true;

        if (this.isMyPlayer) {

            this.timebank.status.visible = true;
        }
    }

    endTurn() {
        this.isPlayerTurn = false;
        this.stopTimer()
    }



    chatMessage(message: string) {
        const position: Point = { x: 180, y: 0 };
        const chatBubble = new ChatBubble(this.textures.chatBubble, position, { message });
        this.container.addChild(chatBubble.container);
    }

    private _onOpenUserInfo() {
        this._store.dispatch(GamesActions.onOpenUserInfoDialog({
            tableId: this.tableId,
            playerId: this.playerId,
            playerName: this.name,
            noteColor: this.memberPreferences.playerColor[this.playerId],
            noteText: this.memberPreferences.playerNotes[this.playerId]
        }))
    }

    updateMemberPreferences(memberPreferences: MemberPreferences) {
        this.memberPreferences = memberPreferences;
        this._setPlayerNote(this.memberPreferences.playerColor[this.playerId])
    }

    private _setPlayerNote(noteColor?: keyof typeof NoteColors) {
        if (this.isMyPlayer) { return; }
        if (!noteColor) { // remove note if color is not provided
            if (this.playerNote) {
                this.container.removeChild(this.playerNote);
                this.playerNote.destroy();
                this.playerNote = undefined
            }
            return;
        }

        if (NoteColors[noteColor] === undefined) { // if color is not valid set default color
            noteColor = '#EBEBEB';
        }

        if (this.playerNote) { // if note already exists change texture
            this.playerNote.texture = this.textures.playerNote[noteColor];
            return;
        }

        // create new note
        this.playerNote = new Sprite(this.textures.playerNote[noteColor]);
        this.playerNote.anchor.set(0.5);
        this.playerNote.scale.set(0.6);
        this.playerNote.position.set(this.playerZoneWidth / 2 - this.infoBoxSprite.width / 2, this.playerZoneHeight - this.playerZoneHeight * 0.12);
        this.container.addChild(this.playerNote);
    }

    getPlayerNumbersOfCards(): number {
        return this.playerCardsController.cards.length;
    }

    revealCards(cards: CardData[]) {
        this.playerCardsController.removeAllCards();
        this.playerCardsController.addCards(cards, this.cardSorting);
    }

}
