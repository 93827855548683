import { Injectable, inject } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerMessageType } from '@app/enums/server-message-type';
import { ServerMsg, ServerMsgAccountEmailUniqueResponse, ServerMsgAccountPasswordChangeResponse, ServerMsgAccountUsernameUniqueResponse, ServerMsgConnectResponse, ServerMsgImageUploadResponse, ServerMsgPlayerBalance, ServerMsgRequestPhoneCodeResponse, ServerMsgRequestSelfExclusionResponse, ServerMsgRequestWithdrawalResponse, ServerMsgSubmitPhoneCodeResponse, ServerMsgTransferMoneyResponse } from '@app/models/server-msg';
import { Store, select } from '@ngrx/store';
import { WsService } from '@app/services/ws.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import * as  WSActions from '@app/store/features/web-socket/web-socket.actions';
import * as ConfigActions from '@app/store/features/config/config.actions';
import { ConnectionStatus } from '@app/enums/connection-status';
import { combineLatest, iif, of, } from 'rxjs';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import { Router } from '@angular/router';
import { GlobalSettings, GlobalSettingsAPIResponse } from '@app/models/global-settings';
import { ServerResponse } from '@app/enums/server-response';
import { MemberProfileDTO } from '@app/models/member-profile';
import * as UserActions from '@app/store/features/user/user.actions';
import { UserService } from '@app/features/user/services/user.service';
import { CurrencyInfoDTO } from '@app/models/currency-info';
import { CurrencyService } from '@app/services/currency.service';
import { PlayerBalance } from '@app/models/player-balance';
import { AccountType } from '@app/models/account-type';
import * as UserSelectors from '@app/store/features/user/user.selectors';
import { ToastrService } from 'ngx-toastr';

import * as ConfigSelectors from '@app/store/features/config/config.selectors';
import { environment } from '@environments/environment';
import { ta } from 'date-fns/locale';
import { PlayerLevelStatusDTO } from '@app/models/player-level-status';
import { GatewaysInfoDTO } from '@app/features/cashier/models/gateway';
import * as CashierActions from '@app/store/features/cashier/cashier.actions';
import { CashierService } from '@app/features/cashier/services/cashier.service';
import * as CurrenciesSelectors from '@app/store/features/currencies/currencies.selectors';
import { TransactionDTO } from '@app/features/cashier/models/transaction';
import { GenericDialogComponent } from '@app/components/generic-dialog/generic-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { Base64Encode } from '@app/helpers/base64-encode';
import { VerificationDocumentType } from '@app/features/cashier/models/verification-document-type';

@Injectable()
export class CashierEffects {

    private readonly _ws = inject(WsService);
    private readonly _actions$ = inject(Actions);
    private readonly _store = inject(Store);
    private readonly _router = inject(Router);
    private readonly _userService = inject(UserService);
    private readonly _currencyService = inject(CurrencyService);
    private readonly _toastr = inject(ToastrService);
    private readonly _cashierService = inject(CashierService);
    private readonly _dialog = inject(Dialog);




    gatewaysInfo$ = createEffect(() => this._ws.getDataResponse<GatewaysInfoDTO>(ServerResponse.GatewaysInfo)
        .pipe(
            map(gatewaysInfoDTO => {
                return CashierActions.setGateways({
                    depositGateways: gatewaysInfoDTO.depositResponse.depositGateways.map(gateway => this._cashierService.fromGateway(gateway)),
                    withdrawalGateways: gatewaysInfoDTO.withdrawResponse.withdrawGateways.map(gateway => this._cashierService.fromGateway(gateway))
                })
            })
        ));



    onTransferMoneyToPlayer$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CashierActions.transferMoneyToPlayer),
                tap(({ username, amount, currency, note }) => this._cashierService.transferMoneyToPlayer(username, amount, currency, note)),
                switchMap(() => this._ws.getServerMsg<ServerMsgTransferMoneyResponse>(ServerMessageType.TransferMoneyResponse)),
                tap((response) => {
                    if (response.errorCode) {
                        this._toastr.warning(response.text, `Transfer money to player`)
                    } else {
                        this._toastr.success(response.text, `Transfer money to player`)

                    }
                }),
                map(() => CashierActions.getTransactionsHistory())
            ));


    getTransactionsHistory$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CashierActions.getTransactionsHistory),
                switchMap(() => this._store.pipe(select(CurrenciesSelectors.selectActive))),
                filter((currency) => !!currency),
                tap((currency) => this._cashierService.getTransactionsHistory(currency!.id)),
                switchMap(() => this._ws.getDataResponse<TransactionDTO[]>(ServerResponse.TransactionsHistory)),
                map((transactions) => CashierActions.setTransactionsHistory({ transactions: transactions.map(transaction => this._cashierService.fromTransaction(transaction)) }))
            ));

    cancelWithdrawal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CashierActions.cancelWithdrawal),
                switchMap(() => this._store.pipe(select(CurrenciesSelectors.selectActive))),
                tap((currency) => this._cashierService.cancelWithdrawal(currency!.id)),

            ), {
        dispatch: false
    });



    requestDeposit$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CashierActions.requestDeposit),
                tap((data) => this._cashierService.requestDeposit(data.gatewayName, data.amount, data.currencyId, data.promoCode)),
                switchMap(() => this._ws.getServerMsg<ServerMsgTransferMoneyResponse>(ServerMessageType.DepositInitiateResponse).pipe(take(1))),
                tap((response) => {
                    if (response.errorCode) {
                        this._toastr.warning((!response.text || response.text === '') ? 'Unknown' : response.text, `Deposit`)
                    } else if (response.text) {
                        const dialog = this._dialog.open(GenericDialogComponent, { width: '300px' })
                        dialog.componentInstance!.title = 'Deposit';
                        dialog.componentInstance!.text = 'You will be directed to the payment processing page'
                        dialog.componentInstance!.dissmissBtn = 'Cancel'
                        dialog.componentInstance!.linkBtn = {
                            text: 'Continue',
                            href: response.text!
                        }

                    }
                }),
                map(() => CashierActions.getTransactionsHistory())

            ), { dispatch: false })


    requestWithdrawal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CashierActions.requestWithdrawal),
                tap((data) => this._cashierService.requestWithdrawal(data.gatewayName, data.amount, data.currencyId, data.extraInfo)),
                switchMap(() => this._ws.getServerMsg<ServerMsgRequestWithdrawalResponse>(ServerMessageType.RequestWithdrawalResponse).pipe(take(1))),
                tap((response) => {
                    if (response.errorCode) {
                        this._toastr.warning((!response.text || response.text === '') ? 'Unknown' : response.text, `Deposit`)
                    } else if (response.text) {
                        const dialog = this._dialog.open(GenericDialogComponent, { width: '300px' })
                        dialog.componentInstance!.title = 'Withdrawal';
                        dialog.componentInstance!.text = 'You will be directed to the payment processing page'
                        dialog.componentInstance!.dissmissBtn = 'Cancel'
                        dialog.componentInstance!.linkBtn = {
                            text: 'Continue',
                            href: response.text!
                        }

                    }
                })
            ), { dispatch: false })


    uploadVerificationDocuments$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CashierActions.uploadVerificationDocuments),
                switchMap((data) => {

                    return combineLatest([
                        data.fileGovId ? new Base64Encode().encodeFile(data.fileGovId) : of(null),
                        data.fileUtilityBill ? new Base64Encode().encodeFile(data.fileUtilityBill) : of(null),
                        data.filePan ? new Base64Encode().encodeFile(data.filePan) : of(null),
                    ])
                }),
                tap(([fileGovId, fileUtilityBill, filePan]) => {
                    if (fileGovId) {
                        this._cashierService.uploadDocument(fileGovId, VerificationDocumentType.GOVERNMENT)
                    }

                    if (fileUtilityBill) {
                        this._cashierService.uploadDocument(fileUtilityBill, VerificationDocumentType.UTILITY_BILL)
                    }

                    if (filePan) {
                        this._cashierService.uploadDocument(filePan, VerificationDocumentType.PAN_CARD)
                    }
                }),
                switchMap((files) => this._ws.getServerMsg<ServerMsgImageUploadResponse>(ServerMessageType.ImageUploadResponse).pipe(take(files.filter(file => !!file).length))),
                tap((response) => {
                    if (response.value < 0 || response.errorCode) {
                        this._toastr.warning((!response.text || response.text === '') ? 'Unknown' : response.text, `Profile verification`)
                    } else {
                        this._toastr.success(`File uploaded`, `Profile verification`)
                    }
                })
            ), { dispatch: false })



    requestPhoneVerificationCodeSms$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CashierActions.requestPhoneVerificationCodeSms),
                tap(() => this._cashierService.requestPhoneVerificationCodeSms()),
                switchMap(() => this._ws.getServerMsg<ServerMsgRequestPhoneCodeResponse>(ServerMessageType.RequestPhoneCodeResponse).pipe(take(1))),
                tap((response) => {
                    if (response.value < 0 || response.errorCode) {
                        this._toastr.warning((!response.text || response.text === '') ? 'Unknown' : response.text, `Phone verification`)
                    } else {
                        this._toastr.success(`SMS send successfully`, `Phone verification`)
                    }
                })
            ), { dispatch: false })


    submitPhoneCode$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CashierActions.submitPhoneCode),
                tap(({ code }) => this._cashierService.submitPhoneCode(code)),
                switchMap(() => this._ws.getServerMsg<ServerMsgSubmitPhoneCodeResponse>(ServerMessageType.SubmitPhoneCodeResponse).pipe(take(1))),
                tap((response) => {
                    if (response.value < 0 || response.errorCode) {
                        this._toastr.warning((!response.text || response.text === '') ? 'Unknown' : response.text, `Phone verification`)
                    } else {
                        this._toastr.success(`Phone successfully verified`, `Phone verification`)
                    }
                })
            ), { dispatch: false })
} 