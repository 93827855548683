import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { of, tap } from 'rxjs';
import * as AuthActions from '@app/store/features/auth/auth.actions';

export const loginGuard: CanActivateFn = (route, state) => {

  const store = inject(Store);

  return of(true).pipe(
    tap(() => store.dispatch(AuthActions.onOpenLoginPage()))
  )
};
