<div class="dialog-header">
    <div class="dialog-header-title">
        R2T? ({{timer}}s)
    </div>
    <div class="dialog-header-close-button">
        <button (click)="close()">
            <svg-icon src="assets/img/icons/dialog-close.svg"
                [stretch]="true"
                [applyClass]="true"
                class="dialog-header-close-button-icon" />
        </button>
    </div>
</div>
<div class="dialog-footer">
    <div class="app-game-dialog-ask-r2t-footer">
        <button class="app-game-dialog-ask-r2t-footer-button-accept"
            appButton
            (click)="confirm()">{{data.yesText}}</button>

        <button
            class="app-game-dialog-ask-r2t-footer-button-cancel secondary"
            appButton
            (click)="confirm()">{{data.noText}}</button>
    </div>
</div>
