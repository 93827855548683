import { Injectable, inject } from '@angular/core';
import { delay, tap, switchMap, take, filter, map, startWith } from 'rxjs/operators';
import { WsService } from '@app/services/ws.service';
import { ServerMessageType } from '@app/enums/server-message-type';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { combineLatest, fromEvent, iif, merge, of } from 'rxjs';
import { ConnectionStatus } from '@app/enums/connection-status';
import { ServerMsg } from '@app/models/server-msg';
import { environment } from '@environments/environment';
import { Store, select } from '@ngrx/store';
import * as WSActions from '@app/store/features/web-socket/web-socket.actions';
import * as ConfigActions from '@app/store/features/config/config.actions';
import * as ConfigSelectors from '@app/store/features/config/config.selectors';
import * as AuthSelectors from '@app/store/features/auth/auth.selectors';

@Injectable()
export class WSEffects {

    private readonly _ws = inject(WsService);
    private readonly _actions$ = inject(Actions);
    private readonly _store = inject(Store);

    onConnectedStartPingPong$ = this._ws.stream.pipe(
        take(1),
        tap(() => this._ws.sendPing()),
        tap(() => this._store.dispatch(WSActions.updatePing())),
        switchMap((() => this._ws.getServerMsg<ServerMsg>(ServerMessageType.Pong))),
        tap(() => this._store.dispatch(WSActions.updatePong())),
        delay(environment.pingPongTime),
        tap(() => this._ws.sendPing()),
        tap(() => this._store.dispatch(WSActions.updatePing()))
    )

    wsPing$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WSActions.updateConnectionStatus),
                switchMap(({ status }) => iif(() => status === ConnectionStatus.Connected, this.onConnectedStartPingPong$, of())),
            ),
        { dispatch: false }
    );

    wsInitConnection$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WSActions.connectWebSocket),
                //      delay(600),
                switchMap(({ wsUrl, token, skin }) => this._ws.connect(wsUrl, token, skin))
            ),
        { dispatch: false }
    );


    // CODE_DEBT RestAPI
    wsReconnect$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WSActions.updateConnectionStatus),
                filter(({ status }) => status === ConnectionStatus.Disconnected),

                switchMap(() => combineLatest([
                    this._store.pipe(
                        select(AuthSelectors.getLoggedInStatus),
                        filter(domainSettings => !!domainSettings),
                        map((domainSettings) => domainSettings!),
                    ),
                    this._store.pipe(
                        select(ConfigSelectors.getDomainSettings),
                        filter(domainSettings => !!domainSettings),
                        map((domainSettings) => domainSettings!),
                    ),
                    this._store.pipe(
                        select(ConfigSelectors.getAuthSettings),
                        filter(authSettings => !!authSettings),
                        map((authSettings) => authSettings!),
                    )])
                ),
                map(([loggedIn, domainSettings, authSettings]) => ({ loggedIn, token: authSettings.token, wsUrl: domainSettings.wsUri, skin: domainSettings.skinName })),
                tap(() => console.log("🚀 Reconnect")),
                tap(({ loggedIn, token, wsUrl, skin }) => {
                    if (loggedIn) {
                        this._ws.connect(wsUrl, token, skin)
                    }
                })
            ),
        { dispatch: false }
    );


    /**
     * Reconnection 
     * Started but its not finished
    */
    reconnection$ = createEffect(() =>
        merge(
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        ).pipe(
            startWith(null),
            map(() => navigator.onLine),
            tap(online => { console.log("🟢 🍃", online, online ? "Online" : "Offline") }),
        ),
        { dispatch: false }
    );
} 