import { Injectable, inject } from '@angular/core';
import { ServerRequestMessage } from '@app/enums/server-request';
import { WsService } from '@app/services/ws.service';

@Injectable({
  providedIn: 'root'
})
export class LobbyChatService {

  private readonly _ws = inject(WsService)

  getChatHistory(oldestMessageId?: number, numberOfMessages = 25): void {
    const data = {
      type: ServerRequestMessage.GetChatLobbyHistory,
      value: oldestMessageId,
      value2: numberOfMessages
    }
    this._ws.sendData(data);
  }



  sendMessage(message: string) {
    const data = {
      type: ServerRequestMessage.Chat,
      str1: message
    }
    this._ws.sendData(data);
  }
}
