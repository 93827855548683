import { Injectable, inject } from '@angular/core';
import { Observable, ReplaySubject, Subject, filter, map, mergeMap, of, share, shareReplay, switchMap, tap } from 'rxjs';
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import * as _ from 'lodash';
import { ServerMsg } from '@app/models/server-msg';
import { ServerMessageType } from '@app/enums/server-message-type';
import { ServerResponse } from '@app/enums/server-response';
import { ServerRequestMessage } from '@app/enums/server-request';
import { ConnectionStatus } from '@app/enums/connection-status';

import { Store, select } from '@ngrx/store';

import * as WSActions from '@app/store/features/web-socket/web-socket.actions';

@Injectable({
  providedIn: 'root'
})
export class WsService {

  private readonly _store: Store<any> = inject(Store);

  ws: WebSocketSubject<any>;
  wsConnection: Observable<any>
  //stream = new ReplaySubject<any>()
  stream = new Subject<any>()

  constructor() {
  }

  connect(url: string, token: string, skinName: string) {
    console.log("@WS Connect 🌱")
    this.ws = webSocket({
      url, //: 'wss://testgame.theonlypoker.com:14445',
      openObserver: {
        next: () => {
          this._store.dispatch(WSActions.updateConnectionStatus({ status: ConnectionStatus.Connected }))

          console.log("🟢", "Connected");
          this.ws.next({
            type: 6, // ClientMsgType.ConnectWithAuthToken = 6
            Str1: token, // 
            Str2: `Angular`, //ClientName
            Str3: skinName,
            Str4: 'TestingBrowser', // Device
            Str5: `${Math.random()}`, // Unique guid to identify the device (can be generated locally) this will let connection overtake existing one.
            Value2: 2 // platform (1 for Windows, 2: HTML, 3:Android, 4:iOS, 7:Mac, 8:Bot)
          })
        }
      },
      closeObserver: {
        next: () => {
          this._store.dispatch(WSActions.updateConnectionStatus({ status: ConnectionStatus.Disconnected }))
          console.log("🔴", "Disconnected")
        }
      },
      closingObserver: {
        next: () => {
          // this._store.dispatch(WSActions.updateConnectionStatus({ status: ConnectionStatus.Disconnected }))
          console.log("🔴", "Closing")
        }
      },
      deserializer: (event) => {
        return this.format(JSON.parse(event.data), 'camelCase')
      },
      serializer: (value) => {
        return JSON.stringify(this.format(value, 'pascalCase'))
      }
    })

    setTimeout(() => {
      this.stream.next({
        "ServerMsg": [
          {
            "Date": "2025-02-02T17:31:17.316Z",
            "MsgTypeLog": "CommunityCards",
            "Id": 142,
            "IdTable": 29062,
            "MsgType": 29,
            "Cards": [
              {
                "Number": 5,
                "Suit": 1,
                "HandNumber": 2732106
              },
              {
                "Number": 6,
                "Suit": 1,
                "HandNumber": 2732106
              },
              {
                "Number": 12,
                "Suit": 3,
                "HandNumber": 2732106
              },
              {
                "Number": 13,
                "Suit": 2,
                "HandNumber": 2732106
              }
            ],
            "CommunityCardsR2T1": [
              {
                "Number": 14,
                "Suit": 0,
                "HandNumber": 0
              }
            ],
            "CommunityCardsR2T2": [
              {
                "Number": 5,
                "Suit": 2,
                "HandNumber": 0
              }
            ],
            "AllCards": [
              {
                "Number": 5,
                "Suit": 1,
                "HandNumber": 2732106
              },
              {
                "Number": 6,
                "Suit": 1,
                "HandNumber": 2732106
              },
              {
                "Number": 12,
                "Suit": 3,
                "HandNumber": 2732106
              },
              {
                "Number": 13,
                "Suit": 2,
                "HandNumber": 2732106
              }
            ]
          }
        ],
        "Response": "ServerMsg"
      })
      console.log("🟢", "CommunityCards")
    }, 10000);



    setTimeout(() => {
      //  this.disconnect()
    }, 5000);
    return this.ws
      .asObservable()
      .pipe(
        //tap(message => console.log('🟠', message)),
        tap(message => this.stream.next(message)),
      )
  }




  format(data: any, type: 'camelCase' | 'pascalCase'): any {
    if (_.isArray(data)) {
      return data.map((item: any) => this.format(item, type));
    } else if (_.isObject(data) && data !== null) {
      const convertedObj: any = {};
      _.mapKeys(data, (value: any, key: any) => {
        const newKey = type === 'camelCase' ? _.camelCase(key) : _.upperFirst(_.camelCase(key));
        convertedObj[newKey] = this.format(value, type);
      });
      return convertedObj;
    }

    if (typeof data === 'string') {
      if (data === 'False' || data === 'false') {
        return false;
      } else if (data === 'True' || data === 'true') {
        return true;
      }
    }
    return data;
  }


  sendData(data: any): void {
    this.ws.next(data)
  }

  getDataResponse<T>(serverResponse: ServerResponse): Observable<T> {



    return this.stream
      .pipe(
        filter(data => data.response === serverResponse),
        map(data => data[_.camelCase(serverResponse)] as T)
      )
  }

  getServerMsg<T>(serverMessageType: ServerMessageType): Observable<T> {
    return this.stream
      .pipe(
        // tap(data => console.log('🟢', data)),

        filter(data => data.response === 'ServerMsg'),
        // tap(data => console.log('ServerMsg 🟢', data, serverMessageType)),

        filter(data => (data.serverMsg as ServerMsg[]).filter(serverMsg => serverMsg.msgType === serverMessageType).length > 0),
        map(data => (data.serverMsg as ServerMsg[]).filter(serverMsg => serverMsg.msgType === serverMessageType)),
        mergeMap(data => data as T[]),
        // tap(data => console.log('2 🟢', data))
        // shareReplay({ bufferSize: 1, refCount: true })
      )
  }

  disconnect(): void {
    this.ws.complete()
  }

  sendPing() {
    this.sendData({
      type: ServerRequestMessage.Ping,
      Value2: new Date().getTime() + Math.random()
    });
  }
}
