export enum HandType {
    Hidden = 0,
    High = 1,
    Pair = 2,
    TwoPair = 3,
    Tripple = 4,
    Straight = 5,
    Flush = 6,
    FullHouse = 7,
    FourOfaKind = 8,
    StraightFlush = 9,
    Badugi1Card = 100,
    Badugi2Cards = 101,
    Badugi3Cards = 102,
    Badugi4Cards = 103,
}

// TRANSLATION_DEPT - handTypesText 
export const HandTypesText: string[] = [
    'Hidden',
    'High',
    'Pair',
    'Two Pairs',
    '3 of a kind',
    'Straight',
    'Flush',
    'Full House',
    '4 of a kind',
    'Straight Flush'
];
