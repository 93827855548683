import { Component, ElementRef, HostListener, OnDestroy, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, catchError, filter, finalize, of, switchMap, tap } from 'rxjs';

import { LobbyFilterLimit } from './features/lobby/enums/lobby-filter-limit';
import { map, toPairs } from 'lodash';
import { LobbyFilterSpeed } from './features/lobby/enums/lobby-filter-speed';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogContainer, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import * as ConfigActions from '@app/store/features/config/config.actions';
import * as ConfigSelectors from '@app/store/features/config/config.selectors';

import * as AuthSelectors from '@app/store/features/auth/auth.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { WsService } from './services/ws.service';
import { InitLoaderComponent } from './components/init-loader/init-loader.component';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ButtonComponent } from './components/button/button.component';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import { Assets, Application, Container, Sprite } from 'pixi.js';
import * as GameHorizontal from '@app/features/game/consts/game-horizontal.const';

const angularMaterialModules = [
  MatSlideToggleModule,
  MatExpansionModule,
  MatDialogModule,

  MatSnackBarModule,

  OverlayModule
]
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-root' },
  imports: [
    CommonModule,
    RouterModule,
    InitLoaderComponent,
    ...angularMaterialModules
  ]
})
export class AppComponent {

  gameConstData = GameHorizontal

  getGameInitialized$ = this.store.pipe(select(ConfigSelectors.getGameInitialized));
  getDefaultAssetsLoaded$ = this.store.pipe(select(ConfigSelectors.getDefaultAssetsLoaded));

  wsConnetion = new BehaviorSubject<void>(undefined);
  isOpen = false;


  constructor(public dialog: MatDialog, private store: Store<{ count: number }>, private _ws: WsService) {






  }

}