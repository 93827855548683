import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { WINDOW } from './window.providers';
import { DomainSettings } from '@app/models/domain-settings';
import { Assets, Application, Container, Sprite } from 'pixi.js';
import { Observable } from 'rxjs';
import { sound, Sound } from '@pixi/sound';
import * as PIXI from 'pixi.js';
import { NoteColors } from '@app/features/game/enums/note-colors';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    @Inject(WINDOW) private window: Window,
    private readonly _httpClient: HttpClient) { }



  loadConfig() {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    });

    return this._httpClient.get<DomainSettings>(`${environment?.dataSource}/config/${environment?.domainname ?? this.window.location.hostname}.json`, { headers })

  }

  // ===========================
  // @ Game Assets
  // ===========================
  unloaGameAssets() {
    Assets.unloadBundle('game');
    Assets.unloadBundle('game-horizontal');
  }



  loadGameAssets(domainSettings: DomainSettings): Observable<number> {
    const { code } = domainSettings;
    Assets.addBundle('game', {
      logo: `${environment.dataSource}/assets/${code}/tables/table_logo.png`,
      seatDefault: `${environment.dataSource}/assets/${code}/seat/take_a_seat_default.png`,
      seatClick: `${environment.dataSource}/assets/${code}/seat/take_a_seat_click.png`,

      backCard: `${environment.dataSource}/assets/${code}/cards/cardBack.png`,
      backCard2: `${environment.dataSource}/assets/${code}/cards/cardBack2.png`,
      backCard3: `${environment.dataSource}/assets/${code}/cards/cardBack3.png`,
      backCard4: `${environment.dataSource}/assets/${code}/cards/cardBack4.png`,

      tableShadow: `assets/common/table/table_shadow.png`,

      playerActive: `${environment.dataSource}/assets/${code}/player/player_active.png`,
      playerDefault: `${environment.dataSource}/assets/${code}/player/player_default.png`,
      playerDisconnected: `${environment.dataSource}/assets/${code}/player/player_disconnected.png`,
      playerFold: `${environment.dataSource}/assets/${code}/player/player_fold.png`,
      playerSitout: `${environment.dataSource}/assets/${code}/player/player_sitout.png`,

      avatarActive: `${environment.dataSource}/assets/${code}/player/avatar_active.png`,
      avatarDefault: `${environment.dataSource}/assets/${code}/player/avatar_default.png`,
      avatarDisconnected: `${environment.dataSource}/assets/${code}/player/avatar_disconnected.png`,
      avatarFold: `${environment.dataSource}/assets/${code}/player/avatar_fold.png`,
      avatarSitout: `${environment.dataSource}/assets/${code}/player/avatar_sitout.png`,

      emptyAvatar: `${environment.dataSource}/assets/${code}/player/avatar.png`,

      playerStraddle: `assets/common/player/straddle.png`,
      chatBubble: `assets/common/player/chat-bubble.png`,

      playerDealer: `${environment.dataSource}/assets/${code}/tables/dealer.png`,
      playerNbRebuyUsed: `assets/common/player/nb-rebuy-used.png`,
      playerProMark: `assets/common/player/pro-mark.png`,

      callTimeAttention: `assets/common/player/call-time/attention.png`,
      callTimeCheck: `assets/common/player/call-time/check.png`,
      callTimeBackground: `assets/common/player/call-time/background.png`,

      //  tableCardAtlas: domainSettings.skinSettings.tableCards[0].cardJsonUrl,
      // playerCardAtlas: domainSettings.skinSettings.playerCards[0].cardJsonUrl,


      chip: `${environment.dataSource}/assets/${code}/chips/chip.png`,

      rabbitHuntingCardCover: `assets/common/cards/rabbit-hunting.png`,

      // not used in v4
      // barBackground: `${environment.dataSource}/assets/${code}/timer/barBackground.png`,
      // barCap: `${environment.dataSource}/assets/${code}/timer/barCap.png`,
      // barMiddle: `${environment.dataSource}/assets/${code}/timer/barMiddle.png`,
      // barGlow: `${environment.dataSource}/assets/${code}/timer/barGlow.png`,

    });

    const tableCardAtlas: any = {}
    const playerCardAtlas: any = {}
    for (let i = 0; i < 4; i++) { // 4 not included
      tableCardAtlas[i] = `assets/common/cards/table/${i}.json`
      playerCardAtlas[`${i}`] = `assets/common/cards/player/${i}.json`
    }


    Assets.addBundle('tableCardAtlas', tableCardAtlas);
    Assets.addBundle('playerCardAtlas', playerCardAtlas);

    const playerNoteIcons: any = {}
    for (const key in Object.keys(NoteColors).slice(Object.keys(NoteColors).length / 2)) {
      playerNoteIcons[`${NoteColors[key]}`] = `assets/common/player/note-icons/${key}.png`
    }

    Assets.addBundle('playerNote', playerNoteIcons);
    Assets.addBundle('game-horizontal', {
      table: `${environment.dataSource}/assets/${code}/tables/table.png`,
      table2: `${environment.dataSource}/assets/${code}/tables/table2.png`,
      table3: `${environment.dataSource}/assets/${code}/tables/table3.png`,
      table4: `${environment.dataSource}/assets/${code}/tables/table4.png`,
    });

    Assets.addBundle('game-vertical', {
      table: `${environment.dataSource}/assets/${code}/tables/vertical_table.png`,
      table2: `${environment.dataSource}/assets/${code}/tables/vertical_table2.png`,
      table3: `${environment.dataSource}/assets/${code}/tables/vertical_table3.png`,
      table4: `${environment.dataSource}/assets/${code}/tables/vertical_table4.png`,
    });



    const soundFiles = [
      { name: 'allIn', url: `assets/sound/allin.mp3` }, // used
      { name: 'check', url: `assets/sound/check.mp3` }, // used
      { name: 'fold', url: `assets/sound/fold.mp3` },// used
      { name: 'raise', url: `assets/sound/raise.mp3` }, // used
      { name: 'timeBank', url: `assets/sound/time-bank.mp3` }, // used
      { name: 'waitingOver', url: `assets/sound/waiting-over.mp3` },

      { name: 'chip', url: `assets/sound/chip.wav` }, // used
      { name: 'slotMachine', url: `assets/sound/slot-machine.wav` }
    ]

    let soundLoaded = 0;

    // Config Sound Volume:
    const soundTurnOff = localStorage.getItem('soundTurnOff')
    sound.volumeAll = soundTurnOff === 'true' ? 0 : 1;

    // =======================


    return new Observable((observer) => {

      // for (const soundFile of soundFiles) {
      //   PIXI.Assets.add(soundFile.name, soundFile.url);
      //   PIXI.Assets.load(soundFile.name)
      //     .then(() => soundLoaded += 100 / soundFiles.length)
      //     .catch(err => observer.error(err))
      // }

      for (const soundFile of soundFiles) {
        sound.add(soundFile.name, {
          url: soundFile.url,
          preload: true,
          loaded: (err, sound) => {
            soundLoaded += 100 / soundFiles.length
          }
        });
      }

      let completed = 0;
      Assets.loadBundle([
        'game',
        'game-horizontal',
        'game-vertical',
        // THEME:
        'tableCardAtlas',
        'playerCardAtlas',
        'playerNote'
      ], (assetsLoaded) => {

        const loaded = (assetsLoaded * 100 * 0.9) + (soundLoaded * 0.1) // Sound is 10% of the total loading, so we multiply it by 0.1
        observer.next(loaded)
      }).then(() => {
        completed++

        observer.complete();


        // sound.play('chip',{loop:true});

      }).catch(err => {
        observer.error(err)
      })
    })
  }
}
