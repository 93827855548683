export enum VariantType2 {
    texasHoldEm = 1,
    texasHoldEmRock = 2, // (ring only)
    capTexasHoldEm = 3, // CAP, where CAP means max you can lose in a single hand. (ring only), (not limit only)
    omaha3 = 9, // Omaha with 3 cards
    omaha = 10,
    omahaHiLo = 11, /* Omaha with hi/lo winners */
    omaha5 = 12, /* Omaha with 5 cards instead of 4 */
    omaha5HiLo = 13, /* Omaha with 5 cards instead of 4 with hi/lo winners */
    omaha6 = 14, /* Omaha with 6 cards instead of 4 */
    triOmaha = 15, // the combination will be from 3 cards, exactly 2 cards from player's 4 cards and only 1 from the table. so best combination is AAA
    capOmaha = 16, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    capOmaha5 = 22, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    capOmaha6 = 23, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    capOmaha7 = 27, // Omaha7 with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    omaha7 = 24, // Omaha with 7 cards instead of 4
    tripleDraw = 17, // 2/7 triple draw
    badugi = 18, // Badugi
    badeucey = 19, // Badeucey = mix of triple draw and badugi
    sevenStud = 20, // player receives 7 cards, 2 face down - private, 4 face up - everyone can see, and 7th card face down - private, no public/community cards. (Fix limit only. and has ante)
    sevenStudHiLo = 21, // hybrid of 7 stud and Omaha hi/lo
    razz = 25, // Form of 7 stud game but the lowest possible five-card hand is winner
    fiveCardDraw = 26, // Similar to triple draw but Hi
    sixPlusHoldem = 28, // 6+ Holdem
    singleDraw = 29, // 2/7 single draw - subvariant of the triple draw but with only one draw round
    capSingleDraw = 31, // 2-7 Single Draw version that also has CAP 
    capOmaha5HiLo = 30, // Omaha 5 Hi/Lo version that has CAP
    mixTable = 100 // the variant is changing every hand and sent by the server.
}

