import { Inject, Injectable, inject } from '@angular/core';
import { filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { ServerMessageType } from '@app/enums/server-message-type';
import { ServerMsg, ServerMsgAuthToken, ServerMsgBroadcastMessageInfoResponse, ServerMsgConnectResponse } from '@app/models/server-msg';
import { Store, select } from '@ngrx/store';
import { WsService } from '@app/services/ws.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import * as  WSActions from '@app/store/features/web-socket/web-socket.actions';
import * as ConfigActions from '@app/store/features/config/config.actions';
import { ConnectionStatus } from '@app/enums/connection-status';
import { Observable, combineLatest, from, iif, of, timer } from 'rxjs';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSettings, GlobalSettingsAPIResponse, GlobalSettingsBanner, GlobalSettingsFdbEnabled, GlobalSettingsRafEnabled, GlobalSettingsRakebackLevelStructure } from '@app/models/global-settings';
import { ServerResponse } from '@app/enums/server-response';
import { JackPotInfo, JackPotInfoDTO } from '@app/models/jackpot-info';
import { CasinoInfo, CasinoInfoDTO } from '@app/models/casino-info';
import { CasinoService } from '@app/services/casino.service';
import * as AuthSelectors from '@app/store/features/auth/auth.selectors';

import * as UserSelectors from '@app/store/features/user/user.selectors';
import { HttpParams } from '@angular/common/http';
import { SkinSettings, SkinSettingsDTO } from '@app/models/skin-settings';
import { Dialog } from '@angular/cdk/dialog';
import { GenericDialogComponent } from '@app/components/generic-dialog/generic-dialog.component';
import { ConfigService } from '@app/services/config.service';
import { camelCase } from 'lodash';
import { BroadcastMessageInfoDialogComponent } from '@app/components/broadcast-message-info-dialog/broadcast-message-info-dialog.component';
import { BroadCastMessageInfo } from '@app/models/broadcast-message-info';
import { Assets, Application, Container, Sprite } from 'pixi.js';

@Injectable()
export class ConfigEffects {

    private readonly _ws = inject(WsService);
    private readonly _actions$ = inject(Actions);
    private readonly _store = inject(Store);
    private readonly _router = inject(Router);
    private readonly _casinoService = inject(CasinoService);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _dialog = inject(Dialog);
    private readonly _configService = inject(ConfigService);



    constructor() {

    }

    // Game Loading Assets


    // 🌐 WebSocket 
    wsLoginStatus$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsg>(ServerMessageType.AppReadyToUse)
            .pipe(
                tap(() => console.log(" 📱 AppReadyToUse _____________________", new Date(), new Date().getTime(), this._activatedRoute.snapshot.queryParams['returnUrl'])),
                tap(() => {
                    // const returnUrl = this._activatedRoute.snapshot.queryParams["returnUrl"]
                    // this._router.navigate([returnUrl ? returnUrl : '/lobby'])
                }),
                map(() => ConfigActions.updateAppReadyToUse({ appReadyToUse: true }))
            ));

    // 🌐 WebSocket, 🕹️ App
    private readonly gameInitializedWithCredentials$ = this._ws.getServerMsg<ServerMsgConnectResponse>(ServerMessageType.ConnectResponse)
        .pipe(
            switchMap(connectionResponse => iif(() => !!connectionResponse.errorCode,
                of(undefined), // on Error Proceed to next step
                this._ws.getServerMsg<ServerMsg>(ServerMessageType.AppReadyToUse)
            )),
            take(1),
        )




    // 🌐 WebSocket 
    wsBroadcastMessageInfo$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgBroadcastMessageInfoResponse>(ServerMessageType.MessageInfo)
            .pipe(
                switchMap((broadcastMessageInfo) => {
                    return this._dialog.open<BroadCastMessageInfo>(BroadcastMessageInfoDialogComponent, {
                        width: '300px',
                        data: broadcastMessageInfo,

                    }).closed
                }),
                tap(tournamentId => {
                    if (tournamentId) {
                        this._router.navigate(['/tournament', tournamentId])
                    }
                })

            ), { dispatch: false });





    gameInitialized$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WSActions.updateConnectionStatus),
                filter(({ status }) => status === ConnectionStatus.Connected),
                switchMap(() => this._ws.stream.pipe(take(1))),
                map(() => localStorage.getItem('authCredentials')),
                switchMap(authCredentials => iif(() => !!authCredentials, this.gameInitializedWithCredentials$, of(undefined))),
                map(() => ConfigActions.updateGameInitialized({ gameInitialized: true }))
            )

    );


    updateSkinSettings$ = createEffect(() =>
        this._ws.getDataResponse<{ skinSettings: SkinSettingsDTO }>(ServerResponse.SkinsInfo)
            .pipe(
                map(skinsInfo => {
                    return ConfigActions.updateSkinSettings({ skinSettings: skinsInfo.skinSettings })
                })
            ));

    updateGlobalSettings$ = createEffect(() =>
        this._ws.getDataResponse<GlobalSettingsAPIResponse>(ServerResponse.Settings)
            .pipe(
                map(globalSettingsAPIResponse => {

                    let bannersData: GlobalSettingsBanner[] = [];
                    if (globalSettingsAPIResponse.banners) {
                        try {
                            bannersData = this._ws.format(JSON.parse(globalSettingsAPIResponse.banners), 'camelCase') as GlobalSettingsBanner[]
                        } catch (e) { }
                    }

                    let rakebackLevelStructuresData;
                    if (globalSettingsAPIResponse.rakebackLevelStructures) {
                        try {
                            rakebackLevelStructuresData = this._ws.format(JSON.parse(globalSettingsAPIResponse.rakebackLevelStructures), 'camelCase') as Record<string, GlobalSettingsRakebackLevelStructure>
                        } catch (e) { }
                    }


                    let rafEnabledData: GlobalSettingsRafEnabled[] = []
                    if (globalSettingsAPIResponse.rafEnabled) {
                        try {
                            rafEnabledData = this._ws.format(JSON.parse(globalSettingsAPIResponse.rafEnabled), 'camelCase') as GlobalSettingsRafEnabled[]
                        } catch (e) { }
                    }

                    let fdbEnabledData: GlobalSettingsFdbEnabled[] = []
                    if (globalSettingsAPIResponse.fdbEnabled) {
                        try {
                            fdbEnabledData = this._ws.format(JSON.parse(globalSettingsAPIResponse.fdbEnabled), 'camelCase') as GlobalSettingsFdbEnabled[]
                        } catch (e) { }
                    }


                    const globalSettings: GlobalSettings = {
                        ...globalSettingsAPIResponse,
                        bannersData,
                        bannerTop$: of(bannersData?.filter(banner => banner.position === 'Top'))
                            .pipe(
                                switchMap((banners) => {
                                    return timer(0, 6000).pipe(
                                        map((index) => banners ? banners[index % banners.length] : null)
                                    )
                                })),
                        rakebackLevelStructuresData,
                        fdbEnabledData,
                        rafEnabledData
                    };

                    return ConfigActions.updateGlobalSettings({ globalSettings })
                })
            ));




    showWelcomeBanner$ = createEffect(
        () =>
            this._store.pipe(
                select(AuthSelectors.getLoggedInStatus),
                filter(loggedIn => loggedIn),
                switchMap(() => this._actions$.pipe(ofType(ConfigActions.updateGlobalSettings))),
                tap(({ globalSettings }) => {

                    if ((new Date().getTime() < Number(globalSettings!.popupExpirationDate) * 1000) && globalSettings?.popupTitle !== '') {
                        // The popup should not be displayed if current time is after PopupExpiration or PopupTitle is empty.
                        // The popup should have a button to register a tournament if PopupIdTournament is defined.
                        const bannerDialog = this._dialog.open(GenericDialogComponent, { width: '300px' })
                        bannerDialog.componentInstance!.title = globalSettings!.popupTitle;
                        bannerDialog.componentInstance!.image = globalSettings!.popupBannerUrl;
                        bannerDialog.componentInstance!.text = globalSettings!.popupDescription;
                        bannerDialog.componentInstance!.confirmBtn = globalSettings!.popupIdTournament ? 'Register' : null;

                        bannerDialog.closed.pipe(take(1)).subscribe((confirm) => {
                            if (confirm) {
                                this._router.navigate(['/tournament', globalSettings!.popupIdTournament])
                            }
                        })
                    }


                    let showWelcome = true
                    if (typeof Storage !== "undefined") {
                        showWelcome = (localStorage.getItem('welcomeMessage') !== 'true')
                    }

                    if (globalSettings!.welcome && showWelcome) {

                        const welcomeDialog = this._dialog.open(GenericDialogComponent, { width: '300px' })
                        welcomeDialog.componentInstance!.text = globalSettings!.welcome
                        welcomeDialog.componentInstance!.confirmBtn = 'Ok'

                        welcomeDialog.closed.pipe(take(1)).subscribe(() => {
                            if (typeof Storage !== "undefined") {
                                localStorage.setItem('welcomeMessage', 'true');
                            }
                        })
                    }

                })
            ),
        { dispatch: false }
    );

    updateCasinosInfo$ = createEffect(() =>
        this._ws.getDataResponse<CasinoInfoDTO[]>(ServerResponse.CasinosInfo)
            .pipe(
                map(casinosInfoDTO => {
                    return ConfigActions.updateCasinosInfo({ casinosInfo: casinosInfoDTO.map(casinoInfo => this._casinoService.updateCasino(casinoInfo)) })
                })
            ));

    updateJackPotInfo$ = createEffect(() =>
        this._ws.getDataResponse<JackPotInfoDTO[]>(ServerResponse.JackPotsInfo)
            .pipe(
                map(jackPotInfoDTO => {
                    return ConfigActions.updateJackPotsInfo({ jackPotsInfo: jackPotInfoDTO })
                })
            ));

    openCasino$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ConfigActions.openCasino),
                tap(({ amount, casinoInfo }) => this._casinoService.getCasinoToken(casinoInfo.parameters.product)),
                switchMap(({ amount, casinoInfo }) => {
                    return this._ws.getServerMsg<ServerMsgAuthToken>(ServerMessageType.GetAuthToken).pipe(
                        take(1),
                        switchMap(casinoAuthToken => {
                            return this._store.pipe(
                                select(UserSelectors.selectUserState),
                                take(1),
                                tap(user => {
                                    let queryParams: { [key: string]: string | number; } = {}

                                    if (casinoInfo.config.token) { queryParams['token'] = casinoAuthToken.text }
                                    if (casinoInfo.config.username) { queryParams['username'] = user.profile!.username }
                                    if (casinoInfo.config.buyInAmount) { queryParams['buyInAmount'] = amount }

                                    queryParams['product'] = casinoInfo.parameters.product;

                                    const url = `${casinoInfo.landingUrl}?${new HttpParams({ fromObject: queryParams })}`
                                    window.open(url, '_blank');
                                })
                            )
                        })

                    )
                }),
            ),
        { dispatch: false }

    );



    onUpdateDomainSettings$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ConfigActions.updateDomainSettings),
            map(({ domainSettings }) => ConfigActions.connectWebSocket({ domainSettings }))
        ))




    // ===========================
    // @ Game Assets
    // ===========================

    onUpdateDomainSettingsLoadAssets$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ConfigActions.updateDomainSettings),
            switchMap(({ domainSettings }) => this._configService.loadGameAssets(domainSettings)),
            map((percentage) => ConfigActions.onAssetsLoading({ percentage: Math.floor(percentage) }))
        ))



    // Theme
    themeSelectCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ConfigActions.themeSelectCard),
            map(({ id }) => ConfigActions.updateAssetsThemeSelectCard({ id }))
        ))

    themeSelectBackCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ConfigActions.themeSelectBackCard),
            map(({ id }) => ConfigActions.updateAssetsThemeSelectBackCard({ id }))
        ))

    themeSelectTable$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ConfigActions.themeSelectTable),
            map(({ id }) => ConfigActions.updateAssetsThemeSelectTable({ id }))
        ))

    themeSelectCarpet$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ConfigActions.themeSelectCarpet),
            map(({ id }) => ConfigActions.updateAssetsThemeSelectCarpet({ id }))
        ))
} 