import { GameCardSorting } from "@app/store/features/games/games.reducer";
import { CardData } from "../models/card-data";
import { CardSuits } from "./card-decoder";

export function sortCards(cards: CardData[], cardSorting: GameCardSorting) {
    const suitOrder: { [key: string]: number } = {
        "Hearts": 0,
        "Diamonds": 1,
        "Clubs": 2,
        "Spades": 3
    };

    switch (cardSorting) {
        case GameCardSorting.Default:
            return cards;
        case GameCardSorting.Suit:
            return cards.sort((a, b) => {
                // First, compare suit order (descending)
                const suitComparison = suitOrder[CardSuits[b.suit]] - suitOrder[CardSuits[a.suit]];
                if (suitComparison !== 0) {
                    return suitComparison;
                }

                // If suits are the same, sort by number (descending)
                return b.number - a.number;
            });
        case GameCardSorting.LowerToHigher:
            return cards.sort((a, b) => a.number - b.number);
        case GameCardSorting.HigherToLower:
            return cards.sort((a, b) => b.number - a.number);
        default:
            return cards;
    }
}