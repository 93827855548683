<div class="dialog-header">
    <div class="dialog-header-title">
        Call Time
    </div>
</div>
<ng-container  *ngIf="callTimeCountdownTimer$ | async as timer; else callTimeNotActivated">
    <div class="dialog-content app-game-call-time-dialog-content">
        <div class="app-game-call-time-dialog-text">
            You can leave table after Call Time expires
        </div>
        <div class="app-game-call-time-dialog-timer">
            {{timer?.minutes}}m : {{timer?.seconds}}s
        </div>
    </div>
    <div class="dialog-footer">
        <div class="app-game-call-time-dialog-footer">
            <button class="app-game-call-time-dialog-footer-button-ok" appButton (click)="close()">Ok</button>
        </div>
    </div>  
</ng-container>
<ng-template #callTimeNotActivated>
    <div class="dialog-content app-game-call-time-dialog-content">
        <div class="app-game-call-time-dialog-text">
            You can leave table after Call Time expires
        </div>
    </div>  
    <div class="dialog-footer">
        <div class="app-game-call-time-dialog-footer">
            <button class="app-game-call-time-dialog-footer-button-accept success" appButton
                (click)="startCallTime()">Start Call Time</button>

            <button class="app-game-call-time-dialog-footer-button-cancel" appButton (click)="close()">Cancel</button>
        </div>
    </div>
</ng-template>


