export enum ServerMessageType {
    Info = 0,  // entire table information. Received when you join the table for instance or get move to to another table (tournament)
    StatsPlayerOnline = 1002,
    ConnectResponse = 2001,

    Pong = 1502,     // replay to Ping. Value: Server time in .net format, Value2: the time you send. Compare to your local time to know the network delay


    AppReadyToUse = 2002,


    Chat = 1001, // Lobby Chat message

    PlayerBalance = 1003,

    GetAuthToken = 900, // CasinoAuthToken

    AccountPasswordChangeResponse = 1104,
    AccountRequestSecurityCodeResponse = 1107, // response to lost password AccountRequestSecurityCode
    AccountUsernameUniqueResponse = 1101, // reply to VerifyUsernameUnique
    AccountEmailUniqueResponse = 1102, // reply to VerifyEmailUnique
    Error = 1300, // see https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#error-code


    TransferMoneyResponse = 1250, // TransferMoneyResponse

    RequestSelfExclusionResponse = 1226, // Self Exclude Response

    MessageInfo = 1403,  // msg to display. see https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#popup-timed


    DepositInitiateResponse = 321, // DepositInitiateResponse
    RequestWithdrawalResponse = 1260,

    ImageUploadResponse = 1125,

    RequestPhoneCodeResponse = 1211,
    SubmitPhoneCodeResponse = 1212,



    // Game Actions:

    SendCardHidden = 22,
    HandStart = 3, // [IdTable],  Value = id of the hand, Value2: # number player playing the hand.

    GameStatusChanged = 4,
    HandStartWithPlayers = 5,
    HandStartPlayerBalances = 6,

    TimeBankStatus = 8,
    TimeBankUsing = 9,

    Fold = 10,

    BidAnte = 11,
    BidSmallBlind = 12,
    BidBigBlind = 13,
    BidCheck = 14,
    BidBet = 15,
    BidCall = 16,
    BidRaise = 17,
    BidAllIn = 18,
    BidStraddle = 45,

    Dealer = 20,
    PlayerTurnChange = 21,

    RevealCard = 24, // player publicaly reveal it's card. [IdTable], [IdPlayer], [Cards]

    // when overbidding return the extra to the player. [IdTable], [IdPlayer], Value: amt returned to the player. Value2: player Balance
    ReturnBackMoney = 26,

    // list of community cards to show (this is used to rollback card in running twice for instance),
    // [IdTable], [Cards]: community cards share for both series, [CommunityCardsR2T1] community cards serie 1,
    // [CommunityCardsR2T2]: commmunity cards serie 2
    CommunityCards = 29,


    MoveToPot = 27,
    PotResult = 30,

    //  [Private msg] asked if player want to show his cards because everyone else folded. Value: nb seconds to answer message 'Muck' #14
    AskShowCard = 40,

    // Next 3 messages use the same format
    // [IdTable], [IdPlayer], Value: money win, Value2: balance, Cards: cards used to win if showed.
    WinnerByFold = 31, // player win because everyone else folded
    WinnerByStrongestHand = 32, // player win because he got the best hand
    WinnerSplit = 33, // player win part of the pot because he got a tie best hand

    PlayerTurnCardsReturnChange = 38,
    SkipNextHand = 39, // [Private msg] send to client if he will be skipped on next hand because he didn't agree to pay the BB

    ReplaceCardsPeriodOver = 41,  // This message is sent after process of replacing the cards for one player is finished on the server side. This way other clients will know that player replaced cards and how many cards are replaced. Message structure:

    // player purchase chips,[IdTable], [IdPlayer], Value= nb chips total, value2: 1=rebuy, 2=addon. value3: 1 = automatic
    PlayerBuyChips = 42,
    PlayerLeave = 43, // user leave the table, [IdTable], [IdPlayer], [PlayerData]: player profile including name
    PlayerStatus = 44, // player status change: see: https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#sitout--im-back

    // take a physical place on the table  [IdTable], [IdPlayer], Value: seat index, [PlayerData]: player profile including name
    PlayerTakeSeat = 46,
    PlayerSetRunItTwice = 47, // player set the option to use Run it twice or not. [IdTable], [IdPlayer], Value = 1: enable, 0: disable
    PrivateTablePasswordResponse = 48, // response to PrivateTablePassword, Value = 1:password ok, 0: wrong password

    PlayerPrivateData = 49,

    HandType = 70, // used to show player hand strength

    OfferRabbitHunting = 71, // Rabbit Hunting game extension
    StartedRabbitHunting = 72,
    SecondBoardHandType = 74, // server send you the type of second board hand you have - only for double board games (pair, straight..). [IdTable]. Value is mapped to enum https://github.com/ElninhoConsulting/Html5V2/wiki/Websocket-API-(To-play-the-game)#handtype

    HideRabbitHuntingButton = 75, // hide the rabbit hunting button

    ChargedRabbitHunting = 76, // how much Rabbit Hunting is charged

    FreeRabbitHunting = 77, // number of free Rabbit Hunting
    RabbitHuntingPrice = 83, // On each hand starts, send info about rabbit hunting prize and currency

    // player is out of chips, ask for rebuy. value2 = NbSeconds to buy, must send BuyChips with a value > 0 to accept, value < 0 to reject
    AskPlayerRebuy = 59,

    PotSplitted = 80, // When pot is splitted into 2 (R2T or Omahah Hi/lo). [IdTable], [Pots]

    EndOfHand = 81,


    // stats about the player play for a particular table.
    // [IdTable], [IdPlayer], Values[0 = NbHandsPlayed,  1 = NbHandsWon, 2 =TotalBet,  3 = TotalWon].
    // Can be reset by sending PlayStatisticsReset #23
    PlayerPlayStatistic = 100,

    CallTimeCallTimeInProgress = 301, // (player is winning but call time is in progress - player already accepted call time)
    CallTimeNoActivated = 302, // (player is winning but he didn't accept call time)
    
}


