import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import * as TableSummariesActions from './table-summaries.actions';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import { TableSummary } from '@app/models/table-summary';

export interface State extends EntityState<TableSummary> {
    loader: boolean;
}

export const adapter: EntityAdapter<TableSummary> = createEntityAdapter<TableSummary>({
    selectId: (model: TableSummary) => model.id,
    sortComparer: smartSort,
});

export const initialState: State = adapter.getInitialState({
    loader: false,
});

function smartSort(propA: TableSummary, propB: TableSummary): number {
    const pa = propA.playerCount / propA.maxPlayers;
    const pb = propB.playerCount / propB.maxPlayers;
    const aAccessLimited = propA.isAccessLimited ? 3 : 0;
    const bAccessLimited = propB.isAccessLimited ? 3 : 0;
    let a = 0;
    let b = 0;

    if (propA.playerCount === 0) {
        a = 0;
    } else if (propA.playerCount === 1) {
        a = 1;
    } else if (propA.playerCount === propA.maxPlayers) {
        a = 2;
    } else {
        a = 3 + pa;
    }

    if (a) { a += aAccessLimited; }
    if (!a && aAccessLimited) { a += 1; }

    if (propB.playerCount === 0) {
        b = 0;
    } else if (propB.playerCount === 1) {
        b = 1;
    } else if (propB.playerCount === propB.maxPlayers) {
        b = 2;
    } else {
        b = 3 + pb;
    }

    if (b) { b += bAccessLimited; }
    if (!b && bAccessLimited) { b += 1; }

    if (a === b) {
        if (propA.blind < propB.blind) {
            a += 0.1;
        } else {
            b += 0.1;
        }
    }

    return b - a;
}

export const reducer = createReducer(initialState,
    on(TableSummariesActions.updateLoader, (state, { loader }) => {
        return { ...state, loader };
    }),

    on(TableSummariesActions.setAll, (state, { tableSummaries }) => {
        return adapter.setAll(tableSummaries, { ...state, loader: false });
    }),

    on(TableSummariesActions.updateOne, (state, { tableId, tableSummary }) => {
        return adapter.updateOne({ id: tableId, changes: { ...tableSummary } }, { ...state });
    }),

    on(TableSummariesActions.removeOne, (state, { tableId }) => {
        return adapter.removeOne(tableId, { ...state });
    }),

    on(AuthActions.onLogout, (state) => {
        return adapter.removeAll({ ...state });
    }),
);
