
export enum CardSuits { Hearts, Diamods, Clubs, Spades }
export enum CardNumbers { T = 10, Jack, Queen, King, Ace }
export const cardNames: string[] = [
    '', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'Jack', 'Queen', 'King', 'Ace'
];

export function cardDecoder(cardSuit: number | null, cardNumber: number, reversed?: boolean) {
    if (cardSuit === undefined || cardNumber === undefined) { return ''; }

    let cardNumberString: string;
    let cardSuitString: string;

    if (cardNumber === CardNumbers.Jack) {
        cardNumberString = 'J';
    } else if (cardNumber === CardNumbers.Queen) {
        cardNumberString = 'Q';
    } else if (cardNumber === CardNumbers.King) {
        cardNumberString = 'K';
    } else if (cardNumber === CardNumbers.Ace) {
        cardNumberString = 'A';
    } else if (cardNumber === CardNumbers.T) {
        cardNumberString = 'T';
    } else {
        cardNumberString = cardNumber.toString();
    }

    if (cardSuit === CardSuits.Hearts) {
        cardSuitString = 'h';
    } else if (cardSuit === CardSuits.Diamods) {
        cardSuitString = 'd';
    } else if (cardSuit === CardSuits.Clubs) {
        cardSuitString = 'c';
    } else if (cardSuit === CardSuits.Spades) {
        cardSuitString = 's';
    } else {
        cardSuitString = '';
    }

    return reversed ? cardNumberString + cardSuitString : cardSuitString + cardNumberString;
}

export function cardNameDecoder(newCardNumber: number, short?: boolean): string {
    // TRANSLATION_DEPT - cardNames Jack, Queen, King, Ace
    let name = cardNames[newCardNumber]
    if (short) {
        if (name === 'Jack') { name = 'J' } 
        if (name === 'Queen') { name = 'Q' }
        if (name === 'King') { name = 'K' }
        if (name === 'Ace') { name = 'A' }
    }
    return cardNames[newCardNumber];
}

export function cardSuitDecoder(cardSuit: number, short?: boolean): string {
    let cardSuitString: string;

    if (cardSuit === CardSuits.Hearts) {
        cardSuitString = short ? 'h' : 'hearts';
    } else if (cardSuit === CardSuits.Diamods) {
        cardSuitString = short ? 'd' : 'diamods';
    } else if (cardSuit === CardSuits.Clubs) {
        cardSuitString = short ? 'c' : 'clubs';
    } else if (cardSuit === CardSuits.Spades) {
        cardSuitString = short ? 's' : 'spades';
    }else{
        cardSuitString = '';
    }

    return cardSuitString
}

