// @localhost
export const environment = {
    production: false,

    pingPongTime: 60000,
    // domainname: 'instantplay.bluffclub.vip',
    // domainname: 'instantplay.50poker.net',
    domainname: 'dev.createpoker.app',
    // domainname: 'instantplay.oshipoker.com',
    // domainname: 'instantplay.aionpoker.com',
    // domainname: 'instantplay.winall.store',
    // domainname: 'instantplay.loco.poker',

    dataSource: 'https://data.createpoker.app/app/game',
    devMode: false
};
