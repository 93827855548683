
import { Injectable } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerMessageType } from '@app/enums/server-message-type';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { combineLatest, merge, of } from 'rxjs';
import { LobbyService } from '../../../features/lobby/services/lobby.service';
import { StatsPlayerOnline } from '../../../features/lobby/models/stats-player-online';
import * as LobbyActions from './lobby.actions';
import { LobbyPreviewNav } from '../../../features/lobby/enums/lobby-preview-nav';
import { VariantType2 } from '@app/enums/variant-type-2';
import * as LobbySelectors from './lobby.selectors';


import * as TableSummariesActions from '../table-summaries/table-summaries.actions';
import * as SitNGoSummariesActions from '../sit-n-go-summaries/sit-n-go-summaries.actions';
import * as SpinNGoSummariesActions from '../spin-n-go-summaries/spin-n-go-summaries.actions';
import * as TournamentSummariesActions from '../tournament-summaries/tournament-summaries.actions';

import * as TableSummariesSelectors from '../table-summaries/table-summaries.selectors';

import { LobbyFilterTableType } from '../../../features/lobby/enums/lobby-filter-table-type';
import { ServerMsgStatsPlayerOnline } from '@app/models/server-msg';
import { WsService } from '@app/services/ws.service';
import * as CurrenciesSelectors from '@app/store/features/currencies/currencies.selectors';
import { SkinSettingsDTO } from '@app/models/skin-settings';
import { ServerResponse } from '@app/enums/server-response';
import { TableSummaryData } from '@app/models/table-summary';
import { TournamentSummaryResponse } from '@app/models/tournament-summary';
import { Dialog } from '@angular/cdk/dialog';
import { LobbyDialogLegendsComponent } from '@app/features/lobby/presenters/lobby-dialog-legends/lobby-dialog-legends.component';
import { GenericDialogComponent } from '@app/components/generic-dialog/generic-dialog.component';

@Injectable()
export class LobbyEffects {
    constructor(
        private readonly _ws: WsService,
        private readonly _actions$: Actions,
        private readonly _store: Store,
        private readonly _lobbyService: LobbyService,
        private readonly _dialog: Dialog,

    ) {

    }


    updateActiveNavInitFromSkinSettings$ = createEffect(() =>
        this._ws.getDataResponse<{ skinSettings: SkinSettingsDTO }>(ServerResponse.SkinsInfo)
            .pipe(
                map(skinsInfo => {

                    const skinSettings = skinsInfo.skinSettings

                    let activeNav;

                    if (skinSettings.shouldHaveRing) {
                        activeNav = LobbyFilterTableType.RingGames
                    } else if (skinSettings.shouldHaveSitNGo) {
                        activeNav = LobbyFilterTableType.SitNGo
                    } else if (skinSettings.shouldHaveTournament) {
                        activeNav = LobbyFilterTableType.Tournaments
                    } else if (skinSettings.shouldHaveSpinNGo) {
                        activeNav = LobbyFilterTableType.SpinNGo
                    } else {
                        activeNav = LobbyFilterTableType.RingGames
                    }

                    return LobbyActions.updateActiveNav({ activeNav })
                })
            ));


    updateStatsPlayerOnline$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgStatsPlayerOnline>(ServerMessageType.StatsPlayerOnline)
            .pipe(
                map(statsPlayerOnline => {
                    const payload: StatsPlayerOnline = {
                        total: statsPlayerOnline.value,
                        playingRingTable: statsPlayerOnline.values[0],
                        playingTournament: statsPlayerOnline.values[1],
                        idles: statsPlayerOnline.values[2]
                    }
                    return LobbyActions.updateStatsPlayerOnline({ statsPlayerOnline: payload })

                })
            ));

    updateActiveNav$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LobbyActions.updateActiveNav),
                switchMap(({ activeNav }) => {
                    let updateSummariesAction = null;

                    switch (activeNav) {
                        case LobbyFilterTableType.RingGames:
                            updateSummariesAction = TableSummariesActions.updateLoader({ loader: true })
                            break;

                        case LobbyFilterTableType.SitNGo:
                            updateSummariesAction = SitNGoSummariesActions.updateLoader({ loader: true })
                            break;

                        case LobbyFilterTableType.Tournaments:
                            updateSummariesAction = TournamentSummariesActions.updateLoader({ loader: true })
                            break;

                        case LobbyFilterTableType.SpinNGo:
                            updateSummariesAction = SpinNGoSummariesActions.updateLoader({ loader: true })
                            break;
                    }
                    if (true) {
                        updateSummariesAction = SitNGoSummariesActions.updateLoader({ loader: true })
                    }

                    return of(
                        LobbyActions.updateActivePreview({ tableId: undefined, tournamentId: undefined }),
                        LobbyActions.updateActivePreviewNav({ activeNav: LobbyPreviewNav.Preview }),
                        LobbyActions.onFiltersChange({ filters: { tableType: activeNav } }),
                        updateSummariesAction
                    )
                })
            ));

    onSelectTable$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LobbyActions.onSelectTable),
                switchMap(({ tableSummary }) => {
                    return of(
                        LobbyActions.updateActivePreview({ tableId: tableSummary.id, tournamentId: undefined }),
                        LobbyActions.updateActivePreviewNav({ activeNav: tableSummary.variant2 === VariantType2.mixTable ? LobbyPreviewNav.MixTables : LobbyPreviewNav.Preview })
                    )
                })
            ));

    onSelectTournament$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LobbyActions.onSelectTournament),
                switchMap(({ tournamentSummary }) => {
                    return of(
                        LobbyActions.updateActivePreview({ tableId: undefined, tournamentId: tournamentSummary.id }),
                        LobbyActions.updateActivePreviewNav({ activeNav: LobbyPreviewNav.Preview })
                    )
                })));

    onSelectSitNGo$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LobbyActions.onSelectSitNGo),
                switchMap(({ sitNGoSummary }) => {
                    return of(
                        LobbyActions.updateActivePreview({ tableId: undefined, tournamentId: sitNGoSummary.id }),
                        LobbyActions.updateActivePreviewNav({ activeNav: LobbyPreviewNav.Preview })
                    )
                })
            ));

    onSelectSpinNGo$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LobbyActions.onSelectSpinNGo),
                switchMap(({ spinNGoSummary }) => {
                    return of(
                        LobbyActions.updateActivePreview({ tableId: undefined, tournamentId: spinNGoSummary.id }),
                        LobbyActions.updateActivePreviewNav({ activeNav: LobbyPreviewNav.Preview })
                    )
                })
            ));

    onFiltersChange$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LobbyActions.onFiltersChange),
                switchMap(({ filters }) => {
                    return combineLatest([
                        this._store.pipe(select(CurrenciesSelectors.selectActive), filter(currency => !!currency)),
                        this._store.pipe(select(LobbySelectors.selectFilters))
                    ])
                        .pipe(
                            take(1),
                            tap(([currency, currentFilters]) => this._lobbyService.getTables({ ...currentFilters, ...filters }, currency!)),
                            map(([currency, currentFilters]) => LobbyActions.updateFiltersChange({ filters: { ...currentFilters, ...filters } }))
                        )

                })
            ),
    );

    onChangeCurrency$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LobbyActions.onChangeCurrency),
                switchMap(({ currency }) => {
                    return this._store.pipe(
                        select(LobbySelectors.selectFilters),
                        take(1),
                        tap(lobbyFilters => this._lobbyService.getTables(lobbyFilters, currency)),
                        map((lobbyFilters) => LobbyActions.updateFiltersChange({ filters: lobbyFilters }))
                    )
                })
            ),
    );


    onChangeFilters$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LobbyActions.updateFiltersChange),
                switchMap(() => {
                    return of(
                        TableSummariesActions.updateLoader({ loader: true }),
                        SitNGoSummariesActions.updateLoader({ loader: true }),
                        TournamentSummariesActions.updateLoader({ loader: true }),
                        SpinNGoSummariesActions.updateLoader({ loader: true })
                    )
                })
            ),
    );



    updateError$ = createEffect(() =>
        merge(
            this._ws.getDataResponse<TableSummaryData[]>(ServerResponse.TableSummaries)
                .pipe(
                    map(tableSummariesResponse => {
                        if (tableSummariesResponse.filter(el => el.id === -1).length > 0) {
                            return tableSummariesResponse[0].name
                        }

                        return undefined;
                    })
                ),
            this._ws.getDataResponse<TournamentSummaryResponse[]>(ServerResponse.TournamentsSummaries)
                .pipe(
                    map(tournamentSummariesResponse => {
                        if (tournamentSummariesResponse.filter(el => el.id === -1).length > 0) {
                            return tournamentSummariesResponse[0].name
                        }

                        return undefined;
                    })
                )
        ).pipe(map(error => LobbyActions.updateError({ error }))));

    onGameLegend$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LobbyActions.onGameLegend),
                tap(({ gameLegend, tableSum }) => {
                    if (gameLegend.image === 'call-time') {
                        const dialog = this._dialog.open(GenericDialogComponent, { width: '300px' })
                        dialog.componentInstance!.title = 'Call Time';
                        dialog.componentInstance!.text = `
                        <div style="margin-bottom:5px"><strong> >${tableSum.callTimeConfiguration?.callTimePercent}%</strong></div>
                        <div>${tableSum.callTimeConfiguration?.callTimeDuration}min</div>
                        `
                        dialog.componentInstance!.dissmissBtn = 'Ok'
                    } else {
                        this._dialog.open(LobbyDialogLegendsComponent, { width: '300px' });
                    }
                })
            ), { dispatch: false }
    );


} 